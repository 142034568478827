import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Input, Popover, Row, Select, Space, Switch, Tag } from 'antd'
import { QuestionCircleFilled } from '@ant-design/icons';
import { networkModuleApi } from '../../../../services/api';

export default function LoadBalancerDetail(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [id, setId] = useState(props.id);
    const [model, setModel] = useState({ status: 1, origins: [{ status: 1 }] });
    const [status, setStatus] = useState(1);
    const [fails, setFails] = useState(0);
    const [domains, setDomains] = useState([]);
    const [healhChecks, setHealhChecks] = useState([])

    useEffect(() => {
        networkModuleApi.get(`/Domain/All`)
            .then((res) => {
                setDomains(res.data.content);
            });
        networkModuleApi.get(`/HealthCheck/All`)
            .then((res) => {
                setHealhChecks(res.data.content);
            });
        if (id) {
            networkModuleApi.get(`/Balancer?id=${id}`)
                .then((res) => {
                    setModel(res.data.content);
                });
        }
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            protocol: model.protocol,
            size: model.size,
            idDomain: model.idDomain?._id ? model.idDomain._id : model.idDomain,
            address: model.address,
            method: model.method,
            tlsVersion: model.tlsVersion,
            forwardIP: model.forwardIP,
            compression: model.compression,
            port: model.port,
            status: model.status,
            origins: model.origins
        });
        let fails = 0;
        model.origins.map((origin) => {
            if (origin.status == 0) {
                fails++;
            }
        });
        if (fails === 0) {
            setStatus(1);
        } else {
            if (fails === model.origins.length) {
                setStatus(0);
            } else {
                setStatus(2);
            }
        }
        setFails(fails);
    }, [model]);

    useEffect(() => {
        form.setFieldsValue({ status: status });
    }, [status])

    const onProtocolChange = (value) => {
        if (value === 'HTTPS') {
            form.setFieldsValue({ port: 443, tlsVersion: '1.2' });
        } else {
            form.setFieldsValue({ port: null, tlsVersion: null });
        }
    };

    const handleSave = (values) => {
        setIsLoading(true);
        if (model._id) {
            networkModuleApi.put(`/Balancer?id=${model._id}`, values)
                .then((res) => {
                    setModel(res.data.content);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            networkModuleApi.post(`/Balancer`, values)
                .then((res) => {
                    setId(res.data.content._id);
                    setModel(res.data.content);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleRemove = () => {
        setIsLoading(true);
        networkModuleApi.delete(`/Balancer?id=${model._id}`)
            .then(() => {
                props.onClose();
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const getStatus = () => {
        if (fails === 0) {
            return "Health";
        } else {
            if (fails === model.origins.length) {
                return "All origns down";
            } else if (fails > 1) {
                return fails + " origns down";
            } else {
                return "1 orign down";
            }
        }
    };

    const getStatusStyle = () => {
        if (status === 1) {
            return "success";
        } else if (status === 2) {
            return "warning";
        } else {
            return "error";
        }
    };

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                requiredMark={false}
                onFinish={handleSave}
            >
                <Card bordered={false} style={{ maxWidth: "1080px" }}>
                    <Row gutter={[10, 10]} align="bottom">
                        <Col span={12} order={1} md={5} xl={4}>
                            <Form.Item className="mb-0" label="Protocol" name="protocol" rules={[{ required: true }]}>
                                <Select onChange={onProtocolChange}>
                                    <Select.Option value="HTTP">HTTP</Select.Option>
                                    <Select.Option value="HTTPS">HTTPS</Select.Option>
                                    <Select.Option value="TCP">TCP</Select.Option>
                                    <Select.Option value="UDP">UDP</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} order={1} md={5} xl={4}>
                            <Form.Item
                                label={[
                                    <Popover key="size-pop" content="Something">
                                        <span>Size</span>
                                        <QuestionCircleFilled className="popover-icon" />
                                    </Popover>
                                ]}
                                name="size"
                                rules={[{ required: true }]}
                                style={{ marginBottom: 0 }}>
                                <Select>
                                    <Select.Option value="SMALL">SMALL - 250 conn/s</Select.Option>
                                    <Select.Option value="MEDIUM">MEDIUM - 500 conn/s</Select.Option>
                                    <Select.Option value="LARGE">LARGE - 1000 conn/s</Select.Option>
                                    <Select.Option value="X-LARGE">X-LARGE - 2000 conn/s</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} order={1} md={5} xl={6}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => prevValues.protocol !== currentValues.protocol}
                            >
                                {({ getFieldValue }) =>
                                    getFieldValue('protocol') === 'HTTP' || getFieldValue('protocol') === 'HTTPS' ? (
                                        <Form.Item className="mb-0" label="Domain" name="idDomain" rules={[{ required: true }]}>
                                            <Select>
                                                {
                                                    domains && (domains.map((item) => {
                                                        return (
                                                            <Select.Option key={item._id} value={item._id}>{item.url}</Select.Option>
                                                        )
                                                    }))
                                                }
                                            </Select>
                                        </Form.Item>
                                    ) : (
                                        <Form.Item className="mb-0" label="Address" name="address" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12} order={1} md={5} xl={2}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => prevValues.protocol !== currentValues.protocol}
                            >
                                {({ getFieldValue }) => getFieldValue('protocol') === 'HTTPS' ? (
                                    <Form.Item className="mb-0" label="Port" name="port" rules={[{ required: true }]}>
                                        <Input disabled value="443" />
                                    </Form.Item>
                                ) : (
                                    <Form.Item className="mb-0" label="Port" name="port" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={24} order={0} xl={{ span: 8, order: 1 }} style={{ textAlign: 'right' }}>
                            <Form.Item noStyle name="status"><Input type="hidden" /></Form.Item>
                            <Tag className="medium" color={getStatusStyle()}>{getStatus()}</Tag>
                        </Col>
                    </Row>
                    <Form.Item className="mb-0 mt-1" label="Advanced Options"></Form.Item>
                    <Card className="small mb-2">
                        <Row gutter={[10, 10]} align="bottom">
                            <Col span={12} md={5}>
                                <Form.Item
                                    className="mb-0"
                                    label={[
                                        <Popover key="size-pop" content="Something">
                                            <span>Balancing method</span>
                                            <QuestionCircleFilled className="popover-icon" />
                                        </Popover>
                                    ]}
                                    name="method"
                                >
                                    <Select>
                                        <Select.Option value="roundrobin">Round robin</Select.Option>
                                        <Select.Option value="hash">Hash</Select.Option>
                                        <Select.Option value="iphash">IP hash</Select.Option>
                                        <Select.Option value="leastconnections">Least Connections</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12} md={4} xl={3}>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => prevValues.protocol !== currentValues.protocol}
                                >
                                    {({ getFieldValue }) =>
                                        getFieldValue('protocol') === 'HTTPS' ? (
                                            <Form.Item className="mb-0" label="TLS version" name="tlsVersion">
                                                <Select>
                                                    <Select.Option value="1.1">1.1</Select.Option>
                                                    <Select.Option value="1.2">1.2</Select.Option>
                                                    <Select.Option value="1.3">1.3</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        ) : (
                                            <Form.Item className="mb-0" label="TLS version" name="tlsVersion">
                                                <Select disabled>
                                                    <Select.Option value="1.1">1.1</Select.Option>
                                                    <Select.Option value="1.2">1.2</Select.Option>
                                                    <Select.Option value="1.3">1.3</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={15} xl={12}>
                                <Form.Item label="Options" style={{ marginBottom: 6 }} />
                                <Row gutter={[10, 10]} >
                                    <Col span={24} sm={12}>
                                        <Space align="end" style={{ marginBottom: 10 }}>
                                            <Form.Item className="mb-0" name="forwardIP" valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                            <Popover content="Something">
                                                <label className="label-switch">Forward client’s IP</label>
                                                <QuestionCircleFilled className="popover-icon" />
                                            </Popover>
                                        </Space>
                                    </Col>
                                    <Col span={24} sm={12}>
                                        <Space align="end" style={{ marginBottom: 10 }}>
                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, currentValues) => prevValues.protocol !== currentValues.protocol}
                                            >
                                                {({ getFieldValue }) =>
                                                    getFieldValue('protocol') === 'HTTP' || getFieldValue('protocol') === 'HTTPS' ? (
                                                        <Form.Item className="mb-0" name="compression" valuePropName="checked">
                                                            <Switch />
                                                        </Form.Item>
                                                    ) : (
                                                        <Form.Item className="mb-0" name="compression" valuePropName="checked">
                                                            <Switch disabled />
                                                        </Form.Item>
                                                    )
                                                }
                                            </Form.Item>
                                            <label className="label-switch">Enable compression</label>
                                        </Space>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    <Form.List name="origins">
                        {(fields, { add, remove }) => (
                            <div className="form-list">
                                <Row className="form-list-header" align="bottom">
                                    <Col span={12}>
                                        <Form.Item className="mb-0" label="Origins"></Form.Item>
                                    </Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>
                                        <Button type="primary" onClick={() => add()} disabled={isLoading}>
                                            Add origin
                                        </Button>
                                    </Col>
                                </Row>
                                {fields.map(field => (
                                    <Row className="form-item" key={field.key} gutter={[10, 10]} align="bottom" style={{ marginBottom: 8 }}>
                                        <Col span={12} md={6}>
                                            <Form.Item
                                                {...field}
                                                className="mb-0"
                                                label="Address"
                                                name={[field.name, 'address']}
                                                fieldKey={[field.fieldKey, 'address']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} md={3} xl={2}>
                                            <Form.Item
                                                {...field}
                                                className="mb-0"
                                                label="Port"
                                                name={[field.name, 'port']}
                                                fieldKey={[field.fieldKey, 'port']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} md={6}>
                                            <Form.Item
                                                {...field}
                                                className="mb-0"
                                                label="Health Check"
                                                name={[field.name, 'idHealthCheck']}
                                                fieldKey={[field.fieldKey, 'idHealthCheck']}
                                                rules={[{ required: true }]}
                                            >
                                                <Select>
                                                    {
                                                        healhChecks && (healhChecks.map((hc) => {
                                                            return (
                                                                <Select.Option key={hc._id} value={hc._id}>{hc.name}</Select.Option>
                                                            )
                                                        }))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} md={3} xl={2}>
                                            <Form.Item
                                                {...field}
                                                className="mb-0"
                                                label="Weight"
                                                name={[field.name, 'weight']}
                                                fieldKey={[field.fieldKey, 'weight']}
                                                rules={[{ required: true }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} md={6} xl={8} style={{ textAlign: 'right' }}>
                                            <Space>
                                                <Tag className="medium" color={model.origins[field.key]?.status == 0 ? "error" : "success"}>{model.origins[field.key]?.status == 0 ? "Fail" : "OK"}</Tag>
                                                <Button type="secondary" onClick={() => remove(field.name)} disabled={isLoading}>Remove</Button>
                                            </Space>
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        )}
                    </Form.List>
                </Card>
                <Space className="form-actions">
                    <Button type="primary" htmlType="submit" loading={isLoading}>Save</Button>
                    <Button type="secondary" onClick={handleRemove} disabled={!id} loading={isLoading}>Remove</Button>
                </Space>
            </Form>
        </>
    )
}
