import { combineReducers } from 'redux';
import alerts from './alerts';
import appearance from './appearance';
import configurations from './configuration';
import expenses from './expenses';
import tasks from './tasks';

export default combineReducers({
    alerts, 
    appearance,
    configurations,
    expenses,
    tasks,
})