import React, { useState, useRef, useEffect } from 'react'
import { Icon, InlineIcon } from '@iconify/react'
import bxsContact from '@iconify/icons-bx/bxs-contact'
import "./contactPopover.scss"




export default function ContactPopover(props) {
    const [showContact, setShowContact] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);


    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowContact(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    return (
        <>

            <Icon onClick={() => { setShowContact(true) }} icon={bxsContact} style={{ fontSize: "25px", color: "#4f4f4f", cursor: "pointer" }} />
            {showContact &&
                <div ref={wrapperRef}>
                    <div className="contact-popover-container" >
                        {

                            props.contactInfo.email && <p>{props.contactInfo.email}</p>
                        }
                        {
                            props.contactInfo.phoneNumber && <p>{props.contactInfo.phoneNumber}</p>
                        }
                        <div className="contact-popover-arrow"></div>
                    </div>
                </div>
            }
        </>
    )
}
