import React, { useRef, useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useField } from '@unform/core';
import 'react-datepicker/dist/react-datepicker.css';
import "./datepicker.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from "moment";
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'


export default function Datepicker({ name, label, ...rest }) {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [date, setDate] = useState(null);
  
  useEffect(() => {
    if(rest.defaultValue){
      // console.log(moment(rest.defaultValue))
      setDate(moment(rest.defaultValue).toDate())
    }
    
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);
  return (
    <div className="form-group">
      <label>{label}</label>
      <ReactDatePicker
        className={"datepicker " + (error ? "error": "") }
        
        ref={datepickerRef}
        selected={date}
        onChange={setDate}
        {...rest}
      />
         { error && <div className="input-error-message"> {error}</div> }
      {/* <div className="calendar">
        <FontAwesomeIcon className="action-icon" icon={faCalendarAlt} />
      </div> */}
    </div>
  );
}
