import React, { useRef, useState, useEffect } from 'react';
import { FormContactTracing } from "../../../components/form/formContainer/formContactTracing";
import { LineEnteringBoxContainer } from '../../../components/proximityManagement/proximityBoxStyle/proximityBoxStyle';
import { Form } from "@unform/web";
import Input from "../../../components/form/input/input";
import Alert from "../../../components/alert/alert";
import History from "../../../routes/history"
import "../../../components/alert/alert.scss";
import { loginApi } from "../../../services/api";
import "./lineEntering.css"
import moment from "moment";
const axios = require('axios');


export default function LineEntering({ ...props }) {

    const formRef = useRef(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [companyName, setCompanyName] = useState("Company Name");
    const [idCompany, setIdCompany] = useState(null);
 
    var url = window.location.href
    var subDomain = /:\/\/([^\/]+)/.exec(url)[1];

    const proximityModuleApi = axios.create({
        baseURL: "http://proximity.tascsystems.com",
       // baseURL: 'http://localhost:8083/',
        timeout: 5000,
        headers: {
            "Authorization": "c9ff81fe25",
        }
    });

    useEffect(async () => {
        setIsLoading(false)

        var result = await loginApi.get('/login/GetCompanyBySubdomain', {
            params: {
                subDomain
            }
        });
        if (result.status === 200) {
            setCompanyName(result.data.content.name)
            setIdCompany(result.data.content._id)
        }
    }, [])

    const GetInLineMethod = (formData) => {
        try {
            const { name, phone, email } = formData;
            const date = moment().format();
            if (phone != '') {

                setOpenAlert(false);
                proximityModuleApi.post('/Proximity/GetInLIne', {
                    idCompany,
                    name,
                    email,
                    phone,
                    date

                }).then(function (response) {
                    if (response.status == 200) {
                        var id = response.data.content._id;
                        setIsLoading(false)
                        return History.push('/line/' + id)
                    }
                })
                    .catch(function (error) {
                        setIsLoading(false)
                        console.log(error.response);
                    });
            }
            else {
                setOpenAlert(true);
            }

        } catch (err) {
            setIsLoading(false)
            console.log(err)
        }
    };

    return (
        <>
            <div style={{
                backgroundImage: `url("/images/container-background.png")`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: "100% 100%",
                minHeight: "100%",
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                width: '100%'
            }}>
                <LineEnteringBoxContainer>
                    <FormContactTracing>
                        <div><img className="company-icon" src="/images/company-icon.png"></img> </div>

                        <Form ref={formRef} onSubmit={GetInLineMethod}>
                            <div className="form-row">
                                <Input name="name" label="Name" style={{ background: "#FBFBFB" }} />
                            </div>
                            <div className="form-row">
                                <Input name="email" label="Email" type="email" style={{ background: "#FBFBFB" }} />
                            </div>
                            <div className="form-row">
                                <Input type="text" mask="(999) 999.9999" name="phone" label="Phone Number" style={{ background: "#FBFBFB" }} />
                            </div>

                            <div className="information">
                                <span>
                                    Your data is not shared with {companyName}
                                </span>
                            </div>
                            <div className="form-row">
                                <button type="submit" className="submit check-in-button" > Get in line</button>
                            </div>
                        </Form>
                    </FormContactTracing>

                    {
                        openAlert && <Alert message={"You have to inform a phone number."} onClose={() => { setOpenAlert(false) }} />

                    }

                </LineEnteringBoxContainer>

                {
                    isLoading && (
                        <div className="loading-container global-form-column">
                            <img src="/images/Loading_1.svg" />
                            <label className="loading">Loading...</label>
                        </div>
                    )
                }

            </div>
        </>
    )
}