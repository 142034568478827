import React from 'react'
import { Routes } from "../../routes/routes"
import GlobalStyles from "../globalStyles/globalStyles";
import { connect } from "react-redux";

function Main({ store }) {
    var backgroundImage = store.appearance.image === undefined ? "/images/background_1.png" : store.appearance.image
    
    return (
        <>
            <GlobalStyles colors={{primaryColor: store.appearance.primaryColor }}/>
            <div className="master-container" style={{backgroundImage: `url("${backgroundImage}")`}}>
                <Routes />
            </div>
        </>
    )
}

export default connect(state => ({ 
    store: state 
  }))(Main);