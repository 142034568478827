import React, { useEffect, useState } from 'react';
import { systemsModuleApi } from '../../../../services/api';
import { Card, Table, Row, Col, Button, Form, Input, Drawer, Select, Space, Upload, message, Popconfirm } from "antd";
import { CloudUploadOutlined } from '@ant-design/icons';
import { getFile } from '../../../../utils/helpers';
import moment from 'moment';

export default function SiteDocuments(props) {
    const [form] = Form.useForm();
    const [search] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [list, setList] = useState([]);
    const [showUpload, setShowUpload] = useState(true);
    const [fileTypes, setFileTypes] = useState('.pdf');
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Document Type',
            dataIndex: 'type',
            key: 'type',
            render: (text, item) => {
                if (text === 0) {
                    return 'Official';
                } else if (text === 1) {
                    return 'User uploaded';
                } else {
                    return text;
                }
            }
        },
        {
            title: 'File Type',
            dataIndex: 'fileType',
            key: 'fileType',
            render: (text, item) => {
                if (text === 0) {
                    return 'PDF';
                } else if (text === 1) {
                    return 'Image';
                } else {
                    return text;
                }
            }
        },
        {
            title: 'Date',
            dataIndex: 'uploadDate',
            key: 'uploadDate',
            render: (text, item) => (
                moment(text).format('YYYY-MM-DD HH:mm')
            )
        },
        {
            title: '',
            key: 'action',
            render: (text, item) => (
                <div className="text-right">
                    <Button type="primary" href={item.url} target="_blank">Preview</Button>
                </div>
            ),
        }
    ];

    const getDocuments = () => {
        setIsLoading(true);

        systemsModuleApi.get(`/Systems/GetDocuments?id=${props.id}`)
            .then(result => {
                setDocuments(result.data.content);
                setList(result.data.content);
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleSearch = (data) => {
        let list = documents;
        if (data.q.length > 0) {
            list = list.filter(x => x.name?.toLowerCase().includes(data.q.toLowerCase()));
        }
        setList(list);
    }

    const handleSave = (data) => {
        setIsLoading(true);
        data['siteId'] = props.id;

        let formData = new FormData();
        formData.append("files", data.file);
        delete data['file'];
        formData.append("document", JSON.stringify(data));

        systemsModuleApi.post('/Systems/PostDocument', formData)
            .then(result => {
                handleDrawerClose();
                getDocuments();
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleRemove = (id) => {
        setIsLoading(true);

        systemsModuleApi.delete(`/Systems/DeleteDocument`)
            .then(result => {
                handleDrawerClose();
                getDocuments();
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleDrawerOpen = () => {
        form.resetFields();
        setShowUpload(true);
        setShowDrawer(true);
    }

    const handleDrawerClose = () => {
        form.resetFields();
        setShowUpload(true);
        setShowDrawer(false);
    }

    const handleChangeFileType = (value) => {
        if (value === 0) {
            setFileTypes('.pdf');
        } else if (value === 1) {
            setFileTypes('.jpg, .png');
        }
    }

    useEffect(() => {
        getDocuments();
    }, [])

    return (
        <>
            <div>
                <Row gutter={[8, 10]} wrap={false} className="text-right mb-2" align="middle">
                    <Col span={24} md={12} xl={8} xxl={6}>
                        <Form form={search} layout="inline" onFinish={handleSearch}>
                            <Form.Item className="mb-0" name="q" wrapperCol={{ span: 24 }} style={{ flex: 'auto' }}>
                                <Input placeholder="Search" />
                            </Form.Item>
                            <Button type="primary" htmlType="submit">Filter</Button>
                        </Form>
                    </Col>
                    <Col span={24} md={12} xl={16} xxl={18}>
                        <Button type="secondary" onClick={() => handleDrawerOpen({})}>Add Document</Button>
                    </Col>
                </Row>
                <Table rowKey="_id" bordered={true} columns={columns} dataSource={list} pagination={false} loading={isLoading} />
            </div>
            <Drawer
                visible={showDrawer}
                title="Manage Document"
                mask={false}
                getContainer="#main-container"
                destroyOnClose={true}
                onClose={handleDrawerClose}
            >
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSave}
                >
                    <Card bordered={false} style={{ width: "600px" }}>
                        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Document Type" name="type" rules={[{ required: true }]}>
                            <Select>
                                <Select.Option value={0}>Official</Select.Option>
                                <Select.Option value={1}>User uploaded</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="File Type" name="fileType" rules={[{ required: true }]}>
                            <Select onChange={handleChangeFileType}>
                                <Select.Option value={0}>PDF</Select.Option>
                                <Select.Option value={1}>Image</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="File" name="file" valuePropName="file" getValueFromEvent={getFile} rules={[{ required: true }]}>
                            <Upload
                                accept={fileTypes}
                                beforeUpload={() => {
                                    setShowUpload(false);
                                    return false;
                                }}
                                onRemove={() => {
                                    setShowUpload(true);
                                }}
                            >
                                {showUpload && <Button type="secondary" icon={<CloudUploadOutlined />}>Click to Upload</Button>}
                            </Upload>
                        </Form.Item>
                    </Card>
                    <Space className="form-actions">
                        <Button type="secondary" onClick={handleDrawerClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={isLoading} >Save</Button>
                    </Space>
                </Form>
            </Drawer>
        </>
    );
}