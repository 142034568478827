const INITIAL_STATE = []

const SAMPLE_STATE = [
    {
        _id: 1,
        name: "system name 01 name test 1234567890",
        top_alert_status: "minor",
        alert_count: 8,
        alerts: [
            {
                _id: 1,
                system_id: 1,
                active: true,
                status: 3
            },
            {
                _id: 2,
                system_id: 1,
                active: true,
                status: 1
            },
            {
                _id: 3,
                system_id: 1,
                active: true,
                status: 1
            },
            {
                _id: 4,
                system_id: 1,
                active: true,
                status: 1
            },
            {
                _id: 5,
                system_id: 1,
                active: true,
                status: 1
            },
            {
                _id: 6,
                system_id: 1,
                active: true,
                status: 1
            },
            {
                _id: 7,
                system_id: 1,
                active: true,
                status: 1
            },
            {
                _id: 8,
                system_id: 1,
                active: true,
                status: 1
            }
        ],
        location: {
            lat: -2.2884,
            lng: 36.8233
        }
    }
]

export default function alerts(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ("GENERATE_SYSTEMS"):
            return {
                ...state, 
                systems: action.systems
            };
        case ("UPDATE_ALERTS"):
            return {
                ...state
            };
        default:
            return state
    }
}