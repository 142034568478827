import React, { useRef, useState, useEffect } from 'react';
import { proximityModuleApi } from "../../../services/api";
import { FormContactTracing } from "../../../components/form/formContainer/formContactTracing";
import { ContactTracingBoxContainer } from '../../../components/proximityManagement/proximityBoxStyle/proximityBoxStyle';
import { Form } from "@unform/web";
import Input from "../../../components/form/input/input";
import Slides from "../../../components/form/slides/slides";
import Alert from "../../../components/alert/alert";
import { loginApi } from "../../../services/api";
import "./contactTracing.css";
import moment from "moment";
const axios = require('axios');

export default function ContactTracing({ ...props }) {

    const formRef = useRef(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [openAlertConfirmation, setOpenAlertConfirmation] = useState(false);
    const [peopleCounter, setPeopleCounter] = useState(0)
    const [isLoading, setIsLoading] = useState(true);
    const [companyName, setCompanyName] = useState("Company Name");
    const [idCompany, setIdCompany] = useState(null);
    var slidesLabel = "Share my personal data with " + companyName;

    const proximityModuleApi = axios.create({
        baseURL:"http://proximity.tascsystems.com",
        //baseURL: 'http://localhost:8083/',
        timeout: 5000,
        headers: {
            "Authorization": "c9ff81fe25",
        }
    });

    useEffect(async () => {
        setIsLoading(false)
        
        var url = window.location.href
        var subDomain = /:\/\/([^\/]+)/.exec(url)[1];
      
        var result = await loginApi.get('/login/GetCompanyBySubdomain', {
            params: {
                subDomain
            }
        });
        if (result.status === 200) {
            setCompanyName(result.data.content.name)
            setIdCompany(result.data.content._id)
        }
    
    }, [])

 
    const checkInMethod = (formData) => {
        try {
            const { name, email, phone, numberOfPeople, shareData } = formData;
            const date = moment().format();
            if (phone != '' || email != '') {
                setOpenAlert(false);
                proximityModuleApi.post('/Proximity/CheckIn', {
                    idCompany,
                    name,
                    email,
                    phone,
                    numberOfPeople,
                    date,
                    shareData
                }).then(function (response) {
                    if (response.status == 200) {
                        setIsLoading(false)
                        setOpenAlertConfirmation(true)

                    }
                })
                    .catch(function (error) {
                        setIsLoading(false)
                        console.log(error.response);
                    });
            } else {
                setOpenAlert(true);
            }

        } catch (err) {
            setIsLoading(false)
            console.log(err)
        }
    };

    return (
        <>
            <div style={{
                backgroundImage: `url("/images/container-background.png")`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: "100% 100%",
                minHeight: "100%",
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                width: '100%',

            }}>

                <ContactTracingBoxContainer>
                    <FormContactTracing>
                        <div><img className="company-icon" src="/images/company-icon.png"></img> </div>
                        <Form ref={formRef} onSubmit={checkInMethod}>
                            <div className="form-row">
                                <Input name="name" label="Name" style={{ background: "#FBFBFB" }} />
                            </div>
                            <div className="form-row">
                                <Input name="email" label="Email" type="email" style={{ background: "#FBFBFB" }} />
                            </div>
                            <div className="form-row">
                                <Input type="text" mask="(999) 999.9999" name="phone" label="Phone Number" style={{ background: "#FBFBFB" }} />
                            </div>
                            <div className="form-row">
                                <label>Persons with you</label>
                            </div>
                            <div className="form-row ">
                                <div className="input-number">
                                    <button type="button" className="subtract" onClick={() => setPeopleCounter(peopleCounter - 1)} > - </button>
                                    <Input style={{ padding: 0 }} isHidden="true" name="numberOfPeople" type="hidden" value={peopleCounter} />
                                    <div className="number-of-people">
                                        {peopleCounter}
                                    </div>
                                    <button type="button" className="add" onClick={() => setPeopleCounter(peopleCounter + 1)}> + </button>
                                </div>
                            </div>
                            <p className="note">Not including you</p>

                            <div className="form-row">
                                <Slides
                                    name="shareData"
                                    className="checkbox"
                                    label= {slidesLabel}
                                />
                            </div>
                            <div className="form-row">
                                <button type="submit" className="submit check-in-button" > CHECK IN</button>
                            </div>
                        </Form>
                    </FormContactTracing>

                    {
                        openAlert && <Alert message={" You have to inform an email or a phone number."} onClose={() => { setOpenAlert(false) }} />

                    }

                    {
                        openAlertConfirmation && <Alert message={"Check in successfully"} onClose={() => {
                            setOpenAlertConfirmation(false)
                            window.location.reload()
                        }} />

                    }

                </ContactTracingBoxContainer>

                {
                    isLoading && (
                        <div className="loading-container global-form-column">
                            <img src="/images/Loading_1.svg" />
                            <label className="loading">Loading...</label>
                        </div>
                    )
                }

            </div>

        </>
    )
}