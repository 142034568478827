import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import Login from "../pages/Login/login"
import RecoveryPassword from "../pages/Login/recovery"
import Launcher from "../pages/launcher/launcher";
import Proximity from "../pages/proximity/proximity";
import ContactTracing from "../pages/proximity/contactTracing/contactTracing";
import Line from "../pages/proximity/line/line";
import LineEntering from "../pages/proximity/lineEntering/lineEntering";
import GatheringRegister from "../pages/gathering/gatheringRegister/gatheringRegister";
import GatheringConfirm from "../pages/gathering/gatheringConfirm/gatheringConfirm";
import History from "./history"
import { isLogged } from "../auth"
import modules from "../modules"
/* eslint-disable */

export const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => (
                isLogged() ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: "/Login", state: { from: props.location } }} />
                )
            )}
        />
    )
}

export const Routes = () => {
    return (
        <Router history={History}>
            <Switch>
                <Route exact path="/Login" component={Login} />
                <Route exact path="/Recovery/:token" component={RecoveryPassword} />
                <Route exact path="/GatheringConfirm/:token" component={GatheringConfirm} />
                <Route exact path="/Gathering" component={GatheringRegister} />
                <Route exact path="/Line-entering" component={LineEntering} />
                <Route exact path="/Line/:id" component={Line} />
                <Route exact path="/Contact-tracing" component={ContactTracing} />
                <PrivateRoute exact path="/" component={Launcher} />
                <PrivateRoute exact path="/Proximity" component={Proximity} />
                {modules.map(module => (
                    module.private ? (
                        <PrivateRoute {...module.routeProps} key={module.name} />
                    ) : (
                        <Route {...module.routeProps} key={module.name} />
                    )
                ))}
                <Redirect to="/" />
            </Switch>
        </Router>
    )
}