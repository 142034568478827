import React, {useEffect, useRef} from 'react'
import InputMask from "react-input-mask";
import {useField} from "@unform/core";
import "./input.scss"
export default function Input({mask = null, classes, name, label, formGroup = true, isHidden = false, ...rest }) {
    const inputRef = useRef(null);
    const {fieldName, registerField, defaultValue, error} = useField(name);
    let divGroup = [];

    useEffect(()=>{
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: "value"
        })
    }, [fieldName, registerField])
    
    if (formGroup) {
        divGroup.push(
            <div key={0}  className={isHidden? "hidden" : (formGroup ? "form-group " : "") + classes}>
                <label className="form-label">{label}</label>
                <InputMask maskPlaceholder="" className={"input " + (error ? "error": "")} mask={ mask != null ? mask : ""} defaultValue={defaultValue} ref={inputRef} {...rest}/>
                { error && <div className="input-error-message"> {error}</div> }
            </div>
        )
    }
    else
        divGroup.push(
            <InputMask key={0} maskPlaceholder="" className={"input " + (error ? "error": "")} mask={ mask != null ? mask : ""} defaultValue={defaultValue} ref={inputRef} {...rest}/>
        )

    return (
        <>
            {divGroup}
        </>        
    )
}
