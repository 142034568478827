import React, { useRef, useState, useEffect } from 'react';
import Input from "../../../components/form/input/inputRaw";
import DatePicker from "../../../components/form/datepicker/datepickerRaw";
import { Form } from "@unform/web";
import { Grid, Row, RowHeader, ColHeader, Col } from "../../../components/grids/spacedGrid/spacedGridStyle"
import ModalAddGathering from "../../../components/modal/modalAddGathering/modalAddGathering"
import GatheringManagement from "../gatheringManagement/gatheringManagement"
import "./gatheringEvent.scss";
import { animated, useTransition } from 'react-spring';
import { proximityModuleApi } from "../../../services/api";
import ConfirmationAlert from "../../../components/alert/confirmationAlert"
import moment from "moment";
import ReactPaginate from 'react-paginate';
import GatheringProvider from "../../../providers/gatheringProvider"
import { Drawer, Row as Line, Col as Column } from 'antd';

const headers = [
    { label: "id", name: "_id" },
    { label: "name", name: "name" },
    { label: "location", name: "location" },
    { label: "limit", name: "limit" },
    { label: "date", name: "date" },
    { label: "time", name: "time" }
];


export default function GatheringEvent() {
    const [showModal, setShowModal] = useState(false);
    const [idEvent, setIdEvent] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [gatheringEvent, setGatheringEvent] = useState([]);
    const [pages, setPages] = useState(1);
    const formRef = useRef(null);
    const submitRef = useRef(null);
    const [alertConfirmation, setAlertConfirmation] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        submitRef.current.click()
    }, [])

    const transitions = useTransition(showModal, null, {
        from: { opacity: 0 },
        enter: { opacity: 1, duration: 350 },
        leave: { opacity: 0, duration: 350 },
    })

    const transitionsTest = useTransition(showDetails, null, {
        from: { transform: 'translateX(100%)', opacity: 0 },
        enter: { transform: 'translateX(0)', opacity: 1 },
        leave: { transform: 'translateX(100%)', opacity: 0 },
        config: { duration: 250 }

    })



    const handleSubmit = (filters) => {

        getGatheringEvents(filters)
        // getGatheringEvents(filters)
    }

    const closeModal = () => {

        setShowModal(false);
    }

    const openModal = () => {
        setShowModal(true);
    }

    const toggleDetail = (eventId, event) => {
        setSelectedEvent(event)
        if (showDetails) {
            submitRef.current.click()
        }
        setShowDetails(!showDetails);
    }

    const getGatheringEvents = async (filter = {}, page, sort = {}) => {
        setIsLoading(true)
        for (var propName in filter) {
            if (filter[propName] === null || filter[propName] === undefined || filter[propName] === "") {
                delete filter[propName];
            }
        }

        var result = await proximityModuleApi.get('/gathering/getGatherinEvent', {
            params: {
                ...filter,
                page: page ? page : 1,
                size: 6,
                sort
            }
        });

        var map = result.data.gathering.map(({ _id, name, location, limit, date, time = "", code }) => {
            if (date)
                date = moment(date).format("ddd, MMMM DD, YYYY");
            return { _id, name, location, limit, date, time, code }
        })
        setGatheringEvent(map);
        setPages(result.data.pages)
        setIsLoading(false)
    }


    const formData = async (data = null) => {

        if (data) {
            data.time = moment(data.date).format('hh:mm A')
            data.date = moment(data.date).format("YYYY-MM-DDTHH:MM:ss.SSSZ");
            var result = await proximityModuleApi.post('/gathering/postGatheringEvent', data);
            if (result.status === 200) {
                closeModal();
                submitRef.current.click()
            }
        }
    }

    const removeGatheringEvent = (idGathering) => {
        var result = proximityModuleApi.delete('/gathering/deleteGatheringEvent', {
            data: {
                idGathering
            }
        }).then((result) => {
            if (result.status === 200) {
                setGatheringEvent(gatheringEvent.filter(item => item._id !== idGathering))
                closeConfirmationAlert()
            }

        }).catch((err) => {
            console.log(err)
        });
    }

    const closeConfirmationAlert = () => {
        setAlertConfirmation(undefined)
    }

    const openConfirmationAlert = (idGathering) => {

        setAlertConfirmation(idGathering)
    }

    const handlePageClick = (data) => {
        let dataFromForm = formRef.current.getData()
        let page = data.selected
        getGatheringEvents(dataFromForm, page + 1)
    };

    return (
        <>
            <GatheringProvider >
                <div className="form-container">
                    <div className="form-container-gathering">
                        <Form ref={formRef} onSubmit={handleSubmit}>
                            <Line gutter={[12, 12]}>
                                <Column xs={24} md={10}>
                                    <Input name="name" label="name" />
                                </Column>
                                <Column xs={24} md={10}>
                                    <DatePicker isClearable name="date" label="date" />
                                </Column>
                                <Column xs={24} md={4}>
                                    <button ref={submitRef} type="submit" className="submit">Filter</button>
                                </Column>
                            </Line>
                            {/* <div className="form-row">
                                <Input name="name" label="name" />
                                <DatePicker isClearable name="date" label="date" />
                                <div className="form-group" style={{ flex: 0.4 }}>
                                    <button ref={submitRef} type="submit" className="submit">Filter</button>
                                </div>

                            </div> */}

                        </Form>
                    </div>
                    <div className="add-gathering-box">
                        <button onClick={openModal} type="button" className="btn-add-gathering">Add Gathering</button>
                    </div>
                </div>

                <Grid>
                    <RowHeader>
                        {
                            headers && headers.map((element, index) => {
                                if (element.label == "location") {
                                    return <ColHeader className="hidden-xs" key={element.label} display={element.label == "id" ? "none" : ""} size={1}>{element.label}</ColHeader>
                                }

                                return <ColHeader key={element.label} display={element.label == "id" ? "none" : ""} size={1}>{element.label}</ColHeader>
                            })
                        }
                        <ColHeader size={2}></ColHeader>
                    </RowHeader>
                    {
                        gatheringEvent.length > 0 && gatheringEvent.map((element, index) => {
                            return (
                                <Row key={element["_id"]}>
                                    {

                                        Object.keys(element).map((key) => {
                                            if (key == "location") {
                                                return (
                                                    <Col className="hidden-xs" key={Math.random()} display={key == "_id" ? "none" : ""} size={1}>
                                                        { element[key] ? element[key].name.toString() : "---"}
                                                    </Col>
                                                )
                                            }
                                            else if (key == "code") {

                                            }
                                            else {
                                                return <Col key={Math.random()} display={key == "_id" ? "none" : ""} size={1}>{element[key].toString()}</Col>
                                            }
                                        })
                                    }
                                    <Col key={Math.random()} size={2}>
                                        <div className="action-container">
                                            <button onClick={() => toggleDetail(element["_id"], element)} className="btn-action" type="button">Manage</button>
                                            <button onClick={() => openConfirmationAlert(element["_id"])} className="btn-action" type="button">Remove</button>
                                        </div>
                                    </Col>
                                </Row>
                            );
                        })
                    }
                    {
                        gatheringEvent.length == 0 && (
                            <Row>
                                <Col size={1}>
                                    Events not found
                                </Col>
                            </Row>
                        )
                    }
                </Grid>
                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
                {
                    transitions.map(({ item, key, props }) =>
                        item && <animated.div key={key} style={props}><ModalAddGathering formData={formData} closeModal={closeModal} /></animated.div>
                    )

                }
                {

                    <Drawer
                        visible={showDetails}
                        title="Gathering management"
                        mask={false}
                        getContainer="#main-container"
                        destroyOnClose={true}
                        onClose={toggleDetail}
                    >
                        <GatheringManagement event={selectedEvent} toggleDetail={toggleDetail} />
                    </Drawer>

                    //     transitionsTest.map(({item, key, props}) =>
                    //         item && <animated.div className="drawer" key={key} style={props}>

                    // </animated.div>
                    //     )

                }

                {
                    alertConfirmation && <ConfirmationAlert
                        idToRemove={alertConfirmation}
                        doAction={removeGatheringEvent}
                        closeAlert={closeConfirmationAlert}
                        message={"Do you really want remove this item?"} />
                }

                {
                    isLoading && (
                        <div className="loading-container global-form-column">
                            <img src="/images/Loading_1.svg" />
                            <label className="loading">Loading...</label>
                        </div>
                    )
                }

            </GatheringProvider>


        </>
    )
}
