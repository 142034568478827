import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import moment from "moment";
import { Card, Form, Select, Row, Col, message, Button, Input, Upload, Space } from 'antd'
import { GetUsers, GetLoggedUser, GetCompany } from "../../../services/services";
import { configurationModuleApi } from "../../../services/api";
import { getIdUser, getIdCompany } from '../../../auth';
import './account.scss';
import { getBase64 } from '../../../utils/helpers';
import { UserOutlined, CloudUploadOutlined } from '@ant-design/icons';

const Account = ({ store, dispatch }) => {
  let urlUserImg = "/images/user-no-img.png"

  const [formAccount] = Form.useForm();
  const [formCompany] = Form.useForm();
  const [users, SetUsers] = useState([]);
  const [delegateList, SetDelegateList] = useState([{ value: 0, label: "Nobody (do not delegate)" }]);
  const [approverList, SetApproverList] = useState([{ value: 0, label: "Myself" }]);
  const [user, SetLoggedUser] = useState({});
  const [company, SetCompany] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCompany, setIsLoadingCompany] = useState(true);
  const [file, setFile] = useState(undefined);

  useEffect(async () => {
    SetLoggedUser(await GetLoggedUser(getIdUser()));
    SetUsers(await GetUsers());
    SetCompany(await GetCompany(getIdCompany()));
  }, [])

  async function handleCompanySubmit(data) {
    try {
      setIsLoadingCompany(true)
      if (data) {
        configurationModuleApi.put('/account/EditCompany?id=' + company._id, data)
          .then((res) => {
            message.success('Account settings updated successfully');
          }).catch(() => {
            message.error('Error saving settings');
          })
          .finally(() => {
            setIsLoadingCompany(false);
          });
      }
      else
        setIsLoadingCompany(false)
    } catch (err) {
      setIsLoadingCompany(false)
      message.error('Something went wrong.');
    }
  }

  async function handleAccountSubmit(data) {
    try {
      setIsLoading(true)
      data.picture = file || data.picture;
      if (data) {
        configurationModuleApi.put('/account/SetAccountConfiguration?id=' + user._id, data)
          .then((res) => {
            message.success('Account settings updated successfully');
          }).catch(() => {
            message.error('Error saving settings');
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
      else
        setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      message.error('Something went wrong.');
    }
  }

  useEffect(async () => {
    let approverList = []
    let delegatelist = []

    users.forEach(usr => {
      let u = {
        value: usr._id,
        label: usr.name
      }
      if (!usr.delegateTo && usr._id !== user._id)
        delegatelist.push(u)

      if (usr._id !== user._id)
        approverList.push(u)
    });

    delegatelist.unshift({ value: 0, label: "Nobody (do not delegate)" })
    approverList.unshift({ value: 0, label: "Myself" })

    SetApproverList(approverList);
    SetDelegateList(delegatelist)

    formAccount.setFieldsValue({
      email: user.login,
      lastLogin: moment(user.LastLogin).format("dddd, MMMM DD, YYYY HH:MM:ss"),
      delegateTo: user.delegateTo,
      expensesApprover: user.expensesApprover
    })
    setFile(user.picture);

    formCompany.setFieldsValue({
      phone: company.phoneNumber,
      address: (company.Address ? company.Address.street : "") + ', ' + (company.Address ? company.Address.city : "") + ", " + (company.Address ? company.Address.province : "") +
        ' - ' + (company.Address ? company.Address.postalCode : "") + ', ' + (company.Address ? company.Address.country : ""),
      contactPerson: company.contactPerson,
      email: company.email
    })

    setIsLoading(false)
    setIsLoadingCompany(false)
  }, [users, company])


  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={24} lg={12}>
          <Card bordered={false} className="h-100">
            <Form form={formAccount} onFinish={handleAccountSubmit} layout="vertical">
              <Form.Item className="mb-0" name="picture">
                <Space align="center">
                  <Upload
                    accept=".jpg,.jpeg,.png"
                    listType="picture"
                    showUploadList={false}
                    beforeUpload={file => {
                      getBase64(file, img => setFile(img));
                      return false;
                    }}
                  >
                    {file ? <img src={file} /> : (<UserOutlined />)}
                    <CloudUploadOutlined/>
                  </Upload>
                  <span className="fw-bold fs-4">{user.name}</span>
                </Space>
              </Form.Item>
              <Form.Item label="Email" name="email" className="mt-1" >
                <Input disabled />
              </Form.Item>
              <Form.Item label="Last login" name="lastLogin">
                <Input disabled />
              </Form.Item>
              <Form.Item name="delegateTo" label='Delegate functions to'>
                <Select>
                  {delegateList && (delegateList.map((item) => {
                    return (<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)
                  }))}
                </Select>
              </Form.Item>
              <Form.Item name="expensesApprover" label="Expenses approver">
                <Select>
                  {approverList && (approverList.map((item) => {
                    return (<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)
                  }))}
                </Select>
              </Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>Save</Button>
            </Form>
          </Card>
        </Col>
        <Col span={24} lg={12}>
          <Card bordered={false} className="h-100">
            <Form form={formCompany} layout="vertical" onFinish={handleCompanySubmit}>
              <div className="mb-3">
                <span className="fw-bold fs-4">{company.name}</span>
              </div>
              <Form.Item label="Phone" name="phone" className="mt-1" >
                <Input disabled />
              </Form.Item>
              <Form.Item label="Address" name="address" >
                <Input disabled />
              </Form.Item>
              <Form.Item label="Contact Person" name="contactPerson" >
                <Input />
              </Form.Item>
              <Form.Item label="Contact Email" name="email" >
                <Input />
              </Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoadingCompany}>Save</Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default connect(state => ({
  store: state
}))(Account);