import React, { useEffect, useRef, Component } from 'react';
import QRious from 'qrious';
import "./PDFContact.css"
import { loginApi } from "../../../services/api";

export default class GatheringToPrint extends Component {
    render() {

        var url = window.location.href
        var subDomain = /:\/\/([^\/]+)/.exec(url)[1];
        var size = "240"

        var QRCodes = () => {
            
            const canvas = useRef(null);
            useEffect(() => {

                var result = loginApi.get('/login/GetCompanyBySubdomain', {
                    params: {
                        subDomain
                    }
                });

                if (canvas != null && canvas.current != null) {
                    var qr = new QRious({
                        element: canvas.current,
                        value: "http://" + subDomain + "/gathering",
                        size: size,

                    });
                }
            });
            return (<canvas ref={canvas}></canvas>);

        }

        return (
            <div>
                <h2 className="pdf-title"><b>Welcome</b></h2>
                <div>
                    <span className = "pdf-header"><b>Please check in</b></span>
                <div className = "pdf-text">
                    <span> Open the Camera app on your phone, scan the QR code, tap the link and check in</span>
                </div>
                    <div className="align-qrcode" >
                        <QRCodes subDomain={subDomain} size={size} />
                    </div>

                </div>
            </div>
        );
    }
}