import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { systemsModuleApi } from '../../../../services/api';
import { getLoggedUser } from '../../../../auth';
import { Comment, Row, Col, Avatar, message, Form, Input, Button, Select, Upload, Image, Space, Divider } from "antd";
import { CloudUploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;

export default function SiteActivity(props) {
    const user = getLoggedUser();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [fileList, setFileList] = useState([]);

    const getActivities = () => {
        setIsLoading(true);

        systemsModuleApi.get(`/Systems/GetActivities?id=${props.id}`)
            .then(result => {
                setList(result.data.content);
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleSave = (data) => {
        setIsLoading(true);
        data['siteId'] = props.id;

        let formData = new FormData();
        fileList.map(file => {
            formData.append("files", file.originFileObj);
        });
        formData.append("activity", JSON.stringify(data));

        systemsModuleApi.post(`/Systems/PostActivity`, formData)
            .then(result => {
                form.resetFields();
                getActivities();
                setFileList([]);
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getActivities();
    }, [])

    return (
        <>
            <div>
                {list.length > 0 && (
                    <div className="mb-2" style={{ maxHeight: '50vh', overflow: 'auto' }}>
                        {list.map(item =>
                            <Comment
                                key={item._id}
                                author={item.userId?.name}
                                avatar={<Avatar src={item.userId?.picture} alt={item.userId?.name} />}
                                content={
                                    <>
                                        <p>{item.activity}</p>
                                        <Space className="mt-1" size="middle">
                                            {item.pictures.map(p => <Image className="rounded" key={p} width={140} src={p} />)}
                                        </Space>
                                    </>
                                }
                                datetime={moment(item.dateTime).format('YYYY-MM-DD @ HH:mm')}
                            />
                        )}
                    </div>
                )}
                <Divider />
                <Row gutter={[16, 16]} wrap={false}>
                    <Col flex="32px">
                        <Avatar src={user.picture} alt={user.name} />
                    </Col>
                    <Col flex="auto">
                        <Form
                            form={form}
                            layout="vertical"
                            requiredMark={false}
                            onFinish={handleSave}
                            style={{ maxWidth: 470 }}
                        >
                            <Form.Item label="Activity" name="activity" rules={[{ required: true }]}>
                                <TextArea />
                            </Form.Item>
                            <Form.Item label="Activity Type" name="type" rules={[{ required: true }]}>
                                <Select>
                                    <Select.Option value={0}>Site survey</Select.Option>
                                    <Select.Option value={1}>Site survey</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Pictures">
                                <Upload
                                    accept=".jpg,.jpeg,.png"
                                    listType="picture-card"
                                    fileList={fileList}
                                    showUploadList={{ showPreviewIcon: false }}
                                    beforeUpload={() => {
                                        return false;
                                    }}
                                    onChange={({ fileList }) => setFileList(fileList)}
                                >
                                    {fileList.length < 5 ? <div><CloudUploadOutlined /><p>Upload here</p></div> : null}
                                </Upload>
                            </Form.Item>
                            <Button type="primary" htmlType="submit" loading={isLoading}>Save</Button>
                        </Form>
                    </Col>
                </Row>
            </div>
        </>
    )
}