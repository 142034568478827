import { TasksStatus, LauncherWidget, ModuleType } from '../utils/enums'
import { expesesModuleApi, taskModuleApi, configurationModuleApi } from "./api";

import { getIdCompany, getIdUser, Logout } from '../auth';
import History from '../routes/history';

//#region Get
export const GetCompany = ( _id ) => {
    return new Promise(function(resolve, reject) {
        configurationModuleApi.get('/account/GetCompany?id=' + _id).then((res) => {
            if (res && res.status == 200) 
                resolve(res.data.content)
        })
    });
}

export const GetCompanies = () => {
    return new Promise(function(resolve, reject) {
        configurationModuleApi.get('/account/GetCompanies').then((res) => {
            if (res && res.status == 200) 
                resolve(res.data.content)
        })
    });
}

export const GetExpenses = () => {
    return new Promise(function(resolve, reject) {
        expesesModuleApi.get('/Expense/GetExpenses').then((res) => {
            if (res && res.status == 200) 
                resolve(res.data.content)
        })
    });
}

export const GetExpensesCategories = async () => {
    return new Promise(function(resolve, reject) {
        configurationModuleApi.get('/configuration/GetExpenseCategories').then((res) => {
            if (res && res.status == 200) 
                resolve(res.data.content)
        })
    });
}

export const GetModules = () => {
    return new Promise(function(resolve, reject) {
        configurationModuleApi.get('/account/GetModules?id=' + getIdCompany()).then((res) => {
            if (res && res.status == 200) {
                let modules = [];

                res.data.content.forEach(module => {
                    let widget = {}
                    
                    switch (module) {
                        case "Reports":
                            widget = { type: ModuleType.REPORTS, width: LauncherWidget.SMALL }
                            break;
                        case "Views":
                            widget = { type: ModuleType.VIEWS, width: LauncherWidget.SMALL }
                            break;
                        case "Expenses":
                            widget = { type: ModuleType.EXPENSES, width: LauncherWidget.SMALL }
                            break;
                        case "Proximity":
                            widget = { type: ModuleType.PROXIMITY, width: LauncherWidget.SMALL }
                            break;
                        case "Settings":
                            widget = { type: ModuleType.SETTINGS, width: LauncherWidget.SMALL }
                            break;
                        case "Emergency":
                            widget = { type: ModuleType.EMERGENCY, width: LauncherWidget.SMALL }
                            break;
                        case "Network":
                            widget = { type: ModuleType.NETWORK, width: LauncherWidget.SMALL }
                            break;
                        case "Systems":
                            widget = { type: ModuleType.SYSTEMS, width: LauncherWidget.LARGE }
                            break;
                        case "Tasks":
                            widget = { type: ModuleType.TASK, width: LauncherWidget.LARGE }
                            break;
                        default:
                            break;
                    }   
                    
                    modules.push(widget);
                });
                resolve(modules)
            }
        })
        .catch(() => {
            Logout();
            History.push('/Login');
        })
    });
}

export const GetPlaces = () => {
    return new Promise(function(resolve, reject) {
        configurationModuleApi.get('/configuration/GetPlaces').then((res) => {
            if (res && res.status == 200) 
                resolve(res.data.content)
        })
    });
}

export const GetProfiles = () => {
    return new Promise(function(resolve, reject) {
        configurationModuleApi.get('/account/GetProfiles').then((res) => {
            if (res && res.status == 200) 
                resolve(res.data.content)
        })
    });
}

export const GetTasks = () => {
    return new Promise(function(resolve, reject) {
        taskModuleApi.get('/Tasks/GetTasks').then((res) => {
            if (res && res.status == 200) {
                let tasks = []

                res.data.content.forEach(task => {
                    if (task.status === undefined || task.status === null) 
                        task.status = TasksStatus.TODO
                    else if (task.status.toLowerCase() == TasksStatus.DOING.toLowerCase()) {
                        // TODO: How do I now the task is delayed?
                        // if (task.done === null && task.due)
                        if (task.done === null)
                            task.status = TasksStatus.DELAYED
                    }
                    tasks.push(task)
                });
                resolve(tasks)
            }
        })
    });
}

export const GetTasksCategories = () => {
    return new Promise(function(resolve, reject) {
        configurationModuleApi.get('/configuration/GetTaskCategories').then((res) => {
            if (res && res.status == 200)
                resolve(res.data.content)
        })
    });
}

export const GetUsers = () => {
    return new Promise(function(resolve, reject) {
        configurationModuleApi.get('/account/GetUsers').then((res) => {
            if (res && res.status == 200) 
                resolve(res.data.content)
        })
    });
}

export const GetLoggedUser = () => {
    let id = getIdUser()
    return new Promise(function(resolve, reject) {
        configurationModuleApi.get('/account/GetUserById?id=' + id).then((res) => {
            if (res && res.status == 200) 
                resolve(res.data.content)
        })
    });
}
//#endregion 