import React, { useEffect, useRef, Component } from 'react';
import QRious from 'qrious';
import "./PDFContact.css"
import { loginApi } from "../../../services/api";

export default class ContactToPrint extends Component {
    render() {

        var url = window.location.href
        var subDomain = /:\/\/([^\/]+)/.exec(url)[1];
        var size = "240"

        var QRCodes = () => {

            const canvas = useRef(null);
            useEffect(() => {

                var result = loginApi.get('/login/GetCompanyBySubdomain', {
                    params: {
                        subDomain
                    }
                });

                if (canvas != null && canvas.current != null) {
                    var qr = new QRious({
                        element: canvas.current,
                        value: "http://" + subDomain + "/Contact-tracing",
                        size: size,

                    });
                }
            });
            return (<canvas ref={canvas}></canvas>);

        }

        return (
            <div>
                <h2 className="pdf-title"><b>Welcome</b></h2>
                <div className="instructions">
                    <span>In line with Govermment Test and Trace Guidance, please could you provide us with your contact details by scanning the QR code below,
                    and enter your details into the website form, this is so you can be contacted int the event that another visitor shows symptoms of COVID-19 </span>
                    <div className="align-img" >
                        <QRCodes subDomain={subDomain} size={size} />
                    </div>
                    <div className="secure-text">
                        <span>This information will be held by us on secure servers, solely for the purpose of Test e Trace, and will be provided to the NHS on request.</span>
                        <h2 className="pdf-footer" ><b>Thank you for your cooperation</b></h2>

                    </div>

                </div>
            </div>

        );
    }


}

