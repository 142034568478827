import React, { useState, useEffect } from 'react';
import { Card, Button, Space, Form, Input, Row, Col, Switch, message } from 'antd';
import { configurationModuleApi } from '../../../../services/api';
import { GetCompanies } from "../../../../services/services";

export default function ClientsForm({ ...props }) {
    let client = props.object
    let company = props.company
    let drawerAdd = props.addClient
    let drawerEdit = props.editClient

    const [isLoading, setIsLoading] = useState(false);
    const [formRef] = Form.useForm();
    const [isNew, setIsNew] = useState(true);
    const [model, setModel] = useState(false);

    const handleSubmit = async (data) => {
        setIsLoading(true)

        if (data) {
            let modules = []
            if (data.network == true)
                modules.push("Network")
            if (data.expenses == true)
                modules.push("Expenses")
            if (data.proximity == true)
                modules.push("Proximity")
            if (data.reports == true)
                modules.push("Reports")
            if (data.settings == true)
                modules.push("Settings")
            if (data.task == true)
                modules.push("Tasks")
            if (data.views == true)
                modules.push("Views")
            if (data.systems == true)
                modules.push("Systems")

            let saveCompany = {
                name: data.name,
                phoneNumber: data.phoneNumber,
                email: data.login,
                contactPerson: data.user_name,
                parentId: company,
                isReseller: data.reseller,
                address: {
                    unit: data.unit,
                    country: data.country,
                    city: data.city,
                    province: data.province,
                    street: data.street,
                    postalCode: data.postalCode
                },
                Modules: modules
            }
            if (client) {
                configurationModuleApi.put('/account/EditCompany?id=' + client._id, saveCompany).then(async (res) => {
                    if (res && res.status == 200) {
                        setModel(res.data.content);
                        GetCompanies().then(async (res) => {
                            let clientList = []

                            res.forEach(company => {
                                let cli = {}

                                cli._id = company._id
                                cli.name = company.name
                                cli.isReseller = cli.isReseller ? cli.isReseller : false
                                cli.active = cli.active ? cli.active : false
                                cli.phoneNumber = company.phoneNumber
                                cli.clients = company.children ? company.children.length : 0

                                clientList.push(cli)
                            });

                            message.success("Clients updated successfully")

                        }).catch((err) => {
                            console.log(err)
                            message.error("Something went wrong.")
                        })
                            .finally(() => {
                                setIsLoading(false)
                            });
                    }
                })
                    .catch(() => {
                        message.error('Something went wrong!');
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });

            }
            else {
                let postUser = {
                    name: data.user_name,
                    login: data.login,
                    password: data.password,
                    isAdmin: true,
                    isMain: true,
                    parentId: company,
                }
                configurationModuleApi.post('/account/PostCompany', saveCompany).then(async (res) => {
                    if (res && res.status == 200) {
                        setModel(res.data.content);
                        configurationModuleApi.post('/account/PostUser', postUser).then(async (res) => {
                            if (res && res.status == 200) {
                                GetCompanies().then(async (res) => {
                                    let clientList = []

                                    res.forEach(company => {
                                        let cli = {}

                                        cli._id = company._id
                                        cli.name = company.name
                                        cli.isReseller = cli.isReseller ? cli.isReseller : false
                                        cli.active = cli.active ? cli.active : false
                                        cli.phoneNumber = company.phoneNumber
                                        cli.clients = company.children ? company.children.length : 0

                                        clientList.push(cli)
                                    });

                                    setIsNew(false);
                                })

                                message.success("Clients created successfully")
                            }
                        })
                    }
                })
                    .catch(() => {
                        message.error('Something went wrong!');
                    })
                    .finally(() => {

                        setIsLoading(false);
                    });
            }
        }

    }
    useEffect(async () => {
        if (client._id) {
            formRef.setFieldsValue({
                name: client.name,
                phoneNumber: client.phoneNumber,
                unit: client.address.unit,
                street: client.address.street,
                province: client.address.province,
                postalCode: client.address.postalCode,
                country: client.address.country,
                city: client.address.city,
                reseller: client.isReseller,
                systems: client.Modules.find(m => m === "Systems") ? true : false,
                reports: client.Modules.find(m => m === "Reports") ? true : false,
                views: client.Modules.find(m => m === "Views") ? true : false,
                proximity: client.Modules.find(m => m === "Proximity") ? true : false,
                expenses: client.Modules.find(m => m === "Expenses") ? true : false,
                task: client.Modules.find(m => m === "Tasks") ? true : false,
                settings: client.Modules.find(m => m === "Settings") ? true : false,
                network: client.Modules.find(m => m === "Network") ? true : false
            })
        }

        setModel(client)
        setIsNew(!client._id);
    }, [client])

    const closeDrawer = () => {
        props.onClose()
    }

    const handleRemoveItem = () => {
        try {
            setIsLoading(true)
            configurationModuleApi.delete('/account/DeleteCompany?id=' + model._id).then(async (res) => {
                if (res && res.status === 200) {
                    let clientList = []

                    GetCompanies().then(async (res) => {
                        res.forEach(company => {
                            let cli = {}

                            cli._id = company._id
                            cli.name = company.name
                            cli.isReseller = cli.isReseller ? cli.isReseller : false
                            cli.active = cli.active ? cli.active : false
                            cli.phoneNumber = company.phoneNumber
                            cli.clients = company.children ? company.children.length : 0

                            clientList.push(cli)
                        });

                        closeDrawer();
                    })
                    message.success("Clients removed successfully")
                }
            })
                .catch(() => {
                    message.error('Something went wrong!');
                })
                .finally(() => {
                    setIsLoading(false);
                });

        } catch (err) {
            console.log(err)
            setIsLoading(false)
            message.error("Something went wrong.")
        }
    }

    return (
        <Form key={0} form={formRef} onFinish={handleSubmit} requiredMark={false} layout="vertical">
            <Card bordered={false} style={{ maxWidth: "1000px" }}>
                <Row gutter={[20, 0]}>
                    <Col span={24} lg={12}>
                        <div className="mb-1">
                            <span>Client's Info</span>
                        </div>

                        <Form.Item name="name" label="Client Name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="phoneNumber" label="Phone Number" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item extra="Choose if this client can have its own clients.">
                            <Space>
                                <Form.Item name="reseller" className="mb-0" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <span>Is a Reseller</span>
                            </Space>
                        </Form.Item>

                        {drawerAdd && (
                            <>
                                <div className="mb-1">
                                    <span>Address</span>
                                </div>
                                <Row gutter={[10, 0]}>
                                    <Col span={24} md={12}>
                                        <Form.Item name="unit" label="Unit" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Form.Item name="street" label="Address" rules={[{ required: true }]} >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Form.Item name="city" label="City" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Form.Item name="province" label="Province" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Form.Item name="country" label="Country" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Form.Item name="postalCode" label="Postal Code" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}

                    </Col>
                    {drawerAdd && (
                        <>
                            <Col span={24} lg={12}>
                                <div className="mb-1">
                                    <span>Default User</span>
                                </div>

                                <Form.Item name="user_name" label="User Name" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="login" label="Login" extra="Use an email." rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>

                                <div className="mb-1">
                                    <span>Profile: Admin</span>
                                </div>
                                <Row gutter={[10, 0]}>
                                    <Col span={24} lg={12}>
                                        <Form.Item name="password" label="Password" rules={[{ required: true, message: 'Please input your password!' }]}>
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} lg={12}>
                                        <Form.Item name="confirm_password" label="Confirm Password" rules={[{ required: true, message: 'Please input your password!' }]} >
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <div className="mb-1">
                                    <span>Modules</span>
                                </div>
                                <Row gutter={[10, 10]} >
                                    <Col span={12} lg={8}>
                                        <Space>
                                            <Form.Item name="systems" className="mb-0" valuePropName="checked" >
                                                <Switch />
                                            </Form.Item>
                                            <label className="label-check">Systems</label>
                                        </Space>
                                    </Col>
                                    <Col span={12} lg={16}>
                                        <Space>
                                            <Form.Item name="reports" className="mb-0" valuePropName="checked" >
                                                <Switch />
                                            </Form.Item>
                                            <label className="label-check">Reports</label>
                                        </Space>
                                    </Col>
                                    <Col span={12} lg={8}>
                                        <Space>
                                            <Form.Item name="views" className="mb-0" valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                            <label className="label-check">Views</label>
                                        </Space>
                                    </Col>
                                    <Col span={12} lg={16}>
                                        <Space>
                                            <Form.Item name="proximity" className="mb-0" valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                            <label className="label-check">Proximity</label>
                                        </Space>
                                    </Col>
                                    <Col span={12} lg={8}>
                                        <Space>
                                            <Form.Item name="expenses" className="mb-0" valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                            <label className="label-check">Expenses</label>
                                        </Space>
                                    </Col>
                                    <Col span={12} lg={16}>
                                        <Space>
                                            <Form.Item name="task" className="mb-0" valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                            <label className="label-check">Tasks</label>
                                        </Space>
                                    </Col>
                                    <Col span={12} lg={8}>
                                        <Space>
                                            <Form.Item name="settings" className="mb-0" valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                            <label className="label-check">Settings</label>
                                        </Space>
                                    </Col>
                                    <Col span={12} lg={16}>
                                        <Space>
                                            <Form.Item name="network" className="mb-0" valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                            <label className="label-check">Network</label>
                                        </Space>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    )}

                    {drawerEdit && (
                        <>
                            <Col span={24} lg={12}>
                                <div className="mb-1">
                                    <span>Address</span>
                                </div>
                                <Row gutter={[10, 0]}>
                                    <Col span={24} md={12}>
                                        <Form.Item name="unit" label="Unit" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Form.Item name="street" label="Address" rules={[{ required: true }]} >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Form.Item name="city" label="City" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Form.Item name="province" label="Province" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Form.Item name="country" label="Country" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <Form.Item name="postalCode" label="Postal Code" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24} lg={12}>
                                <div className="mb-1">
                                    <span>Modules</span>
                                </div>
                                <Row gutter={[10, 10]} >
                                    <Col span={12} lg={8}>
                                        <Space>
                                            <Form.Item name="systems" className="mb-0" valuePropName="checked" >
                                                <Switch />
                                            </Form.Item>
                                            <label className="label-check">Systems</label>
                                        </Space>
                                    </Col>
                                    <Col span={12} lg={16}>
                                        <Space>
                                            <Form.Item name="reports" className="mb-0" valuePropName="checked" >
                                                <Switch />
                                            </Form.Item>
                                            <label className="label-check">Reports</label>
                                        </Space>
                                    </Col>
                                    <Col span={12} lg={8}>
                                        <Space>
                                            <Form.Item name="views" className="mb-0" valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                            <label className="label-check">Views</label>
                                        </Space>
                                    </Col>
                                    <Col span={12} lg={16}>
                                        <Space>
                                            <Form.Item name="proximity" className="mb-0" valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                            <label className="label-check">Proximity</label>
                                        </Space>
                                    </Col>
                                    <Col span={12} lg={8}>
                                        <Space>
                                            <Form.Item name="expenses" className="mb-0" valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                            <label className="label-check">Expenses</label>
                                        </Space>
                                    </Col>
                                    <Col span={12} lg={16}>
                                        <Space>
                                            <Form.Item name="task" className="mb-0" valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                            <label className="label-check">Tasks</label>
                                        </Space>
                                    </Col>
                                    <Col span={12} lg={8}>
                                        <Space>
                                            <Form.Item name="settings" className="mb-0" valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                            <label className="label-check">Settings</label>
                                        </Space>
                                    </Col>
                                    <Col span={12} lg={16}>
                                        <Space>
                                            <Form.Item name="network" className="mb-0" valuePropName="checked">
                                                <Switch />
                                            </Form.Item>
                                            <label className="label-check">Network</label>
                                        </Space>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    )}
                </Row>
            </Card>

            <Space className="form-actions">
                <Button onClick={handleRemoveItem} type="secondary" disabled={isNew || props.isCurrent} loading={isLoading}>Remove</Button>
                <Button type="primary" htmlType="submit" loading={isLoading}>Save</Button>
            </Space>
        </Form >
    )
}