import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Button, Table, Space, Popconfirm, message, Drawer, Tag} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { configurationModuleApi } from '../../../services/api';
import { GetCompanies } from "../../../services/services";
import { getIdCompany } from '../../../auth';
import ClientsForm from './clientsForm/clientsForm';
import "./clientsSubcompanies.scss"

const ClientsSubcompanies = ({ store, dispatch }) => {

  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [company, setIdCompany] = useState();
  const [object, setObject] = useState({});
  const [showDrawer, setShowDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 
  const [addClient, setAddClient] = useState(false);
  const [editClient, setEditClient] = useState(false);

  const clientsColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Is reseller", dataIndex: "isReseller", key: "isReseller", render: (text, item) => 
      (
        (
          item.isReseller === true || item.isReseller === false
        ) ?
          item.isReseller === true ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />
          : item.isReseller.toString()
      )
    },
    {
      title: "Active", dataIndex: "active", key: "active", render: (text, item) =>
      (
        (
          item.active === true || item.active === false
        ) ?
          item.active === true ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />
          : item.active.toString()
      )
    },
    { title: "Phone", dataIndex: "phoneNumber", key: "phoneNumber" },
    { title: "Clients", dataIndex: "clients", key: "clients" },
    {
      title: '',
      key: 'action',
      render: (item) => (
        <Space size="middle" style={{ display: "flex", justifyContent: "flex-end" }}>
          { company === item._id && (
            <Tag className="medium">It's you</Tag>
          )}
          <Popconfirm title="Do you really want remove this item?" okText="Yes" cancelText="No" onConfirm={() => handleRemoveItem(item._id)}>
            <Button type="secondary" disabled={company === item._id}>Remove</Button>
          </Popconfirm>
          <Button type="primary" onClick={() => handleDrawerOpen(item)}>Edit</Button>
        </Space>
      ),
    }
  ];

  const handleDrawerOpen = (item) => {
    if(item._id)
    {
      setAddClient(false);
      setEditClient(true);
      setObject(companies.find(c => c._id === item._id));
      
    }
    else{
      setObject(false);
      setAddClient(true);
      setEditClient(false);
    }
    setShowDrawer(true);
  }

  const handleDrawerClose = async () => {
    setShowDrawer(false);
    setIsLoading(true);
    await getCompanies();
  }

  useEffect(async () => {
    await getCompanies();
  }, [])

  const getCompanies = async () => {

    GetCompanies()
      .then(async (res) => {
        let clientList = []

        res.forEach(company => {
          let cli = {}

          cli._id = company._id
          cli.name = company.name
          cli.isReseller = company.isReseller ? company.isReseller : false
          cli.active = company.active ? company.active : false
          cli.phoneNumber = company.phoneNumber
          cli.clients = company.Children ? company.Children.length : 0
          
          clientList.push(cli)
        });

        setIdCompany(getIdCompany());
        setCompanies(res);
        setClients(clientList);
        setIsLoading(false)
      })

  }

  const handleRemoveItem = (id) => {
    try {
      setIsLoading(true)
      configurationModuleApi.delete('/account/DeleteCompany?id=' + id).then(async (res) => {
        if (res && res.status === 200) {
          await getCompanies();
          message.success("Clients removed successfully")
        }
      })
        .catch(() => {
          message.error('Something went wrong!');
        })
        .finally(() => {
          setIsLoading(false);
        });

    } catch (err) {
      console.log(err)
      setIsLoading(false)
      message.error("Something went wrong.")
    }
  }

  return (
    <>
      <div className="text-right mb-1">
      <Button onClick={() => handleDrawerOpen({})}>Add Client</Button>
      </div>
        <Table className="table-2" rowKey="_id" columns={clientsColumns} dataSource={clients} pagination={false} loading={isLoading} />
      <Drawer
        visible={showDrawer}
        title="Client Manage"
        mask={false}
        destroyOnClose={true}
        getContainer="#main-container"
        onClose={handleDrawerClose}
      >
        <ClientsForm onClose={handleDrawerClose} object={object} isCurrent={object._id === company} company={company} addClient= {addClient} editClient= {editClient}/>
      </Drawer>

    </>
  )
}

export default connect(state => ({
  store: state
}))(ClientsSubcompanies);
