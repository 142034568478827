import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { systemsModuleApi } from '../../../services/api';
import Loading from '../../../components/loading/loading';
import Map from '../../../components/map/map';
import { Button, Card, Col, Collapse, Drawer, Form, Input, message, Row, Space } from 'antd';
import History from '../../../routes/history';
// import GoogleMap from '../../../components/map/googleMap';
import { DownOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

export default function System() {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [search] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [system, setSystem] = useState({});
    const [sites, setSites] = useState([]);
    const [map, setMap] = useState([]);
    const [showDrawer, setShowDrawer] = useState(false);

    const GetSites = (id) => {
        setIsLoading(true);
        Promise.all([
            systemsModuleApi.get(`/Systems/GetSystems?id=${id}`),
            systemsModuleApi.get(`/Systems/GetSitesBySystemId?id=${id}`)
        ])
            .then(result => {
                let system = result[0].data.content[0];
                let sites = result[1].data.content;
                system.sites = sites;
                setSystem(system);
                setMap(system);
                setSites(sites);
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleSave = (data) => {
        setIsLoading(true);
        data['systemId'] = id;
        systemsModuleApi.post('/Systems/PostSite', data)
            .then(async (res) => {
                GetSites();
                setShowDrawer(false);
                message.success('Site created successfully');
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleSearch = (data) => {
        let map = Object.assign({}, system);
        let sites = system.sites;
        sites = sites.filter(x => x.name.toLowerCase().includes(data.q.toLowerCase()));
        map.sites = sites;
        setMap(map);
        setSites(sites);
    }

    const goSite = (item) => {
        let breadcrumbs = History.location.state.breadcrumbs;
        breadcrumbs.push({ name: item.name, link: `/Systems/System/Site/${item._id}` });
        History.push({
            pathname: `/Systems/System/Site/${item._id}`,
            state: {
                breadcrumbs: breadcrumbs
            }
        });
    };

    useEffect(() => {
        GetSites(id);
    }, []);

    return (
        <>
            <Row gutter={[8, 10]} wrap={false} className="text-right mb-2" align="middle">
                <Col span={24} md={12} xl={8} xxl={6}>
                    <Card bordered={false}>
                        <Form form={search} layout="inline" onFinish={handleSearch}>
                            <Form.Item className="mb-0" name="q" wrapperCol={{ span: 24 }} style={{ flex: 'auto' }}>
                                <Input placeholder="Search" />
                            </Form.Item>
                            <Button type="primary" htmlType="submit">Filter</Button>
                        </Form>
                    </Card>
                </Col>
                <Col span={24} md={12} xl={16} xxl={18}>
                    <Button onClick={() => { setShowDrawer(true); }}>Add Site</Button>
                </Col>
            </Row>

            <Card className="no-padding" bordered={false} >
                <Row gutter={[8, 10]}>
                    <Col span={24} xl={8} xxl={6}>
                        <div className="pt-2 pb-2 pl-2 pr-1">
                            <Space className="sites" direction="vertical" size="small" style={{ maxHeight: 540 }}>
                                {sites && sites.map(site => {
                                    return (
                                        <Collapse
                                            key={site._id}
                                            expandIconPosition="right"
                                            expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
                                        >
                                            <Panel
                                                header={
                                                    <>
                                                        <span className="status mr-1"></span>
                                                        <span onClick={() => goSite(site)}>{site.name}</span>
                                                    </>
                                                }
                                            >
                                                {site.gateways && site.gateways.length > 0 ?
                                                    site.gateways.map(gateway =>
                                                        <div key={gateway._id} className="device">
                                                            <span className="name">{gateway.name}</span>
                                                            <span className="status"></span>
                                                        </div>
                                                    )
                                                    :
                                                    <span>Not avaliable</span>
                                                }
                                            </Panel>
                                        </Collapse>
                                    )
                                })}
                            </Space>
                        </div>
                    </Col>
                    <Col span={24} xl={16} xxl={18}>
                        {map && <Map className="map-devices" width={"auto"} height={"600px"} systems={[map]} onMarkerClick={(site) => goSite(site)} />}
                    </Col>
                </Row>
            </Card>
            <Drawer
                visible={showDrawer}
                title="Add Site"
                mask={false}
                getContainer="#main-container"
                destroyOnClose={true}
                onClose={() => { setShowDrawer(false); }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSave}
                >
                    <Card bordered={false} style={{ width: "600px" }}>
                        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Location">
                            <Form.Item name={['address', 'latitude']} rules={[{ required: true }]}>
                                <Input addonBefore="Latitude" />
                            </Form.Item>
                            <Form.Item name={['address', 'longitude']} rules={[{ required: true }]}>
                                <Input addonBefore="Longitude" />
                            </Form.Item>
                        </Form.Item>
                    </Card>
                    <Space className="form-actions">
                        <Button type="secondary" onClick={() => { setShowDrawer(false); }}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={isLoading} >Save</Button>
                    </Space>
                </Form>
            </Drawer>
            {isLoading && <Loading />}
        </>
    )
}