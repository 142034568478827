import React, { useRef, useEffect, useState } from 'react';
import Input from "../../form/input/input";
import Select from "../../form/select/select";
import DatePicker from "../../form/datepicker/datepicker";
import { Form } from "@unform/web";
import "./modalAddGathering.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import * as Yup from "yup";
import { configurationModuleApi } from "../../../services/api";

const statusList = [
];

export default function ModalAddGathering({ show, title, ...props }) {
    
    const formRef = useRef(null);
    const [location, setLocation] = useState([])
    useEffect(() => {
        configurationModuleApi.get("/configuration/GetPlaces").then((res) => {
            var map = res.data.content.map(({ _id, name}) =>{
                return ({label:name, value:_id})
            })
            setLocation(map)
        }).catch((err) => {
            console.log(err)

        })
    }, [])

    const handleSubmit = async (filter) => {
       
        try {
            const schema = Yup.object().shape({
                name: Yup.string().required("Name is required"),
                limit: Yup.number().typeError("Limit must be a number"),
                location: Yup.string().required("Location is required"),
                date: Yup.date().typeError("Date is required")
            })

            await schema.validate(filter, { abortEarly: false });
            formRef.current.setErrors({});
            props.formData(filter)
        } catch (err) {
            const validationErrors = {};
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });
                formRef.current.setErrors(validationErrors);
            }
        }


    }

    const closeModal = () => {
        props.closeModal(false)
    }

    return (
        <div className="modal-fade modal-add-gathering">
            <div className="modal-context">
                <div className="modal-title">
                    Add gathering
                    <div className="modal-close">
                        <FontAwesomeIcon className="close-icon" onClick={closeModal} icon={faTimes} />
                    </div>
                </div>
                <div className="modal-body">
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <Input name="name" label="Gathering name" />
                        {/* <Input name="location" label="Gathering location" /> */}
                        <Select
                            label="location"
                            name="location"
                            options={location}
                            classNamePrefix="select" />
                        <Input mask="9999" name="limit" label="Limit" />
                        <DatePicker showTimeInput dateFormat="iiii, MMMM dd, yyyy hh:mm a" name="date" label="date" />
                        <div className="modal-actions">
                            <button onClick={closeModal} type="button" className="modal-btn secondary">Cancel</button>
                            <button type="submit" className="modal-btn primary">Save template</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}
