import React, { useEffect, useState } from 'react';
import { BoxContainer } from '../proximityManagement/proximityBoxStyle/proximityBoxStyle';
import "./currentLineBox.css"

function CurrentLineBox({ peopleInLine, peopleBeingServed, timeWaiting }) {
    return (
        <>
         <div className = "content-line-screen">
            <BoxContainer >
                <div className="box-title">CURRENT LINE</div>
                <div className="box box-blue box-info">
                    <div className="text-info">
                        <img className="people-icon" src="/images/people-in-line.png"></img>
                        <span className="info-time">
                            People <br /> in line
                            </span>
                    </div>
                    <div className="info-line">
                        <span className="info-line-count">{peopleInLine}</span>
                    </div>
                </div>
                <div className="box box-green box-info">
                    <div className="text-info">
                        <img className="check-icon" src="/images/check.png"></img>
                        <span className="info-time">
                            Being <br /> served
                            </span>
                    </div>
                    <div className="info-line">
                        <span className="info-line-count">{peopleBeingServed}</span>
                    </div>
                </div>
                <div className="box box-purple box-info">
                    <div className="text-info">
                        <img className="time-icon" src="/images/time-waiting.png"></img>
                        <span className="info-time-waiting">
                            Minutes avg. <br /> waiting
                            
                            </span>
                    </div>
                    <div className="info-line">
                        <span className="info-line-count">{timeWaiting}</span>
                    </div>
                </div>
            </BoxContainer>
            </div>
        </>
    )

}

export default CurrentLineBox;