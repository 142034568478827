import React from 'react'
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faArchive } from '@fortawesome/free-solid-svg-icons'

import { Grid, Row, Col, ColHeader, RowHeader, Tbody} from "./whiteHeaderGridStyle"


export default function WhiteHeaderGrid(props) {
    var { data, headers, height = null, overflowX=null } = props ? props : {};
 
    return (
        <>
            <Grid overflowX = {overflowX}>
                
                <RowHeader>
                    {
                        headers && headers.map((element, index) => {
                            return <ColHeader size={1}>{element}</ColHeader>
                        })
                    }
                </RowHeader>

                <Tbody height={height} >
                    {
                        data && data.map((element, index) => {
                            return (
                                <Row>
                                    {
                                        Object.keys(element).map(key => <Col size={1}>{element[key].toString()}</Col>)
                                    }
                                </Row>
                            );
                        })
                    }
                </Tbody>
              
            </Grid>
      
        
        </>


    )
}