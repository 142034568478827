import React, { useRef, useEffect } from 'react';
import ReactSelect, {
    OptionTypeBase,
    Props as SelectProps,
} from 'react-select';
import { useField } from '@unform/core';
import chroma from "chroma-js";
import "./select.css"

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: '#fff', height:"30px" }),
    // indicatorSeparator: styles => ({ ...styles, backgroundColor: '#fff', height:"30px" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled ? null
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#F4F4F4",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#000",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
       color: "#000",
      ':hover': {
        backgroundColor: "#000",
        cursor:"pointer",
        color: 'white',
      },
    }),
  };



export default function Select({ classes, name, label, ...rest }) {

    const selectRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref) => {
                if (rest.isMulti) {
                    if (!ref.state.value) {
                        return [];
                    }
                    return ref.state.value.map((option) => option.value);
                }
                if (!ref.state.value) {
                    return '';
                }
                return ref.state.value.value;
            },
        });
    }, [fieldName, registerField, rest.isMulti]);
    return (
        <>
            <div className={"form-group " + classes}>
                <label>{label}</label>
                <ReactSelect
                    defaultValue={defaultValue}
                    ref={selectRef}
                    {...rest}
                    // styles={colourStyles}
                />
            </div>
        </>

    );
}
