import React, { forwardRef, useCallback, useEffect, useState, memo, useImperativeHandle, useRef } from 'react'
import { Grid, Row, Col, ColHeader, RowHeader, Tbody } from "../../grids/whiteHeaderGrid/whiteHeaderGridStyle";
import { Icon, InlineIcon } from '@iconify/react'
import bxsContact from '@iconify/icons-bx/bxs-contact'
import bxrightarrowalt from '@iconify/icons-bx/bx-right-arrow-alt';
import bxleftarrowalt from '@iconify/icons-bx/bx-left-arrow-alt';
import trashSharp from '@iconify/icons-ion/trash-sharp';
import accountMultipleCheck from '@iconify/icons-mdi/account-multiple-check';
import ConfirmationAlert from "../../../components/alert/confirmationAlert"
import { proximityModuleApi, smsModuleApi, utilsModuleApi } from "../../../services/api";
import AttendedCounter from "./attendedCounter"
import ContactPopover from "./contactPopover"
import "./selectPeople.scss"
import { Form } from "@unform/web";
import Alert from "../../alert/alert";
import SaveNotify from "../saveAndNotify/saveNotify";
import Input from "../../form/input/input";



import { useGlobalAlert } from "../../../providers/gatheringProvider"

const checkboxOptions = [
    { id: 'cellphone', value: true, label: "" }
];


const registerHeader = [
    { label: "id", name: "_id" },
    { label: "name", name: "name" },
    { label: "people", name: "peopleNumber" }

];

const selectedHeader = [
    { label: "", name: "" },
    { label: "id", name: "_id" },
    { label: "name", name: "name" },
    { label: "contact", name: "contact" },
    { label: "people", name: "peopleNumber" },
    { label: "notified", name: "notified" },
    { label: "confirmed", name: "confirmed" },
    { label: "attended", name: "Attended" }

];


var allPeople = []
var alertMessage = "";

const SelectPeople = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        fillSelectedFromParent() {
            fillSelected()
        }
    }));
    const refs = useRef([]);
    const formRef = useRef(null)
    const [peopleRegistred, setpeopleRegistred] = useState([])
    const [peopleSelected, setPeopleSelected] = useState([])
    const [totalSelected, setTotalSelected] = useState(0);
    const [alertConfirmation, setAlertConfirmation] = useState(undefined);
    const { globalAlert, setGlobalAlert } = useGlobalAlert()
    const [attended, setAttended] = useState({})
    //People selected useEffect
    useEffect(() => {
        if (peopleSelected.length > 0) {
            let totalPeople = peopleSelected.reduce((sum, currentElement) => {
                return (sum + (currentElement.peopleNumber + 1));
            }, 0)

            setTotalSelected(totalPeople)
            props.totalSelected(totalPeople)
        } else {
            setTotalSelected(0)
            props.totalSelected(0)
        }
    }, [peopleSelected])

    useEffect(() => {
        getGatheringEvent()
    }, [props.event])

    //Document.ready
    useEffect(() => {
        if (props.event) {
            getGatheringEvent()
        }
    }, [])


    const getGatheringEvent = () => {
        proximityModuleApi.get('/gathering/getPeopleEvent', {
            params: {
                idEvent: props.event["_id"]
            }
        }).then((result) => {
            allPeople = result.data

            var map = result.data.filter(x => !x.selected).map(({ _id, name, peopleNumber }) => {
                return { _id, name, peopleNumber }
            })
            setpeopleRegistred(map)

            let totalPeopleRegistred = allPeople.reduce((sum, current) => {
                return sum + (current.peopleNumber + 1)
            }, 0)
            props.totalRegistred(totalPeopleRegistred)
            var newmap = result.data.filter(x => x.selected).map(({ _id, name, phoneNumber, email, peopleNumber, notified, confirmed, attended }) => {
                var contact = {};
                contact["phoneNumber"] = phoneNumber;
                contact["email"] = email
                return { _id, name, contact, peopleNumber, notified, confirmed, attended }
            })

            setPeopleSelected(newmap)
            props.totalSelected(newmap.reduce((sum, current) => { return (sum + (current.peopleNumber + 1)) }, 0))

            var attendedObject = {};
            newmap.forEach((ele, i) => {
                attendedObject[ele._id] = ele.attended;
            })
            setAttended(attendedObject)

        }).catch((err) => {
            console.log(err);
        });
    }

    //
    const selectPerson = (personId) => {
        var filterResult = allPeople.filter(x => x._id == personId);
        if ((totalSelected + filterResult[0].peopleNumber + 1) <= props.event["limit"]) {
            addSelectPerson(personId)
        } else {
            setAlertConfirmation({ message: "You exceed the limit, do you want add anymay?", idPerson: personId })
            // setGlobalAlert({ message: "You can't include this person because you exceed limit of the event", icon: null })
            // alert()
        }

    }

    const addSelectPerson = (personId) => {
        var filterResult = allPeople.filter(x => x._id == personId);
        var map = filterResult.map(({ _id, name, peopleNumber, phoneNumber, email, notified, confirmed, attended }) => {
            var contact = {};
            contact["phoneNumber"] = phoneNumber;
            contact["email"] = email
            return { _id, name, contact, peopleNumber, notified, confirmed, attended }
        })


        setPeopleSelected(peopleSelected => [...peopleSelected, map[0]]);
        setpeopleRegistred(peopleRegistred.filter(item => item._id !== personId))
        attended[personId] = 0
        setAttended(attended)
        if (alertConfirmation)
            setAlertConfirmation(undefined)
    }

    const removeSelectPerson = (personId) => {

        var filterResult = allPeople.filter(x => x._id == personId);
        if (filterResult.length == 1) {
            var map = filterResult.map(({ _id, name, peopleNumber }) => {
                return { _id, name, peopleNumber }
            })
            setpeopleRegistred(peopleRegistred => [...peopleRegistred, map[0]]);

            setPeopleSelected(peopleSelected.filter(item => item._id !== personId))
            setAttended({ ...attended, personId: 0 })
        }

    }

    //Choose randomly person to event
    const fillSelected = () => {

        var tries = 0;
        var total = 0;
        var registred = peopleRegistred;
        var selected = [];

        if (peopleSelected.length > 0) {
            let alreadySelected = peopleSelected.reduce((acc, current) => {
                return acc + (current.peopleNumber + 1);
            }, 0)

            total = alreadySelected;
            selected = peopleSelected;
        }


        while (total < props.event.limit) {

            // Check if still existing person not selected
            if (registred.length === 0)
                break;
            let selectIndex = Math.floor(Math.random() * registred.length);
            // get registred person randomly
            var personSelected = registred[selectIndex];

            if (personSelected && (total + personSelected.peopleNumber + 1) <= props.event.limit) {

                selected.push(allPeople.filter(x => x._id === personSelected._id)[0])
                total += personSelected.peopleNumber + 1;
                registred = registred.filter(x => x._id != personSelected._id)
            }


            if (tries === 100)
                break;
            tries++;

        }

        var map = selected.map(({ _id, name, peopleNumber, phoneNumber, email, notified, confirmed, attended }) => {
            var contact = {};
            contact["phoneNumber"] = phoneNumber;
            contact["email"] = email
            return { _id, name, contact, peopleNumber, notified, confirmed, attended }

        })

        setpeopleRegistred(registred)
        setPeopleSelected(map)

    }

    const setNotified = async (personId) => {
        var result = await proximityModuleApi.post('/gathering/toggleNotifiedStatus', { personId: personId })
        var updatedPerson = allPeople.find(person => person._id == personId)
        updatedPerson["notified"] = !updatedPerson["notified"];

        var newState = allPeople.filter(x => x.selected).map(({ _id, name, phoneNumber, email, peopleNumber, notified, confirmed, attended }) => {
            var contact = {};
            contact["phoneNumber"] = phoneNumber;
            contact["email"] = email
            return { _id, name, contact, peopleNumber, notified, confirmed, attended }
        })

        setPeopleSelected(newState)
    }

    const setConfirmed = async (personId) => {
        var result = await proximityModuleApi.post('/gathering/ToggleConfirmedStatus', { personId: personId })
        var updatedPerson = allPeople.find(person => person._id == personId)
        updatedPerson["confirmed"] = !updatedPerson["confirmed"];

        var newState = allPeople.filter(x => x.selected).map(({ _id, name, phoneNumber, email, peopleNumber, notified, confirmed, attended }) => {
            var contact = {};
            contact["phoneNumber"] = phoneNumber;
            contact["email"] = email
            return { _id, name, contact, peopleNumber, notified, confirmed, attended }
        })

        setPeopleSelected(newState)
    }

    const countAttendees = () => {
        props.totalAttendees(1)
    }

    //Saving gathering 
    const saveGathering = (filter) => {
        // if (totalSelected > props.event.limit) {
        //     setGlobalAlert({ message: "You've exceeded your capacity", icon: null })
        //     return 0;
        // }
        allPeople = allPeople.map((element) => {
            element.selected = false
            return element
        })

        Object.keys(filter).forEach((elem, index) => {
            allPeople.find(x => x._id == elem)["attended"] = parseInt(filter[elem])
            allPeople.find(x => x._id == elem)["selected"] = true;
        })

        proximityModuleApi.post('/gathering/SaveGatheringDetails', allPeople)
            .then((result) => {
                if (result.status === 200) {
                    setGlobalAlert({ message: "Your event was saved", icon: "success" })
                    props.updatePrint(true)
                }
            }).catch((err) => {
                console.log(err);
            });
    }

    const saveAndNotifyGathering = async () => {
        var filter = formRef.current.getData();
        if (totalSelected > props.event.limit) {
            setGlobalAlert({ message: "You've exceeded your capacity", icon: null })
            return 0;
        }
        allPeople = allPeople.map((element) => {
            element.selected = false
            return element
        })

        Object.keys(filter).forEach((elem, index) => {
            allPeople.find(x => x._id == elem)["attended"] = parseInt(filter[elem])
            allPeople.find(x => x._id == elem)["selected"] = true;
        })

        await allPeople.filter(x => x.selected === true).forEach(async (element) => {
            var objectToSend = {};
            objectToSend.idPeopleRegister = element._id;
            objectToSend.name = props.event.name;
            objectToSend.location = props.event.location ? props.event.location.name : "---";
            objectToSend.idEvent = props.event._id;
            objectToSend.time = props.event.time;
            objectToSend.limit = props.event.limit;
            objectToSend.date = props.event.date;
            objectToSend.email = element.email;
            objectToSend.code = props.event.code;
            objectToSend.phoneNumber = element.phoneNumber.replace(/[\(\) .']+/g, '');
            console.log(objectToSend)
            await utilsModuleApi.post("/sms/SendSMS", objectToSend)
            //await utilsModuleApi.post("email/SendConfirmGatheringEmail", objectToSend)

        })
        setGlobalAlert({ message: "Your event was saved and notifications was sent", icon: "success" })

    }

    const cleanSelected = () => {

        var total = 0;
        var registred = peopleRegistred;
        var selected = peopleSelected;
        while (selected.length > 0) {
            let personSelected = selected[0];
            registred.push(allPeople.filter(x => x._id === personSelected._id)[0])
            total -= personSelected.peopleNumber;
            selected = selected.filter(x => x._id != personSelected._id)

        }

        var map = registred.map(({ _id, name, peopleNumber }) => {
            return { _id, name, peopleNumber }
        })

        setpeopleRegistred(map)
        setPeopleSelected(selected)
    }

    const lessPeople = (personId) => {
        var newValue = attended[personId]--
        setAttended({ ...attended, personId: newValue })
    }
    const morePeople = (personId) => {
        var newValue = attended[personId]++
        setAttended({ ...attended })
    }

    const setLimit = (personId, limit) => {

        attended[personId] = limit + 1;
        setAttended({ ...attended })
    }

    useEffect(() => {
        console.log(attended)
        props.totalAttendees(1)
    }, [attended])

    return (
        <>
            <div className="management-section">
                <div className="management-registred">
                    <Grid style={{ boxShadow: "0px 3px 5px rgb(0 0 0 / 10%), 0px 3px 5px rgb(0 0 0 / 10%)" }} >
                        <RowHeader>
                            {
                                registerHeader && registerHeader.map((element, index) => {
                                    return <ColHeader display={element.label == "id" ? "none" : ""} key={Math.random()} size={4}>{element.label}</ColHeader>
                                })

                            }
                            <ColHeader size={1}></ColHeader>
                        </RowHeader>

                        <Tbody style={{ overflowY: "scroll", maxHeight: "50vh", textTransform: "initial" }} >
                            {
                                peopleRegistred && peopleRegistred.map((element, index) => {
                                    return (
                                        <Row>
                                            {
                                                Object.keys(element).map(key => <Col className="force-capitalize" display={key == "_id" ? "none" : ""} key={Math.random()} size={4}>
                                                    {key == "peopleNumber" ? (element[key] + 1).toString() : element[key].toString()}
                                                </Col>)
                                            }
                                            <Col size={1}>
                                                <div onClick={() => selectPerson(element["_id"])} className="gathering-management-grid-icon-conatiner rigth">
                                                    <Icon icon={bxrightarrowalt} style={{ fontSize: "20px" }} />
                                                </div>

                                            </Col>
                                        </Row>
                                    );
                                })
                            }
                        </Tbody>


                    </Grid>
                </div>
                <div className="management-selected">
                    <Form ref={formRef} onSubmit={saveGathering}>
                        <Grid style={{ boxShadow: "0px 3px 5px rgb(0 0 0 / 10%), 0px 3px 5px rgb(0 0 0 / 10%)" }} >
                            <RowHeader>
                                {
                                    selectedHeader && selectedHeader.map((element, index) => {
                                        let objectReturned = null;
                                        if (element.label == "attended") {
                                            objectReturned = <ColHeader className="hidden-xs" key={Math.random()} size={1.5}>{element.label}</ColHeader>
                                        }
                                        else if (element.label == "notified" || element.label == "confirmed") {
                                            objectReturned = <ColHeader className="hidden-xs" display={element.label == "id" ? "none" : ""} key={Math.random()} size={1}>{element.label}</ColHeader>
                                        }
                                        else {
                                            objectReturned = <ColHeader display={element.label == "id" ? "none" : ""} key={Math.random()} size={1}>{element.label}</ColHeader>
                                        }

                                        return objectReturned
                                    })


                                }
                                <ColHeader size={0.5}>
                                    <div onClick={() => { cleanSelected() }} className="hidden-xs gathering-management-grid-icon-conatiner primary-color">
                                        <Icon icon={trashSharp} style={{ fontSize: "20px" }} />
                                    </div>
                                </ColHeader>
                            </RowHeader>
                            {
                                props.event && props.event.limit == totalSelected &&
                                <div className="management-status-bar red">
                                    <div> People selected: {totalSelected} -   You've reached the people limit</div>
                                </div>
                            }
                            {
                                props.event && props.event.limit < totalSelected &&
                                <div className="management-status-bar red">
                                    <div> People selected: {totalSelected} -   <span>You've exceeded the people limit</span></div>
                                </div>
                            }

                            <Tbody style={{ overflowY: "scroll", maxHeight: "40vh", textTransform: "initial" }} >
                                {
                                    peopleSelected && peopleSelected.map((element, index) => {
                                        return (
                                            <Row >
                                                <Col size={1}>
                                                    <div onClick={() => removeSelectPerson(element["_id"])} className="gathering-management-grid-icon-conatiner left">
                                                        <Icon icon={bxleftarrowalt} style={{ fontSize: "20px" }} />
                                                    </div>
                                                </Col>
                                                {
                                                    Object.keys(element).map(key => {
                                                        var objectReturned = null;
                                                        if (key == "notified" || key == "confirmed") {

                                                            objectReturned = <Col className="hidden-xs" key={Math.random()} size={1}>
                                                                <input type="checkbox" onChange={() => {
                                                                    key == "notified" ? setNotified(element["_id"]) : setConfirmed(element["_id"])
                                                                }} checked={element[key]} name="isCellPhone" className="checkbox people-selected-checkbox" />
                                                            </Col>
                                                        }
                                                        else if (key == "attended") {
                                                            objectReturned = <Col className="hidden-xs" ref={(ele) => refs.current[index] = ele} key={Math.random()} display={key == "_id" ? "none" : ""} size={1.5}>
                                                                <div className="attended-counter-form-row">
                                                                    <div className="attended-counter-form-group">
                                                                        <button onClick={() => lessPeople(element["_id"])} className="btn-attended-counter" type="button"> - </button>
                                                                    </div>
                                                                    <div className="attended-counter-form-group">
                                                                        <Input defaultValue={attended[element["_id"]]} style={{ padding: 0 }} isHidden="true" name={element["_id"]} type="hidden" value={attended[element["_id"]]} />
                                                                        <div className="people-attended-display">{attended[element["_id"]]}</div>
                                                                    </div>
                                                                    <div className="attended-counter-form-group">
                                                                        <button onClick={() => morePeople(element["_id"])} className="btn-attended-counter" type="button"> + </button>
                                                                    </div>
                                                                    <div style={{ marginTop: "10px" }} onClick={() => setLimit(element["_id"], element["peopleNumber"])} className="gathering-management-grid-icon-conatiner center">
                                                                        <Icon icon={accountMultipleCheck} style={{ fontSize: "20px" }} />
                                                                    </div>
                                                                </div>

                                                                {/* <AttendedCounter countAttendees={countAttendees} limit={element["peopleNumber"]} value={element["attended"]} peopleId={element["_id"]} /> */}
                                                            </Col>
                                                        }
                                                        else if (key == "contact") {
                                                            objectReturned = <Col key={Math.random()} display={key == "_id" ? "none" : ""} size={1}>
                                                                <ContactPopover contactInfo={element["contact"]} />
                                                            </Col>
                                                        }
                                                        else {
                                                            objectReturned = <Col className="force-capitalize" key={Math.random()} display={key == "_id" ? "none" : ""} size={1}>{key == "peopleNumber" ? (element[key] + 1).toString() : element[key].toString()}</Col>
                                                        }
                                                        return objectReturned
                                                    })
                                                }
                                                <Col className="hidden-xs" size={0.5} />
                                            </Row>
                                        );
                                    })
                                }
                            </Tbody>
                        </Grid>
                        <div className="select-people-btn-save-container">
                            <button type="button" onClick={() => { saveAndNotifyGathering() }} className="management-btn  management-btn-secondary">Save and notify</button>
                            <button type="submit" className="management-btn  management-btn-primary">Save</button>
                        </div>
                    </Form>
                </div>
            </div>
            {
                alertConfirmation && <ConfirmationAlert
                    idToRemove={alertConfirmation.idPerson}
                    doAction={(idPerson) => { addSelectPerson(idPerson) }}
                    closeAlert={() => { setAlertConfirmation(undefined) }}
                    message={alertConfirmation.message} />
            }


        </>
    )
})


export default memo(SelectPeople)