import React, { useState, useRef, useEffect } from 'react'
import History from "../../routes/history"
import { loginApi } from "../../services/api";
import { Alert, Button, Form, Input } from 'antd';
import "./login.scss"

export default function RecoveryPassword({ token }) {
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(undefined);

    var handleRecovery = (data) => {
        setIsLoading(true);
        data['token'] = token;

        loginApi.post('/login/SetNewPassword', data)
            .then(response => {
                History.push('/');
            })
            .catch(error => {
                setAlert({message: 'Something went wrong!', type: 'error'});
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <div className="login-container">
            <div className="apex-info">
                <img src="/images/loginLogo.png"></img>
            </div>
            {alert && (<Alert className="mb-2" message={alert.message} type={alert.type} showIcon />)}
            <Form onFinish={handleRecovery}>
                <Form.Item name="password" rules={[{ required: true }]}>
                    <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item
                    className="show-error"
                    name="confirmPassword"
                    rules={[
                        { required: true, message: '' },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (value && getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="Password Confirm" />
                </Form.Item>
                <Form.Item>
                    <Button className="d-block w-100" type="primary" htmlType="submit" loading={isLoading}>Save</Button>
                </Form.Item>
            </Form>
        </div>
    )
}
