import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import "./gatheringConfirm.scss"
import { Icon, InlineIcon } from '@iconify/react'
import checkSolid from '@iconify/icons-fa-solid/check';
import calendar4Event from '@iconify/icons-bi/calendar4-event';
import moment from "moment";
const axios = require('axios');
var atob = require('atob');
var alertMessage = "";

export default function GatheringRegister() {
    const [gatheringEvent, setGatheringEvent] = useState(null)
    var { token } = useParams()

    useEffect(async () => {
        if (token) {
            var decodedToken = JSON.parse(atob(token))
            console.log(decodedToken)
            console.log(token)
            var proximityModuleApi = await axios.create({
                baseURL: 'http://proximity.tascsystems.com/',
                timeout: 3000,
                headers: {
                    "Authorization": "Bearer " + decodedToken.token
                }
            });

            var result = await proximityModuleApi.post('/gathering/ConfirmationPresenceByEmail', {
                idPeopleRegister: decodedToken.idPeopleRegister
            });
            console.log(result)
            if (result) {
                setGatheringEvent(result.data)
            }
        }
    }, [])



    return (
        <>
            <div className="gathering-confirm-general-background">
                <div className="confirmation-container">
                    COMPANY LOGO
                    {/* <img src="/images/logo-test.png" />     */}
                    <p className="font-400">You are confirmed for the event:</p>
                    <Icon icon={calendar4Event} />
                    <h3>{gatheringEvent && moment(gatheringEvent.date).format("dddd, MMMM DD, YYYY")} @ {gatheringEvent && gatheringEvent.time}</h3>
                    <p>{gatheringEvent && gatheringEvent.name} for {gatheringEvent && gatheringEvent.limit} people</p>
                </div>
                <div className="gathering-confirm-footer">
                    <p>powered by</p>
                    <div>
                        <img src="/images/apex-logo-confirmation.png" />
                    </div>

                    <p>Apex® | 2021 Apex is a trademark of Tasc Systems</p>
                </div>
            </div>



            {/* {
                isLoading && (
                    <div className="loading-container">
                        
                            <img src="/images/Apex_Loading.gif" />
                        
                        
                    </div>
                )
            }
 */}


        </>
    )
}
