const INITIAL_STATE = {
    primaryColor: "#0F5070",
    secondaryColor: "#ffff00",
    isVisible: true,
    isHeaderVisible: true
}

export default function appearance(state = INITIAL_STATE, action) {
    if (action.type === "CHANGE_DEFAULT_COLORS")
        return { ...state, primaryColor: action.primaryColor, secondaryColor: action.secondaryColor }

    if (action.type === "CHANGE_COLOR_BG")
        return {...state, colorAsBg: action.colorAsBg}

    if (action.type === "CHANGE_BG_COLOR")
        return {...state, backgroundColor: action.BgColor}

    if (action.type === "SET_BACKGROUND")
        return { ...state, image: action.image }

    if (action.type === "TOGGLE_MENU"){
        return { ...state, isVisible: action.isVisible }
    }
    if (action.type === "TOOGLE_HEADER"){
        return { ...state, isHeaderVisible: action.isHeaderVisible }
    }
    

    

   
    return state
}

