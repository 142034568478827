import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import Layout from '../../components/layout/layout';
import { Systems as List } from './systems/systems';
import System from './systems/system';
import Site from './systems/site/site';

const Systems = () => {
    const { path, url } = useRouteMatch();
    const menu = [
        { name: "Systems", link: `${path}` }
    ];
    
    return (
        <Layout module="systems module" menu={menu} breadcrumbs={true}>
            <Switch>
                <Route path={`${path}`} exact component={List} />
                <Route path={`${path}/System/:id`} exact component={System} />
                <Route path={`${path}/System/Site/:id`} exact component={Site} />
            </Switch>
        </Layout>
    )
};

export default {
    name: 'Systems',
    private: true,
    routeProps: {
        path: '/Systems',
        component: Systems
    }
}