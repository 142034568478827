import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { ChromePicker } from 'react-color';
import { Tabs, Button, Table, Space, Popconfirm, message, Drawer, Form, Input, Row, Col, Card } from 'antd';
import Loading from '../../../components/loading/loading';
import { GetExpensesCategories, GetTasksCategories, GetPlaces } from "../../../services/services";
import { CategoryType, OperationType } from '../../../utils/enums'
import { configurationModuleApi } from "../../../services/api";

import "./configuration.scss"

const { TabPane } = Tabs;

const Configuration = ({ store, dispatch }) => {
  const [form] = Form.useForm();
  const [categoryType, setCategoryType] = useState(CategoryType.EXPENSE);
  const [operationType, setOperationType] = useState(false);
  const [expenseCategories, setExpenseCategory] = useState([]);
  const [taskCategories, setTaskCategory] = useState([]);
  const [places, setPlaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [color, setColor] = useState('#000000');
  const [showDrawer, setShowDrawer] = useState(false);

  const expenseColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: '',
      key: 'action',
      render: (text, item) => (
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <Popconfirm title="Do you really want remove this item?" okText="Yes" cancelText="No" onConfirm={() => handleRemoveItem(item._id)}>
            <Button type="secondary">Remove</Button>
          </Popconfirm>
          <Button type="primary" onClick={() => handleOpenOperation(OperationType.EDIT, item)}>Edit</Button>
        </Space>
      ),
    },
  ];

  const taskColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      render: (text, item) => (
        <div className="configuration-task-color">
          {item.color}
          <div className="configuration-color-set" style={{ background: item.color }}></div>
        </div>
      )
    },
    {
      title: '',
      key: 'action',
      render: (text, item) => (
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <Popconfirm title="Do you really want remove this item?" okText="Yes" cancelText="No" onConfirm={() => handleRemoveItem(item._id)}>
            <Button type="secondary">Remove</Button>
          </Popconfirm>
          <Button type="primary" onClick={() => handleOpenOperation(OperationType.EDIT, item)}>Edit</Button>
        </Space>
      )
    },
  ];

  const placeColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (text, item) => (
        <span>{item.address.street + " - " + item.address.city + " - " + item.address.province}</span>
      )
    },
    {
      title: '',
      key: 'action',
      render: (text, item) => (
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <Popconfirm title="Do you really want remove this item?" okText="Yes" cancelText="No" onConfirm={() => handleRemoveItem(item._id)}>
            <Button type="secondary">Remove</Button>
          </Popconfirm>
          <Button type="primary" onClick={() => handleOpenOperation(OperationType.EDIT, item)}>Edit</Button>
        </Space>
      )
    },
  ];

  const handleOpenOperation = (op, item) => {
    setOperationType(op)
    if (op == OperationType.EDIT) {
      switch (categoryType) {
        case CategoryType.EXPENSE:
          form.setFieldsValue({
            _id: item._id,
            name: item.name,
            code: item.code
          });
          break;
        case CategoryType.TASK:
          setColor(item.color);
          form.setFieldsValue({
            _id: item._id,
            name: item.name,
            color: item.color
          });
          break;
        case CategoryType.GATHER:
          form.setFieldsValue({
            _id: item._id,
            name: item.name,
            country: item.address.country,
            city: item.address.city,
            province: item.address.province,
            street: item.address.street,
            unit: item.address.unit,
            postalCode: item.address.postalCode
          });
          break;
      }
    }
    setShowDrawer(true);
  }

  const handleDrawerOpen = () => {
    setShowDrawer(true);
  }

  const handleDrawerClose = async () => {
    setShowDrawer(false);
    form.resetFields();
  }


  const handleRemoveItem = (id) => {
    setIsLoading(true)
    switch (categoryType) {
      case CategoryType.EXPENSE:
        configurationModuleApi.delete('/configuration/DeleteExpenseCategory?id=' + id)
          .then(async (res) => {
            setExpenseCategory(await GetExpensesCategories());
            message.success('Expense category removed successfully');
          })
          .catch(() => {
            message.error('Something went wrong!');
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case CategoryType.TASK:
        configurationModuleApi.delete('/configuration/DeleteTaskCategory?id=' + id)
          .then(async (res) => {
            setTaskCategory(await GetTasksCategories());
            message.success('Task category removed successfully');
          })
          .catch(() => {
            message.error('Something went wrong!');
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case CategoryType.GATHER:
        configurationModuleApi.delete('/configuration/DeletePlace?id=' + id)
          .then(async (res) => {
            setPlaces(await GetPlaces());
            message.success('Place removed successfully');
          })
          .catch(() => {
            message.error('Something went wrong!');
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
    }
  }

  const handleSave = async (data) => {
    setIsLoading(true)
    if (operationType === OperationType.ADD)
      switch (categoryType) {
        case CategoryType.EXPENSE:
          configurationModuleApi.post('/configuration/PostExpenseCategory', data)
            .then(async (res) => {
              setExpenseCategory(await GetExpensesCategories());
              handleDrawerClose()
              message.success('Expense category saved successfully');
            })
            .catch(() => {
              message.error('Something went wrong!');
            })
            .finally(() => {
              setIsLoading(false);
            });
          break;
        case CategoryType.TASK:
          data.color = data.color.hex;
          configurationModuleApi.post('/configuration/PostTaskCategory', data)
            .then(async (res) => {
              setTaskCategory(await GetTasksCategories());
              handleDrawerClose()
              message.success('Task category saved successfully');
            })
            .catch(() => {
              message.error('Something went wrong!');
            })
            .finally(() => {
              setIsLoading(false);
            });
          break;
        case CategoryType.GATHER:
          configurationModuleApi.post('/configuration/PostPlace', data)
            .then(async (res) => {
              setPlaces(await GetPlaces());
              handleDrawerClose()
              message.success('Place saved successfully');
            })
            .catch(() => {
              message.error('Something went wrong!');
            })
            .finally(() => {
              setIsLoading(false);
            });
          break;
      }
    else {
      switch (categoryType) {
        case CategoryType.EXPENSE:
          configurationModuleApi.put('/configuration/EditExpenseCategory?id=' + data._id, data)
            .then(async (res) => {
              setExpenseCategory(await GetExpensesCategories());
              handleDrawerClose()
              message.success('Expense category saved successfully');
            })
            .catch(() => {
              message.error('Something went wrong!');
            })
            .finally(() => {
              setIsLoading(false);
            });
          break;
        case CategoryType.TASK:
          data.color = data.color.hex;
          configurationModuleApi.put('/configuration/EditTaskCategory?id=' + data._id, data)
            .then(async (res) => {
              setTaskCategory(await GetTasksCategories());
              handleDrawerClose()
              message.success('Task category saved successfully');
            })
            .catch(() => {
              message.error('Something went wrong!');
            })
            .finally(() => {
              setIsLoading(false);
            });
          break;
        case CategoryType.GATHER:
          let place = {
            _id: data._id,
            name: data.name,
            address: {
              country: data.country,
              city: data.city,
              province: data.province,
              street: data.street,
              unit: data.unit,
              postalCode: data.postalCode
            }
          }
          configurationModuleApi.put('/configuration/EditPlace?id=' + place._id, place)
            .then(async (res) => {
              setPlaces(await GetPlaces());
              handleDrawerClose()
              message.success('Place saved successfully');
            })
            .catch(() => {
              message.error('Something went wrong!');
            })
            .finally(() => {
              setIsLoading(false);
            });
          break;
      }
    }
  }

  useEffect(async () => {
    setExpenseCategory(await GetExpensesCategories());
    setTaskCategory(await GetTasksCategories());
    setPlaces(await GetPlaces());
    setIsLoading(false)
  }, [])

  return (
    <>
      <Tabs defaultActiveKey={CategoryType.EXPENSE} onTabClick={(key) => setCategoryType(key)}>
        <TabPane tab="Expenses Categories" key={CategoryType.EXPENSE}>
          <div className="text-right mb-1">
            <Button type="secondary" onClick={() => handleOpenOperation(OperationType.ADD)}>Add Category</Button>
          </div>
          <Table rowKey="_id" bordered={true} columns={expenseColumns} dataSource={expenseCategories} pagination={false} loading={isLoading} />
        </TabPane>
        <TabPane tab="Taks Categories" key={CategoryType.TASK}>
          <div className="text-right mb-1">
            <Button type="secondary" onClick={() => handleOpenOperation(OperationType.ADD)}>Add Category</Button>
          </div>
          <Table rowKey="_id" bordered={true} columns={taskColumns} dataSource={taskCategories} pagination={false} loading={isLoading} />
        </TabPane>
        <TabPane tab="Gathering Places" key={CategoryType.GATHER}>
          <div className="text-right mb-1">
            <Button type="secondary" onClick={() => handleOpenOperation(OperationType.ADD)}>Add Category</Button>
          </div>
          <Table rowKey="_id" bordered={true} columns={placeColumns} dataSource={places} pagination={false} loading={isLoading} />
        </TabPane>
      </Tabs>
      <Drawer
        title={operationType + ' ' + categoryType}
        visible={showDrawer}
        mask={false}
        destroyOnClose={true}
        getContainer="#main-container"
        onClose={handleDrawerClose}
      >
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={handleSave}
        >
          <Form.Item name="_id" noStyle>
            <Input type="hidden" />
          </Form.Item>

          {categoryType == CategoryType.EXPENSE && (
            <>
              <Card bordered={false} style={{ width: "600px" }}>
                <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item className="mb-0" label="Code" name="code" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Card>
            </>
          )}

          {categoryType == CategoryType.TASK && (
            <>
              <Card bordered={false} style={{ width: "600px", height: "500px" }}>
                <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item className="mb-0" label="Color" name="color" rules={[{ required: true }]}>
                  <ChromePicker disableAlpha={true} color={color} onChange={(data) => setColor(data.hex)} />
                </Form.Item>
              </Card>
            </>
          )}

          {categoryType == CategoryType.GATHER && (
            <>
              <Card bordered={false} style={{ width: "600px", height: "650px" }}>
                <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Country" name="country" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="City" name="city" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Province" name="province" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Street" name="street" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Unit" name="unit" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item className="mb-0" label="Postal Code" name="postalCode" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Card>
            </>
          )}
          <Space className="form-actions">
            <Button type="secondary" onClick={handleDrawerClose}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={isLoading} >Save</Button>
          </Space>
        </Form>

      </Drawer>

    </>
  )
}

export default connect(state => ({
  store: state
}))(Configuration);