import React from 'react';
import { useParams } from 'react-router';
import { Tabs } from 'antd';
import SiteInformation from './site-information';
import SiteDocuments from './site-documents';
import SiteActivity from './site-activity';
import SiteDevices from './site-devices';

const { TabPane } = Tabs;

export default function Site() {
    const { id } = useParams();

    return (
        <Tabs defaultActiveKey="information">
            <TabPane tab="Information" key="information">
                <SiteInformation id={id} />
            </TabPane>
            <TabPane tab="Devices" key="devices">
                <SiteDevices id={id} />
            </TabPane>
            <TabPane tab="Documents" key="documents">
                <SiteDocuments id={id} />
            </TabPane>
            <TabPane tab="Activity" key="activity">
                <SiteActivity id={id} />
            </TabPane>
        </Tabs>
    )
}