import React, { useEffect, useRef } from 'react'
import { useField } from "@unform/core";

import "./textarea.css"

export default function Textarea({ name, label, rows, ...rest }) {
    const inputRef = useRef(null);
    const { fieldName, registerField, defaultValue, error } = useField(name);
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: "value"
        })
    }, [fieldName, registerField])
    return (
        <div className="textarea-container">
            <label>{label}</label>
            <textarea defaultValue={defaultValue} rows={rows} ref={inputRef} {...rest} />
        </div>

    )
}
