import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import moment from "moment";

import { GetUsers, GetLoggedUser } from "../../../services/services";
import { configurationModuleApi } from '../../../services/api';
import { Avatar, Button, Drawer, message, Popconfirm, Space, Table, Tag } from 'antd';
import UserDetail from './userDetail/userDetail';

const Users = (store) => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, SetUsers] = useState([]);
  const [userList, SetUserList] = useState([]);
  const [user, SetLoggedUser] = useState({});
  const [selected, setSelected] = useState({});
  const [showDrawer, setShowDrawer] = useState(false);
  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (text, item) => (
        <Space align="center" className="ant-space-large">
          <Avatar size="small" src={item.picture}/>
          <span>{item.name}</span>
        </Space>
      )
    },
    {
      title: 'Login',
      dataIndex: 'login',
      key: 'login'
    },
    {
      title: 'Profile',
      dataIndex: 'profile',
      key: 'profile'
    },
    {
      title: 'Last Login',
      dataIndex: 'lastLogin',
      key: 'lastLogin'
    },
    {
      title: '',
      key: 'action',
      render: (text, item) => (
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          { user._id === item._id && (
            <Tag className="medium">It's you</Tag>
          )}
          <Popconfirm title="Do you really want remove this user?" okText="Yes" cancelText="No" onConfirm={() => handleRemoveItem(item._id)}>
            <Button type="secondary" disabled={user._id === item._id}>Remove</Button>
          </Popconfirm>
          <Button type="primary" onClick={() => handleDrawerOpen(item)}>Edit</Button>
        </Space>
      ),
    },
  ];

  const handleRemoveItem = (id) => {
    setIsLoading(true)
    configurationModuleApi.delete('/account/DeleteUser?id=' + id)
      .then(async (res) => {
        SetUsers(await GetUsers());
        message.success("User removed successfully")
      })
      .catch(() => {
        message.success("Something went wrong!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleDrawerOpen = (item) => {
      setShowDrawer(true);
      setSelected(item);
  }

  const handleDrawerClose = async () => {
    setShowDrawer(false);
    setIsLoading(true);
    SetUsers(await GetUsers());
    setSelected({});
  }

  useEffect(async () => {
    let list = []
    users.forEach(user => {
      let u = {
        _id: user._id,
        name: user.name,
        picture: user.picture,
        login: user.login,
        profile: user.idProfile ? user.idProfile.name : "-",
        idProfile: user.idProfile ? user.idProfile._id : user.idProfile,
        lastLogin: user.lastLogin === undefined ? "-" : moment(user.lastLogin).format("dddd, MMMM DD, YYYY HH:MM:ss")
      }
      list.push(u)
    });

    SetUserList(list);
    setIsLoading(false);
  }, [users])

  useEffect(async () => {
    setIsLoading(true);
    SetUsers(await GetUsers());
    SetLoggedUser(await GetLoggedUser());
  }, [])

  return (
    <>
      <div className="text-right mb-1">
        <Button onClick={() => handleDrawerOpen({})}>Add User</Button>
      </div>
      <Table className="table-2" rowKey="_id" columns={columns} dataSource={userList} pagination={false} loading={isLoading} />
      <Drawer
          visible={showDrawer}
          title="User Manage"
          mask={false}
          getContainer="#main-container"
          destroyOnClose={true}
          onClose={handleDrawerClose}
      >
          <UserDetail model={selected} isCurrent={selected._id === user._id} onClose={handleDrawerClose} />
      </Drawer>
    </>
  )
}

export default connect(state => ({
  store: state
}))(Users);