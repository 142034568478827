import React, { useEffect, useState } from 'react';
import { networkModuleApi } from '../../../services/api';
import { Button, Drawer, Space, Table } from 'antd';
import HealthCheckDetail from './healthCheckDetail/healthCheckDetail';
import Loading from '../../../components/loading/loading';

export default function HealthChecks() {
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [selected, setSelected] = useState({});
    const [showDrawer, setShowDrawer] = useState(false);

    useEffect(async () => {
        setIsLoading(true);
        getHealthChecks();
        setIsLoading(false);
    }, []);

    const getHealthChecks = async () => {
        const result = await networkModuleApi.get('/HealthCheck/All');
        setList(result.data.content);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Method',
            dataIndex: 'method',
            key: 'method'
        },
        {
            title: 'Port',
            dataIndex: 'port',
            key: 'port'
        },
        {
            title: 'Max Faliures',
            key: 'maxFailures',
            dataIndex: 'maxFailures'
        },
        {
            title: 'Retry interval',
            dataIndex: 'retryInterval',
            key: 'retryInterval'
        },
        {
            title: 'Check interval',
            dataIndex: 'checkInterval',
            key: 'checkInterval'
        },
        {
            title: '',
            key: 'action',
            render: (text, item) => (
                <Space style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button type="primary" onClick={() => handleEdit(item)}>Edit</Button>
                    <Button type="secondary" onClick={() => handleRemove(item)}>Remove</Button>
                </Space>
            ),
        },
    ];

    const handleEdit = (item) => {
        setShowDrawer(true);
        setSelected(item);
    }

    const handleRemove = (item) => {
        networkModuleApi.delete(`/HealthCheck?id=${item._id}`)
            .then(() => {
                setList(list.filter(e => e._id !== item._id));
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleDrawerClose = () => {
        getHealthChecks();
        setSelected({});
        setShowDrawer(false);
    }

    return (
        <>
            <div className="text-right mb-1">
                <Button onClick={() => setShowDrawer(true)}>Add health check</Button>
            </div>
            <Table rowKey="_id" columns={columns} dataSource={list} pagination={false} />
            <Drawer
                visible={showDrawer}
                title="Health Check Manage"
                mask={false}
                getContainer="#main-container"
                destroyOnClose={true}
                onClose={handleDrawerClose}
            >
                <HealthCheckDetail model={selected} onClose={handleDrawerClose} />
            </Drawer>
            { isLoading && (<Loading />)}
        </>
    );
}