export const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export const getFile = (e) => {
    if (Array.isArray(e)) {
        return e[0];
    }
    return e && e.file;
};

export const getFileList = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
};