import React, { useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import Layout from '../../components/layout/layout';
import SettingsPage from './settings/settings';
import Configuration from './configuration/configuration';
import Appearance from './appearance/appearance';
import Account from './account/account';
import ClientsSubcompanies from './clientsSubcompanies/clientsSubcompanies';
import Users from './users/users';
import Profiles from './profiles/profiles';

const Settings = () => {
    const { path, url } = useRouteMatch();
    const [title, setTitle] = useState("Settings");
    const menu = [
        { name: "Settings", link: `${path}` },
        { name: "Configuration", link: `${path}/Configuration` },
        { name: "Appearance", link: `${path}/Appearance` },
        { name: "Account", link: `${path}/Account` },
        { name: "Clients/Subcompanies", link: `${path}/ClientsSubcompanies` },
        { name: "Users", link: `${path}/Users` },
        { name: "Profiles", link: `${path}/Profiles` }
    ];

    return (
        <Layout module="Settings and Account Configurations module" menu={menu} title={title} onChangeActive={(item) => { setTitle(item) }}>
            <Switch>
                <Route path={`${path}`} exact component={SettingsPage} />
                <Route path={`${path}/Configuration`} component={Configuration} />
                <Route path={`${path}/Appearance`} component={Appearance} />
                <Route path={`${path}/Account`} component={Account} />
                <Route path={`${path}/ClientsSubcompanies`} component={ClientsSubcompanies} />
                <Route path={`${path}/Users`} component={Users} />
                <Route path={`${path}/Profiles`} component={Profiles} />
            </Switch>
        </Layout>
    )
};

export default {
    name: 'Settings',
    private: true,
    routeProps: {
        path: '/Settings',
        component: Settings
    }
};