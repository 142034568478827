export const ModuleType = {
    SYSTEMS: "Systems", 
    REPORTS: "Reports", 
    VIEWS: "Views", 
    EXPENSES: "Expenses Management",
    PROXIMITY: "Proximity Management",
    TASK: "Tasks Management",
    SETTINGS: "Settings and Account Configurations",
    EMERGENCY: "Emergency Management",
    NETWORK: "Network Management",
    LOGO: ""
}

export const LauncherWidget = {
    SMALL: 1, 
    LARGE: 2
}

export const API_PORTS = {
    EXPENSES_MODULE : 8080,
    TASKS_MODULE : 8081,
    SYSTEMS_MODULE : 8082,
    PROXIMITY_MODULE : 8083,
    DOCUMENTS_MODULE : 8084,
    LOGIN_MODULE : 8086,
    CONFIGURATION_MODULE : 8085,
    NETWORK_MODULE : 8088
}

export const AlertStatus = {
    SAFE: -Infinity, 
    MINOR: 1, 
    MAJOR: 2,
    CRITICAL: 3
}

export const CategoryType = {
    EXPENSE: "Expense Category", 
    TASK: "Task Category",
    GATHER: "Gathering Place",
}

export const CounterOperation = {
    MINUS: "minus", 
    PLUS: "plus"
}

export const ExpensesStatus = {
    UNREPORTED: "Unreported", 
    DENIED: "Denied", 
    SUBMITTED: "Submitted", 
    APPROVED: "Approved",
    OPEN: "Open",
    REIMBURSABLE: "reimbursable",
    NOT_REIMBURSED: "Not reimbursed",
    REIMBURSED: "Reimbursed",
    PAID: "Paid",
    NOT_PAID: "Not Paid",
    ARCHIVED: "Archived"
}

export const OperationType = {
    ADD: "Add", 
    EDIT: "Edit",
    DETAIL: "Detail",
    REMOVE: "Remove",
}

export const SettingsDefaultCurrency = [
    { 
        value: 'CAD', 
        label: 'C$'
    }, 
    { 
        value: 'USD', 
        label: 'US$'
    }
]

export const SettingsDefaultMileage = [
    { 
        value: 'KM', 
        label: 'Km' 
    },
    { 
        value: "MI", 
        label: 'Mi' 
    }
]

export const SettingsDefaultTax = [
    { 
        value: 'GST_HST', 
        label: 'GST/HST' 
    },
    { 
        value: "TAX", 
        label: 'Tax' 
    }
]

export const TasksStatus = {
    TODO: "backlog", 
    DOING: "doing",
    DELAYED: "delayed",
}