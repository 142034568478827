import React, { useEffect, useState, useRef } from 'react';
import { proximityModuleApi } from "../../../services/api";
import WhiteHeaderGrid from "../../../components/grids/whiteHeaderGrid/whiteHeaderGrid"
import { MessageBoxContainer } from '../../../components/proximityManagement/proximityBoxStyle/proximityBoxStyle';
import { FormContactCommunication } from "../../../components/form/formContainer/formContactCommunication";
import CustomersInsideBox from "../../../components/customersInsideBox/customersInsideBox";
import { Form } from "@unform/web";
import TextArea from "../../../components/form/textarea/textareaRaw";
import DatePicker from "../../../components/form/datepicker/datepickerRaw";
import Slides from "../../../components/form/slides/slides";
import Alert from "../../../components/alert/alert";
import ReactExport from "react-export-excel";
import { Row, Col } from 'antd';
import * as Yup from "yup";
import "./contactCommunication.scss";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default function ContactCommunication() {

  const [customers, setCustomers] = useState(0);
  const [showCostumersContent, setShowCostumersContent] = useState(false);
  const [peopleShareData, setPeopleShareData] = useState(0);
  const [peopleInLine, setPeopleInLine] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const formRef = useRef(null);
  const formGetCostumersRef = useRef(null);

  const sendCommunication = (formData) => {
    try {
      const { message, email, sms } = formData;
      const { from, to } = formGetCostumersRef.current.getData()

      if (email != 'false' || sms != 'false') {
        proximityModuleApi.post('/Proximity/SendMessage', {
          from,
          to,
          message,
          email,
          sms

        }).then(function (response) {
          if (response.status == 200) {
            setShowConfirmation(true)
          }
        }).catch(function (error) {
          setShowConfirmation(false)
          console.log(error.response);
        });

      }
      else {
        setSendMessage(true)
      }

    } catch (err) {
      console.log(err)
    }


  };

  const getCostumers = async (data) => {

    try {
      const { from, to } = data

      const schema = Yup.object().shape({
        from: Yup.date().typeError("From is required"),
        to: Yup.date().typeError("To is required"),
      })

      await schema.validate(data, { abortEarly: false });
      formGetCostumersRef.current.setErrors({});

      setIsLoading(true)
      var customerListFromDb = await proximityModuleApi.get("/Proximity/GetCostumersByServiceDate", {
        params: {
          from,
          to
        }
      });

      if (customerListFromDb.data && customerListFromDb.data.content.length > 0) {
        setCustomers(customerListFromDb.data.content.map(({ name, phone, email, shareData }) => {
          if (!shareData) {
            return {
              name,
              phone: "---",
              email: "---"
            }
          }
          return {
            name,
            phone,
            email
          }

        }))
        setShowCostumersContent(true)
        var totalPeople = customerListFromDb.data.content.reduce((accumulator, sum) => {
          return accumulator + parseInt(sum.totalPeople)
        }, 0)
        //setPeopleInLine(totalPeople)
        setPeopleInLine(customerListFromDb.data.content.length)
        setPeopleShareData(customerListFromDb.data.content.filter(x => x.shareData).length)
        setIsLoading(false)
      }





    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formGetCostumersRef.current.setErrors(validationErrors);
      }
    }


    try {




    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }

  }

  return (
    <>
      <div className="contact-container">
        <div className="box-filter-form">
          <p style={{ fontSize: "12px" }}>Probable date rage of contact</p>
          <Form ref={formGetCostumersRef} onSubmit={getCostumers}>
            <Row gutter={[12, 12]}>
              <Col xs={24} md={10} >
                <DatePicker
                  name="from"
                  label="FROM"
                  className="input-style"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={10}
                  timeCaption="time"
                  dateFormat="Pp"
                />
              </Col>
              <Col xs={24} md={10}>
                <DatePicker
                  name="to"
                  label="TO"
                  className="input-style"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={10}
                  timeCaption="time"
                  dateFormat="Pp"
                />
              </Col>
              <Col xs={24} md={4} >
                <button style={{ float: "right", marginTop: "20px" }} className="submit " type="submit">
                  Filter
                    </button>
              </Col>
            </Row>
          </Form>
        </div>

        {
          showCostumersContent && (
            <>

              <div className="box-content">
                <Row gutter={[0, 16]}>
                  <Col xs={24} md={14}>
                    <div className="message-box-container" >
                      <Form ref={formRef} onSubmit={sendCommunication}>
                        <Row gutter={[12, 16]}>
                          <Col xs={24}>
                            <TextArea
                              style={{ height: "200px", resize: "none" }}
                              name="message"
                              label="Message"
                              rows="6"
                              placeholder="Message to let customers know about a possible contact goes here"
                            />
                          </Col>
                        </Row>

                        <Row style={{ marginTop: 20 }} gutter={[12, 16]}>
                          <Col xs={12} md={8}>
                            <Slides
                              name="email"
                              className="checkbox"
                              label="Email"
                            />
                          </Col>
                          <Col xs={12} md={8}>
                            <Slides
                              name="sms"
                              className="checkbox"
                              label="SMS"
                            />
                          </Col>
                          <Col xs={24} md={8}>
                            <button type="submit" className="submit"> Send Communication</button>
                          </Col>
                        </Row>

                      </Form>
                    </div >
                  </Col>
                  <Col xs={24} md={10}>
                    <CustomersInsideBox peopleInLine={peopleInLine} peopleShareData={peopleShareData} />
                  </Col>
                </Row>
              </div>


              {/* create excel file */}
              <Row gutter={[12, 12]}>
                <Col xs={24}>
                  <ExcelFile filename="Customers" element={
                    <button type="button" className="export-data-button" >
                      <img className="export-data-icon" src="/images/export-data-icon.png" ></img> Export customer data </button>
                  }>
                    <ExcelSheet data={customers} name="Customers">
                      <ExcelColumn label="Customer" value="name" widthPx="200" />
                      <ExcelColumn label="Phone" value="phone" widthPx="200" />
                      <ExcelColumn label="Email" value="email" widthPx="200" />
                    </ExcelSheet>
                  </ExcelFile>
                </Col>
              </Row>



              <div className="table-margin" name={customers}>
                <WhiteHeaderGrid
                  data={customers}
                  height={'280px'}
                  headers={["Customer", "Phone", "Email"]}
                  overflowX={'auto'}
                />
              </div>

            </>
          )
        }
        {
          sendMessage && <Alert message={"You have to inform Email or SMS as way of communication."} onClose={() => { setSendMessage(false) }} />

        }

        {
          showConfirmation && <Alert message={"Message sent successfully."} onClose={() => { setShowConfirmation(false) }} />
        }

      </div>
      {
        isLoading && (
          <div className="loading-container global-form-column">
            <img src="/images/Loading_1.svg" />
            <label className="loading">Loading...</label>
          </div>
        )
      }
    </>


  )
}

