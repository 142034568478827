import React, { useState } from 'react'
import History from "../../routes/history"
import { loginApi, utilsModuleApi } from "../../services/api";
import { setLoggedUser } from "../../auth"
import { Alert, Button, Form, Input } from 'antd';
import "./login.scss"

export default function Login() {
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(undefined);
    const [isRecovering, setIsrecovering] = useState(false);

    const handleRecovery = (data) => {
        setIsLoading(true);
        setAlert(undefined)
        utilsModuleApi.post('/email/SendRecoveryPasswordEmail', data)
            .then((response) => {
                if (response.data.success) {
                    setAlert({ message: "An email was sent to you, please check yout email for more informations", type: "success" });
                }
            }).catch((err) => {
                setAlert({ message: "Something went wrong!", type: "error" });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleLogin = (data) => {
        setIsLoading(true);
        setAlert(undefined);
        // setToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmYzEwMzg2OTJmMDI4MGNlOGU4NDlkMiIsImlhdCI6MTYxMzM5OTgyMSwiZXhwIjoxNjE0MDA0NjIxfQ.SPYkHbtf3Y_NdDql73wbxXPRGLWe8vIAugN65UEqVsE")
        // return History.push('/Launcher')
        loginApi.post('/login/login', data)
            .then(response => {
                const user = response.data.returnUser;
                user['token'] = response.data.token;
                setLoggedUser(user);
                History.push('/');
                History.go(0);
            })
            .catch(error => {
                setAlert({ message: "Login or password is incorrect.", type: "error" });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }


    return (
        <div className="login-container">
            <div className="apex-info">
                <img src="/images/loginLogo.png" alt="Apex logo"></img>
            </div>
            { alert && (<Alert className="mb-2" message={alert.message} type={alert.type} showIcon />)}
            { !isRecovering ?
                (
                    <Form onFinish={handleLogin}>
                        <Form.Item name="login" rules={[{ required: true }]} >
                            <Input placeholder="Login" />
                        </Form.Item>
                        <Form.Item name="password" rules={[{ required: true }]}>
                            <Input.Password placeholder="Password" />
                        </Form.Item>
                        <Form.Item>
                            <Button className="d-block w-100" type="primary" htmlType="submit" loading={isLoading}>LOGIN</Button>
                        </Form.Item>
                        <Button className="d-block w-100" type="link" onClick={() => { setIsrecovering(true) }}>Forgot Credentials?</Button>
                    </Form>
                )
                :
                (
                    <Form onFinish={handleRecovery}>
                        <Form.Item name="email" rules={[{ required: true }]} >
                            <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item>
                            <Button className="d-block w-100" type="primary" htmlType="submit" loading={isLoading}>RECOVERY</Button>
                        </Form.Item>
                        <Button className="d-block w-100" type="link" onClick={() => { setIsrecovering(false) }}>Back to login</Button>
                    </Form>
                )}
        </div>
    )
}
