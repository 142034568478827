import React, { useEffect, useState, useRef } from 'react'
import Input from "../../../components/form/input/input";
import Select from "../../../components/form/select/select";
import Slide from "../../../components/form/slides/slides";
import CheckboxInput from "../../../components/form/checkbox/checkbox";
import { FormContainerRegisterGathering } from "../../../components/form/formContainer/formContainerRegisterGathering"
import { Form } from "@unform/web";
import { loginApi } from "../../../services/api";
import * as Yup from "yup";
import "./gatheringRegister.scss"
import Alert from "../../../components/alert/alert"
import moment from "moment";
const axios = require('axios');

var alertMessage = "";
export default function GatheringRegister() {
    const formRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [peopleCounter, setPeopleCounter] = useState(0);
    const [gatheringEvents, setGatheringEvents] = useState([]);
    const [gatheringOptions, setGatheringOptions] = useState([]);
    const [companyName, setCompanyName] = useState("Company Name");
    const checkboxOptions = [
        { id: 'cellphone', value: true, label: "cell phone" }
    ];

    const proximityModuleApi = axios.create({
        // baseURL: 'http://localhost:8083',
        baseURL: "http://proximity.tascsystems.com",
        timeout: 5000,
        headers: {
            "Authorization": "c9ff81fe25",
        }
    });

    const lessPeople = () => {
        if (peopleCounter == 0) {
            alertMessage = "People number must be greather than 0"
            setShowAlert(true)

        } else {
            setPeopleCounter(peopleCounter - 1)
        }
    }
    useEffect(() => {
        if (gatheringEvents.length > 0) {
            var gatheringMap = gatheringEvents.map((element) => {
                return { value: element._id, label: element.name }
            })
            setGatheringOptions(gatheringMap)

        }
    }, [gatheringEvents])

    useEffect(async () => {

        var teste = "http://church.tascsystems.com/sahfhas/hfashfsa"
        var subDomain = /:\/\/([^\/]+)/.exec(teste)[1];
        var result = await loginApi.get('/login/GetCompanyBySubdomain', {
            params: {
                subDomain
            }
        });


        if (result.status === 200) {
            setCompanyName(result.data.content.name)

            var gatheringsResults = await proximityModuleApi.get('/gathering/getGatherinEvent', {
                params: {
                    idCompany: result.data.content._id,
                    page: 1,
                    size: 100,
                    minDate: moment().toDate()

                }
            });
            setIsLoading(false)
            setGatheringEvents(gatheringsResults.data.gathering)
            console.log(gatheringsResults.data.gathering)
        }

    }, [])

    async function handleSubmit(data, { reset }) {

        if (data.phoneNumber === "" && data.email === "") {
            alertMessage = "You must inform email or phone number"
            setShowAlert(true)
            return false;
        }

        if (data.phoneNumber !== "") {
            data.phoneNumber = data.phoneNumber.trim().replace(/[\(\) .']+/g, '');
        }

        if (data.name === "") {
            alertMessage = "You must inform your name"
            setShowAlert(true)

            return false;
        }

        if (data.idRegisterEvent === "") {
            alertMessage = "You must to choose what event you want to go";
            setShowAlert(true)
            return false;
        }

        if (data.isCellPhone.length > 0) {
            data.isCellPhone = data.isCellPhone[0]
        } else {
            data.isCellPhone = false;
        }

        var result = loginApi.post('/login/RegisterGatheringEvent', data)
            .then((response) => {
                alertMessage = "You are registred"
                setShowAlert(true)
                reset()
                setPeopleCounter(0)
            }).catch((error) => {
                console.log(error);
            });

    }

    return (
        <>
            <div style={{
                backgroundImage: `url("/images/container-background.png")`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: "100% 100%",
                minHeight: "100%",
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                width: '100%'
            }}>
                <FormContainerRegisterGathering>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <p className="company-name">{companyName}</p>
                        <div className="form-row">
                            <Input name="name" label="name" />
                        </div>
                        <div className="form-row">
                            <Input type="email" name="email" label="Email" />
                        </div>
                        <div className="form-row">
                            <Input mask="(999) 999.9999" type="text" name="phoneNumber" label="phone Number" />
                            <CheckboxInput className="checkbox" name="isCellPhone" options={checkboxOptions} />
                        </div>
                        <div className="form-row">
                            <Select
                                label="gathering"
                                name="idRegisterEvent"
                                options={gatheringOptions}

                                classNamePrefix="select" />
                        </div>
                        <label style={{ display: "flex", flexDirection: "row" }}>People with you</label>
                        <div className="form-row">

                            <div className="form-group">
                                <button onClick={() => lessPeople()} className="btn-people-counter" type="button"> - </button>
                            </div>
                            <div className="form-group">
                                <Input style={{ padding: 0 }} isHidden="true" name="peopleNumber" type="hidden" value={peopleCounter} />
                                <div className="people-counter-display">{peopleCounter}</div>
                            </div>
                            <div className="form-group">
                                <button onClick={() => setPeopleCounter(peopleCounter + 1)} className="btn-people-counter" type="button"> + </button>
                            </div>
                        </div>
                        <div className="people-number-warning">Not including you</div>
                        <div className="form-row">
                            <Slide
                                name="isShared"

                            />

                            <div className="shared-text">Share my personal data with {companyName}</div>
                        </div>


                        <div className="form-row">
                            <button className="submit" type="submit">Check In</button>

                        </div>
                    </Form>
                </FormContainerRegisterGathering>
            </div>

            {
                isLoading && (
                    <div className="loading-container">
                        <img src="/images/Apex_Loading.gif" />
                    </div>
                )
            }
            {
                showAlert && (<Alert icon="success" message={alertMessage} onClose={() => { setShowAlert(false) }} />)
            }
        </>
    )
}
