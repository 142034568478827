import { getToken, getIdCompany } from "../auth";
import axios from 'axios';

export const configurationModuleApi = axios.create({
  baseURL: "http://configuration.tascsystems.com",
  //baseURL: "http://localhost:8085",
  timeout: 5000,
  headers: {
    "Authorization": "Bearer " + getToken(),
    "idCompany": getIdCompany()
  }
});

export const utilsModuleApi = axios.create({
  baseURL: "http://utils.tascsystems.com",
  timeout: 5000,
  headers: {
    "Authorization": "Bearer " + getToken(),
    "idCompany": getIdCompany()
  }
});

export const configurationUploadImageApi = axios.create({
  baseURL: "http://configuration.tascsystems.com",
  //baseURL: "http://localhost:8085",
  timeout: 5000,
  headers: {
    'Content-Type': 'multipart/form-data',
    "Authorization": "Bearer " + getToken(),
    "idCompany": getIdCompany()
  }
});


export const expesesModuleApi = axios.create({
  baseURL: 'http://localhost:8080/',
  timeout: 5000,
  headers: {
    "Authorization": "Bearer " + getToken(),
    "idCompany": getIdCompany()
  }
});

export const systemsModuleApi = axios.create({
  baseURL: 'http://systems.tascsystems.com/',
  // baseURL: 'http://localhost:8082/',
  timeout: 5000,
  headers: {
    "Authorization": "Bearer " + getToken(),
    "idCompany": getIdCompany()
  }
});

export const taskModuleApi = axios.create({
  baseURL: 'http://localhost:8081/',
  timeout: 5000,
  headers: {
    "Authorization": "Bearer " + getToken(),
    "idCompany": getIdCompany()
  }
});

export const proximityModuleApi = axios.create({
  baseURL: "http://proximity.tascsystems.com",
  //baseURL: 'http://localhost:8083/',
  timeout: 7000,
  headers: {
    "Authorization": "Bearer " + getToken(),
    "idCompany": getIdCompany(),
  }
});

export const loginApi = axios.create({
  baseURL: "http://login.tascsystems.com",
  //baseURL: 'http://localhost:8086/',
  timeout: 5000
});

export const smsModuleApi = axios.create({
  baseURL: 'http://localhost:8089/',
  timeout: 5000,

});

export const networkModuleApi = axios.create({
  baseURL: "http://localhost:8088",
  timeout: 1000,
  headers: {
    "Authorization": "Bearer " + getToken(),
    "idCompany": getIdCompany(),
  }
});

// systemsModuleApi.defaults.headers['Authorization'] = "Bearer " + getToken();
// systemsModuleApi.defaults.headers['idCompany'] = "5a7243b2fdba9d1f10822bf8";



const productionAlias = {
  LOGIN_MODULE: "http://login.tascsystems.com",
  PROXIMITY_MODULE: "http://proximity.tascsystems.com",
  TASK_MODULE: "http://proximity.tascsystems.com",
  SYSTEMS_MODULE: "http://systems.tascsystems.com",
  EXPENSES_MODULE: "http://expenses.tascsystems.com",
  CONFIGURATION_MODULE: "http://configuration.tascsystems.com",
  UTILS_MODULE: "http://utils.tascsystems.com",
  NETWORK_MODULE: "http://network.tascsystems.com"
}



const localAlias = {
  LOGIN_MODULE: 'http://localhost:8086/',
  PROXIMITY_MODULE: 'http://localhost:8083/',
  SYSTEMS_MODULE: "http://systems.tascsystems.com",
  CONFIGURATION_MODULE: 'http://localhost:8085/',
  UTILS_MODULE: 'http://localhost:8087/',
  NETWORK_MODULE: 'http://localhost:8088/',
}