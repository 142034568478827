import React from 'react'

export default function Loading() {
    return (
        <div className="loading-container global-form-column">
            <img src="/images/Loading_1.svg" />
            <label className="loading">Loading...</label>
        </div>
    )
}
