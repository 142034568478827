import React, { useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import Layout from '../../components/layout/layout';
import Domains from './domains/domains';
import HealthChecks from './healthChecks/healthChecks';
import LoadBalancers from './loadBalancers/loadBalancers';

const Network = () => {
    const { path, url } = useRouteMatch();
    const [title, setTitle] = useState("Domains");
    const menu = [
        { name: "Domains", link: `${path}/Domains` },
        { name: "Load Balancers", link: `${path}/LoadBalancers` },
        { name: "Health Checks", link: `${path}/HealthChecks` }
    ];

    return (
        <Layout module="Network module" menu={menu} title={title} onChangeActive={(item) => { setTitle(item) }}>
            <Switch>
                <Route path={`${path}/Domains`} component={Domains} />
                <Route path={`${path}/LoadBalancers`} component={LoadBalancers} />
                <Route path={`${path}/HealthChecks`} component={HealthChecks} />
                <Redirect to={`${path}/Domains`} />
            </Switch>
        </Layout>
    )
};

export default {
    name: 'Network',
    private: true,
    routeProps: {
        path: '/Network',
        component: Network
    }
}