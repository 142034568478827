import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Input, Row, Select, Space } from 'antd'
import { networkModuleApi } from '../../../../services/api';

export default function HealthCheckDetail(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [model, setModel] = useState(props.model);

    useEffect(() => {
        form.setFieldsValue({
            name: model.name,
            method: model.method,
            port: model.port,
            endpoint: model.endpoint,
            maxFailures: model.maxFailures ? model.maxFailures : 3,
            retryInterval: model.retryInterval ? model.retryInterval : 5,
            checkInterval: model.checkInterval ? model.checkInterval : 30,
            notification: model.notification
        });
    }, [model])

    const handleSave = (values) => {
        setIsLoading(true);
        if (model._id) {
            networkModuleApi.put(`/HealthCheck?id=${model._id}`, values)
                .then((res) => {
                    setModel(res.data.content);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            networkModuleApi.post(`/HealthCheck`, values)
                .then((res) => {
                    setModel(res.data.content);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleRemove = () => {
        setIsLoading(true);
        networkModuleApi.delete(`/HealthCheck?id=${model._id}`)
            .then(() => {
                handleOnClose();
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const handleOnClose = () => {
        props.onClose();
    }

    const validateCheckInterval = async (rule, value) => {
        let maxFailures = form.getFieldValue('maxFailures');
        let retryInterval = form.getFieldValue('retryInterval');
        let checkInterval = form.getFieldValue('checkInterval');
        if (checkInterval <= (maxFailures * retryInterval))
            throw new Error('Error!');
    }

    return (
        <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={handleSave}
        >
            <Card bordered={false} style={{ maxWidth: "617px" }}>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Row gutter={[10, 10]}>
                    <Col span={24} sm={12} md={8}>
                        <Form.Item
                            label="Method"
                            name="method"
                            rules={[{ required: true }]}
                        >
                            <Select>
                                <Select.Option value="HTTP">HTTP Request</Select.Option>
                                <Select.Option value="HTTPS">HTTPS Request</Select.Option>
                                <Select.Option value="PING">Ping</Select.Option>
                                <Select.Option value="TCP">TCP Connection</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12} md={8}>
                        <Form.Item
                            label="Port"
                            name="port"
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12} md={8}>
                        <Form.Item
                            label="Endpoint"
                            name="endpoint"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12} md={8}>
                        <Form.Item
                            label="Max Failures"
                            name="maxFailures"
                            rules={[{ validator: validateCheckInterval }]}
                        >
                            <Input type="number" min="1" />
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12} md={8}>
                        <Form.Item
                            label="Retry Interval"
                            name="retryInterval"
                            rules={[{ validator: validateCheckInterval }]}
                        >
                            <Input type="number" min="1" addonAfter="seconds" />
                        </Form.Item>
                    </Col>
                    <Col span={24} sm={12} md={8}>
                        <Form.Item
                            label="Check Interval"
                            name="checkInterval"
                            rules={[{ validator: validateCheckInterval }]}
                        >
                            <Input type="number" min="1" addonAfter="seconds" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    className="mb-0 show-error"
                    label="Health notification destination"
                    name="notification"
                    rules={[{ required: true, message: '', type: 'array', defaultField: { type: 'email', message: 'The tag should an email' } }]}
                >
                    <Select className="w-100" mode="tags" tokenSeparators={[',', ' ']}/>
                </Form.Item>
            </Card>
            <Space className="form-actions">
                <Button type="primary" htmlType="submit" loading={isLoading} >Save</Button>
                <Button type="secondary" onClick={handleRemove} disabled={!model._id} loading={isLoading} >Remove</Button>
            </Space>
        </Form>
    );
}