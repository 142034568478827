import styled from "styled-components";

export const FormContactCommunication = styled.div`

flex:2;

   form{
    display:flex;
    flex-direction: column;
    border-radius: 10px;
   }
   
   .slides-align{
       display:flex;
     
   }
  
   .slide + .slide{
       margin-left: 70px;
   }
    .form-row{
        display:flex;
        flex-direction:row;
        margin-bottom:10px;
        flex-wrap: wrap;
        align-items:center
    }
    
    .form-group{
        flex-direction: column;
    	display: flex;
        padding:10px;
        margin-bottom:0;
        flex: 1;
    }

    .button-container{
        display:flex;
        justify-content:center;
        align-items:center;
        padding:10px;

        button{
            margin-top:15px;
        }
        
    }


    label{
        font-size: 12px;
        line-height: 150%;
    }

    input{
        margin-top: 5px;
        height:30px;
        border-radius:5px;
        width: 100%;
        padding-left:5px
    }

    .checkbox{
        height:12px;
        width: auto;
        margin-left: 10px;
        cursor:pointer;
    }

    .label-checkbox{
        cursor:pointer;
    }

    textarea{
        margin-top: 5px;
        min-height:50px;
        border-radius:5px;
        width: 100%;
        padding-left: 5px; 
        resize:none;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;  
    }

    button.submit{
        background-color:#0F5071;
        padding: 5px 10px;
        color: #fff;
        border-radius: 5px;
        margin-right:10px;
        cursor: pointer;
       
    }

    button.reset{
        padding:10px;
        border-radius: 5px;
        margin-right:10px;
        cursor: pointer;
        background-color:#fff;
        color: #0F5071;
        border: 1px solid #0F5071;

    }


    @media(max-width: 1050px){
        
      
        .date-form-row{
            .form-group{
                flex:100%
            }
        }

        .button-container{
            width:100%;

            button{
                width:inherit
            }
        }
       
      
            .submit {
                 width:auto;
                 margin-top:15px
            }
        
    }

`;
