
const INITIAL_STATE = []

export default function tasks(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "UPDATE_TASKS":
            return {
                ...state,
                tasks: action.tasks
            }
        default:
            return state
    }
}