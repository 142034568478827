export function updateEnv(env) { 
    return {
        type: "UPDATE_ENV",
        env
    }
}

export function updatelogo(logo) {    
    return {
        type: "UPDATE_LOGO",
        logo
    }
}

export function updateBackground(background) {    
    return {
        type: "UPDATE_BACKGROUND",
        background
    }
}