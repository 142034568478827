import React, { useEffect, useState } from 'react';
import { LineBoxContainer } from '../../../components/proximityManagement/proximityBoxStyle/proximityBoxStyle';
import ConfirmationAlert from "../../../components/alert/confirmationAlert";
import History from "../../../routes/history"
import { loginApi } from "../../../services/api";
import "../contactTracing/contactTracing.css"
import "./line.css"

const axios = require('axios');

export default function Line(props) {

    const [numberInLine, setNumberInLine] = useState(0);
    const [timeWaiting, setTimeWaiting] = useState(0);
    const [alertConfirmation, setAlertConfirmation] = useState(undefined)
    const [isLoading, setIsLoading] = useState(true);
    const [hideLeaveLine, setHideLeaveLine] = useState(true);
    const [minutesAvgText, setMinutesAvgText] = useState("Average waiting time is");
    const [position, setPosition] = useState(true);
    const [clientTurn, setClientTurn] = useState(false);
    var idCompany;
    var idClient = props.match.params.id;
   
    var url = window.location.href
    var subDomain = /:\/\/([^\/]+)/.exec(url)[1];

    const proximityModuleApi = axios.create({
        baseURL: "http://proximity.tascsystems.com",
       // baseURL: 'http://localhost:8083/',
        timeout: 5000,
        headers: {
            "Authorization": "c9ff81fe25",
        }
    });

    useEffect(async () => {
        const interval = setInterval(async () => {
            var company = await loginApi.get('/login/GetCompanyBySubdomain', {
                params: {
                    subDomain
                }
            });
            if (company.status === 200) {
                idCompany = company.data.content._id;
            }

            var result = await proximityModuleApi.get("/Proximity/NumberInLine", {
                params: {
                    idCompany: idCompany,
                    idClient: idClient
                }

            });
            if (result && result.data && result.data.content != 0) {
                setNumberInLine(result.data.content);
                setIsLoading(false)
            }
            else {
                setPosition(false)
                setClientTurn(true)
                setMinutesAvgText("Average waiting time was")
                setHideLeaveLine(false)
                setIsLoading(false)
            }

            var minutesWaiting = await proximityModuleApi.get("/Proximity/MinutesWaiting", {
                params: {
                    idCompany: idCompany
                }
            });
            if (minutesWaiting && minutesWaiting.data && minutesWaiting.data.content) {
                setTimeWaiting(minutesWaiting.data.content);
                setIsLoading(false)
            }
          
        }, 2000)
    
        return () => clearInterval(interval);

    }, [])

    
      var GetOutLineMethod = async () =>   {
        try {
            var company = await loginApi.get('/login/GetCompanyBySubdomain', {
                params: {
                    subDomain
                }
            });
            if (company.status === 200) {
                idCompany = company.data.content._id;
            }

            proximityModuleApi.delete('/Proximity/GetOutLine', {
                params: {
                    idClient: idClient,
                    idCompany: idCompany

                }
            }).then(function (response) {
                if (response.status == 200) {
                    closeConfirmationAlert()
                    setIsLoading(false)
                    return History.push('/line-entering')
                }
            }).catch((err) => {
                setIsLoading(false)
                console.log(err)
            });

        } catch (err) {
            setIsLoading(false)
            console.log(err)
        }

    };

    const openConfirmationAlert = (idClient) => {

        setAlertConfirmation(idClient)
    }

    const closeConfirmationAlert = () => {
        setAlertConfirmation(undefined)
    }

    return (
        <>
            <div style={{
                backgroundImage: `url("/images/container-background.png")`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: "100% 100%",
                minHeight: "100%",
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                width: '100%'
            }}>
                <LineBoxContainer>
                    <div className="font-color">
                        <div><img className="company-icon" src="/images/company-icon.png"></img> </div>
                        <div>
                            {
                                position && <span className='position-in-line margin-auto'> Your are number <br /><span className="number-on-line">{numberInLine}</span> <br /> in the line </span>
                            }
                            {
                                clientTurn && <span className="position margin-auto"> Your <br /> turn!</span>

                            }
                        </div>
                        {
                            minutesAvgText &&
                            <div>
                                <span className='waiting-time margin-auto'>{minutesAvgText}<br /> <br /> <span className="time"> {timeWaiting} minutes </span> </span>
                            </div>

                        }
                        <div className="form-row">
                            {
                                hideLeaveLine && <button type="submit" className="leave-line-button" onClick={() => openConfirmationAlert(idClient)}  > LEAVE LINE</button>
                            }
                        </div>
                    </div>

                </LineBoxContainer>

                {
                    alertConfirmation && <ConfirmationAlert
                        idToRemove={alertConfirmation}
                        doAction={GetOutLineMethod}
                        closeAlert={closeConfirmationAlert}
                        message={"Do you really wanna leave line?"} />
                }

                {
                    isLoading && (
                        <div className="loading-container global-form-column">
                            <img src="/images/Loading_1.svg" />
                            <label className="loading">Loading...</label>
                        </div>
                    )
                }

            </div>
        </>
    )
}
