import React, { useEffect, useState } from 'react';
import { networkModuleApi } from '../../../services/api';
import './loadBalancers.scss'

import Loading from '../../../components/loading/loading';
import { Row, Col, Card, Drawer, Tag } from 'antd';
import LoadBalancerDetail from './loadBalancerDetail/loadBalancerDetail';

export default function LoadBalancers() {
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [selected, setSelected] = useState({});
    const [showDrawer, setShowDrawer] = useState(false);

    useEffect(async () => {
        setIsLoading(true);
        getBalancers();
        setIsLoading(false);
    }, []);

    const getBalancers = async () => {
        const result = await networkModuleApi.get('/Balancer/All');
        setList(result.data.content);
    };

    const getTitle = (balancer) => {
        if (balancer.idDomain) {
            return balancer.idDomain.url;
        } else {
            return balancer.address;
        }
    };

    const getStatus = (balancer) => {
        if (balancer.origins.length > 0) {
            let fails = 0;
            balancer.origins.map((origin) => {
                if (origin.status === 0) {
                    fails++;
                }
            });
            if (fails == 0) {
                return "Health";
            } else {
                if (fails === balancer.origins.length) {
                    return "All origns down";
                } else if (fails > 1) {
                    return fails + " origns down";
                } else {
                    return "1 orign down";
                }
            }
        } else {
            return "No origns";
        }
    };

    const getStatusStyle = (status) => {
        if (status === 1) {
            return "success";
        } else if (status === 2) {
            return "warning";
        } else {
            return "error";
        }
    };

    const handleDrawerClose = () => {
        getBalancers();
        setShowDrawer(false);
    }

    return (
        <>
            <Row gutter={[12, 12]}>
                {
                    list.map((item) => {
                        return (
                            <Col key={item._id} span={6} style={{ maxWidth: '282px', minWidth: '282px' }}>
                                <Card
                                    className="c-pointer"
                                    title={[
                                        <div key="title">{getTitle(item)}</div>,
                                        <Tag key="tag" color={getStatusStyle(item.status)}>{getStatus(item)}</Tag>
                                    ]}
                                    bordered={false}
                                    style={{ height: '200px' }}
                                    onClick={() => { setSelected(item); setShowDrawer(true); }}
                                >
                                    <div className="origins">
                                        {
                                            item.origins && (item.origins.map((origin) => {
                                                return (
                                                    <div key={origin._id} className="origin">
                                                        {origin.address}:{origin.port}
                                                        <strong className={origin.status === 1 ? "ok" : "fail"}>{origin.status === 1 ? "ok" : "fail"}</strong>
                                                    </div>
                                                )
                                            }))
                                        }
                                    </div>
                                </Card>
                            </Col>
                        );
                    })
                }
                <Col key="add" span={6} style={{ maxWidth: '282px', minWidth: '282px' }}>
                    <Card className="add c-pointer" bordered={false} style={{ height: '200px', width: '100%' }} onClick={() => { setSelected({}); setShowDrawer(true); }}>
                        Add Load Balancer
                    </Card>
                </Col>
            </Row>
            <Drawer
                visible={showDrawer}
                title="Load Balancer Manage"
                mask={false}
                getContainer="#main-container"
                destroyOnClose={true}
                onClose={handleDrawerClose}
            >
                <LoadBalancerDetail id={selected._id} onClose={handleDrawerClose} />
            </Drawer>
            { isLoading && (<Loading />)}
        </>
    );
}