const INITIAL_STATE = {
    onPremisse: false,
    internet: true
}

export default function configuration(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "UPDATE_ENV":
            return {
                ...state,
                onPremisse: action.onPremisse,
                internet: action.internet
            }
        case "UPDATE_BACKGROUND":
            return {
                ...state,
                background_image: action.background
            }
        case "UPDATE_LOGO":
            return {
                ...state,
                logo: action.logo
            }
        default:
            return state
    }
}