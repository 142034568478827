import React, { useEffect, useRef, Component } from 'react';
import QRious from 'qrious';
import "./PDFLine.scss"
import { getIdCompany } from '../../../auth';
import { loginApi } from "../../../services/api";

export default class InstructionsToPrint extends Component {

    render() {

        var url = window.location.href
        var subDomain = /:\/\/([^\/]+)/.exec(url)[1];
        var size = "240"

        var QRCodes = () => {
            var idCompany = getIdCompany();
            var encoded = window.btoa(idCompany);

            const canvas = useRef(null);
            useEffect(() => {

                var result =  loginApi.get('/login/GetCompanyBySubdomain', {
                    params: {
                        subDomain
                    }
                });

                if (canvas != null && canvas.current != null) {
                    var qr = new QRious({
                        element: canvas.current,
                        value: "http://" + subDomain + "/Line-entering",
                        size: size,

                    });
                }
            });
            return (<canvas ref={canvas}></canvas>);

        }

        return (

            <div>
                <div className='check-info'>
                    <h2 style={{ color: "white" }}><b>Hi. Check in here</b></h2>
                    <h2><b>We'll text you when it's your turn.</b></h2>
                </div>

                <div>
                    <div className="text-with-info">
                        <h3>Join our virtual line to <br /> wait outside or in your car</h3>
                        <div className="instructions-pdf">
                            <div>
                                Text your first name to (201) 308-1824.
                       </div>
                            <div>
                                Or scan the QR Code with yout smartphone camera.
                       </div>
                            <div>
                                Then, follow the instructions - we'll text you when we're ready for you inside.
                       </div>
                        </div>
                    </div>
                    <div className="qr-code-line">
                        <QRCodes subDomain={subDomain} size={size} />
                    </div>
                </div>
            </div>

        );
    }


}

