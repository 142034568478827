import styled from "styled-components";

export const BoxContainer = styled.div`
    width: 100%;
    height: 305px;
    margin-top: 20px;
    background: #FFFFFF;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
`;


export const UserBoxContainer = styled.div`
width: 100%;
background: #FFFFFF;
box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 3px 5px rgba(0, 0, 0, 0.1);
border-radius: 10px;

@media (max-width: 800px) {
    height: 408px;
    margin-top: 6px;
}
`;

export const MessageBoxContainer = styled.div`
background: #F4F4F4;
box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 3px 5px rgba(0, 0, 0, 0.1);
border-top-left-radius: 8px;
border-bottom-left-radius: 8px;
`;

export const ClientBoxContainer = styled.div`
width: 100%;
background: #FFFFFF;
border-top-right-radius: 8px;
border-bottom-right-radius: 8px;
padding: 15px;
box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 3px 5px rgba(0, 0, 0, 0.1);

@media (max-width: 1050px) {
border-radius: 8px;
}

`;

export const TableBoxContainer = styled.div`
width: 100%;
height: 380px;
background: #F4F4F4;
box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
border-radius: 10px;

`;

export const QRCodesBoxContainer = styled.div`
height: 270px;
background: #F4F4F4;
box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1), 0px 3px 5px rgba(0, 0, 0, 0.1);
border-radius: 8px;
margin-right: 31px; 

`;

export const ContactTracingBoxContainer = styled.div`
background-color: #FFFFFF ;
backdrop-filter: blur(25px);
padding: 68px;

`;

export const LineEnteringBoxContainer = styled.div`
background-color: #FFFFFF ;
backdrop-filter: blur(25px);
padding: 70px;
height: 900px !important;
`;

export const LineBoxContainer = styled.div`
background-color: #FFFFFF ;
backdrop-filter: blur(25px);
padding: 80px;
`;

