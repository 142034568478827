import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

import './slides.scss'

const Slides = ({ name, label, classes, ...rest }) => {
    const inputRefs = useRef(null);
    const { fieldName, registerField, defaultValue = false , error } = useField(name);
    const [inputValue, setInputValue] = useState(defaultValue);

    const onClick = async (e) => {
        await setInputValue(!inputValue);
    }

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRefs.current,
            path: "value",
        });
    }, [fieldName, registerField]);

    return (
        <div className={`slide ${ label ? 'has-label' : '' }`}>
            <label className="switch">
                <input type="checkbox" ref={inputRefs} name={name} value={inputValue} onClick={onClick} {...rest} />
                <div className="slider round"></div>
            </label>
            <label className={ `switch-label ${ classes ? classes : '' }`}>{label}</label>
        </div>
    );
};
export default Slides;