import React, { useEffect, useState } from 'react';
import Diagram, { useSchema } from 'beautiful-react-diagrams';
import 'beautiful-react-diagrams/styles.css';

export const CustomRender = ({ id, content, data, ...props }) => {
    const [inputs, setInputs] = useState(props.inputs);
    const [outputs, setOutputs] = useState(props.outputs);
    
    return (
        <div style={{ background: 'purple' }}>
            <div role="button" style={{ padding: '15px' }} onClick={() => data.onClick(id)}>
                {content}
            </div>
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
                {inputs.map((port) => React.cloneElement(port, { style: { width: '25px', height: '25px', background: '#1B263B' } }))}
                {outputs.map((port) => React.cloneElement(port, { style: { width: '25px', height: '25px', background: '#1B263B' } }))}
            </div>
        </div>
    )
};

export const DeviceDiagram = ({ mapping, ...props }) => {
    const [schema, { onChange, addNode }] = useSchema(mapping);

    useEffect(() => {
        //check if schema have duplicate link
        schema.links.map((link, index) => {
            if (schema.links.filter(x => x.input === link.input && x.output === link.output).length > 1) {
                schema.links.splice(index, 1);
            }
        });

        if (typeof props.onMappingChange === 'function') {
            props.onMappingChange(schema);
        }
    }, [schema]);

    useEffect(() => {
        if (props.newDevice._id) {
            let coordinates = [];
            coordinates.push((schema.nodes.length - 1) * 10);
            coordinates.push((schema.nodes.length - 1) * 10);
    
            let nextNode = {
                id: props.newDevice._id,
                content: props.newDevice.name,
                coordinates: coordinates,
                inputs: [{ id: `input-1-${props.newDevice._id}`}],
            };
            
            addNode(nextNode);
        }
    }, [props.newDevice]);

    return (
        <div style={props.style}>
            <Diagram schema={schema} onChange={onChange} />
        </div>
    );
};