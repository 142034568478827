import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { UserBoxContainer } from '../proximityManagement/proximityBoxStyle/proximityBoxStyle';
import { GetLoggedUser } from '../../services/services'
import "./userBox.css"

function UserBox({ status, minutesServicing }) {

    const [user, setUser] = useState(0);

    useEffect(async () => {
        var userData = await GetLoggedUser();
        setUser(userData)
        
    }, [])
    return (
        <>

        
            <div className="content-user-box">
                <UserBoxContainer>
                    <div className="box-user">
                        <div className="display">
                            <img className="user-img" src="/images/user.png"></img>
                            <span className="user">{user.name}</span>
                        </div>
                    </div>
                    <div className="box-status">
                        <div className="user-time">
                            <span className="text-time">{minutesServicing}</span>
                            <div className="text-status">
                                minutes avg. <br /> servicing
                            </div>
                        </div>
                        <div className={`
                        status 
                        display
                        ${status === "SERVICING" ? 'status-change' : ''}
                        `}>
                            <div className={`
                       font-status
                        ${status === "SERVICING" ? 'font-change' : ''}
                        `}>{status}</div>
                        </div>

                    </div>

                </UserBoxContainer>
            </div>
        </>
    )

}

export default UserBox;