import React, { useState } from 'react';
import { networkModuleApi } from '../../../../services/api';
import { Button, Card, Col, Row, Space, Switch, Table, Tag } from 'antd'

export default function DomainDetail(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [model, setModel] = useState(props.model);


    const columns = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Host',
            dataIndex: 'host',
            key: 'host'
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value'
        },
        {
            title: 'Status',
            key: 'status',
            render: (text, item) => (
                <Tag color={item.status !== 0 ? 'success' : 'warning'} style={{width: 70}}>{item.status !== 0 ? 'OK' : 'Missing'}</Tag>
            )
        },
    ];

    const onClose = () => {
        props.onClose();
    };

    const getDomain = async () => {
        setIsLoading(true);
        networkModuleApi.get(`/Domain?id=${model._id}`)
            .then((res) => {
                setModel(res.data.content);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleDelete = () => {
        setIsLoading(true);
        networkModuleApi.delete(`/Domain?id=${model._id}`)
            .then(() => {
                onClose();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleToggleSSL = (value) => {
        setIsLoading(true);
        networkModuleApi.put(`/Domain/ToggleSSL?id=${model._id}`, {
            enable: value,
        })
            .then(() => {
                setModel({ ...model, ...{ ssl: value } });
            })
            .catch(() => {

            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div style={{ maxWidth: "627px" }}>
            <Card bordered={false} style={{ marginBottom: 12 }}>
                <Row gutter={[10, 10]} align="middle">
                    <Col span={14}>
                        <strong>{model.url}</strong>
                    </Col>
                    <Col span={10} style={{ textAlign: "right" }}>
                        <Space>
                            <Button type="secondary" onClick={() => getDomain()} loading={isLoading}>Check status</Button>
                            <Button type="primary" onClick={handleDelete} loading={isLoading}>Remove</Button>
                        </Space>
                    </Col>
                </Row>
            </Card>
            <div style={{ marginLeft: 8, marginBottom: 8, fontWeight: 600, fontSize: 10 }}>DNS entries</div>
            <Table className="table-striped" rowKey="_id" columns={columns} dataSource={model.Entries} pagination={false} />
            <Card bordered={false} style={{ marginTop: 24 }}>
                <Row gutter={[10, 10]}>
                    <Col span={15}>
                        <Space>
                            <span>SSL</span>
                            {model.ssl ?
                                (<Tag color={model.sslStatus !== 0 ? 'success' : 'warning'}>{model.sslStatus !== 0 ? 'Active' : 'Pending'}</Tag>)
                                :
                                (<Tag color="warning">Inactive</Tag>)}
                        </Space>
                    </Col>
                    <Col span={9}>
                        <Space>
                            <Switch defaultChecked={model.ssl} onChange={handleToggleSSL} />
                            <label className="label-switch">Allow non SSL</label>
                        </Space>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}