import React, { useState, useEffect } from 'react';

import "./alert.scss"

export default function ConfirmationAlert(props) {
    return (
        <>
            <div className="modal ">
                <div className="alert-box " name='alertButton'>
                    <img onClick={() => { props.closeAlert() }} className="close-icon" src="/images/close-icon.png" />
                    <div className="alert-content">
                        {props.message}
                    </div>
                    <div className="modal-confirmation-actions">
                        <button onClick={() => { props.closeAlert() }} type="button" className="btn btn-cancel btn-confirmation-alert"> Cancel</button>
                        <button onClick={() => { props.doAction(props.idToRemove && props.idToRemove) }} type="button" className="btn btn-confirmation-alert submit"> Confirm </button>
                    </div>

                </div>
            </div>
        </>
    )

}
