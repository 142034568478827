import React, { useEffect, useState } from 'react';
import { systemsModuleApi } from '../../../../services/api';
import { Card, message, Form, Input, Row, Col, Button, Avatar, Comment, Popconfirm, Drawer, Space, Select, Divider } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Map from '../../../../components/map/map';
// import GoogleMap from '../../../components/map/googleMap';
import moment from 'moment';
import { getLoggedUser } from '../../../../auth';

const { TextArea } = Input;

export default function SiteInformation(props) {
    const user = getLoggedUser();
    const [formAccess] = Form.useForm();
    const [formAddress] = Form.useForm();
    const [formNote] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [site, setSite] = useState({});
    const [system, setSystem] = useState({});
    const [showDrawerAccess, setShowDrawerAccess] = useState(false);
    const [showDrawerAddress, setShowDrawerAddress] = useState(false);

    const toggleDrawerAccess = (obj) => {
        if (obj) {
            formAccess.setFieldsValue(obj);
            setShowDrawerAccess(true);
        } else {
            formAccess.resetFields();
            setShowDrawerAccess(false);
        }
    };

    const handleSaveAccess = (data) => {
        setIsLoading(true);
        let endpoint = data._id ? 'PutAccess' : 'AddAccess';

        systemsModuleApi.put(`/Systems/${endpoint}/${site._id}`, data)
            .then(result => {
                site.access = result.data.content.access;
                toggleDrawerAccess();
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleRemoveAccess = (id) => {
        setIsLoading(true);

        systemsModuleApi.delete(`/Systems/DeleteAccess/${site._id}/${id}`)
            .then(result => {
                site.access = site.access.filter(x => x._id !== id);
                toggleDrawerAccess();
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const toggleDrawerAddress = (obj) => {
        if (obj) {
            formAddress.setFieldsValue(obj);
            setShowDrawerAddress(true);
        } else {
            formAddress.resetFields();
            setShowDrawerAddress(false);
        }
    };

    const handleSaveAddress = (data) => {
        setIsLoading(true);
        site.address = data;

        systemsModuleApi.put(`/Systems/PutSite/${site._id}`, site)
            .then(result => {
                toggleDrawerAddress();
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSaveNote = (data) => {
        setIsLoading(true);
        data['dateTime'] = moment().format('YYYY-MM-DD');
        data['idUser'] = user.id;

        systemsModuleApi.put(`/Systems/AddNote/${site._id}`, data)
            .then(result => {
                data['user'] = user;
                site.notes.push(data);
                formNote.resetFields();
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        systemsModuleApi.get(`/Systems/GetSites?id=${props.id}`)
            .then(result => {
                setSite(result.data.content[0]);
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        if (site.systemId) {
            systemsModuleApi.get(`/Systems/GetSystems?id=${site.systemId}`)
                .then(result => {
                    setSystem(result.data.content[0]);
                })
                .catch(() => {
                    message.error('Something went wrong!');
                });
        }
    }, [site])

    return (
        <>
            <div>
                <Row gutter={[20, 20]}>
                    <Col span={24} md={12} xl={6}>
                        <div className="mb-1">
                            <div className="mb-1"><label>Client</label></div>
                            <Input value={system.client} disabled />
                        </div>
                    </Col>
                </Row>
                <div className="mb-1">
                    <div className="mb-1"><label>Access</label></div>
                    <Row gutter={[20, 20]} align="stretch">
                        {site.access && site.access.map(access => (
                            <Col key={access._id} span={24} md={12} xl={6}>
                                <Card
                                    actions={[
                                        <Popconfirm key="delete" title="Do you really want remove this access?" okText="Yes" cancelText="No" onConfirm={() => handleRemoveAccess(access._id)}>
                                            <DeleteOutlined />
                                        </Popconfirm>,
                                        <EditOutlined key="edit" onClick={() => toggleDrawerAccess(access)} />
                                    ]}
                                >
                                    <p>{access.accessName}</p>
                                    {access.type === 0 && (
                                        <>
                                            <div><strong>User:</strong>{access.username}</div>
                                            <div><strong>Password:</strong>{access.code}</div>
                                        </>
                                    )}
                                    {access.type === 1 && (
                                        <div><strong>PinCode:</strong>{access.code}</div>
                                    )}
                                    {access.type === 2 && (
                                        <>
                                            <div><strong>Name:</strong>{access.username}</div>
                                            <div><strong>Phone:</strong>{access.phone}</div>
                                        </>
                                    )}
                                </Card>
                            </Col>
                        ))}
                        <Col span={24} md={12} xl={6}>
                            <Card className="add c-pointer" style={{ height: '100%', minHeight: '160px', width: '100%' }} onClick={() => toggleDrawerAccess({})}>
                                + Add Access
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="mb-1">
                    <div className="mb-1"><label>Address</label></div>
                    <Card className="no-padding">
                        <Row>
                            <Col span={24} md={12} xl={6}>
                                <div className="p-2">
                                    {site.address && (
                                        <div className="mb-2">
                                            <p className="mb-0">{site.address.unit} {site.address.streetNumber}</p>
                                            <p className="mb-0">{site.address.street} - {site.address.province} - {site.address.country}</p>
                                            <p>{site.address.postalCode}</p>
                                            <p className="mb-0"><strong>Latitude:</strong> {site.address.latitude}</p>
                                            <p className="mb-0"><strong>Longitude:</strong> {site.address.longitude}</p>
                                        </div>
                                    )}
                                    <Button type="primary" onClick={() => toggleDrawerAddress(site.address)}>Edit</Button>
                                </div>
                            </Col>
                            <Col span={24} md={12} xl={18}>
                                {site._id && <Map className="map-devices" width={"auto"} height={"225px"} systems={[{ sites: [site] }]} />}
                            </Col>
                        </Row>
                    </Card>
                </div>
                <div>
                    <div className="mb-1"><label>Notes</label></div>
                    {site.notes && site.notes.length > 1 && (
                        <Card className="mb-1">
                            <div style={{ maxHeight: 300, overflow: 'auto' }}>
                                {site.notes.map(note => {
                                    note['user'] = site.users.find(x => x._id === note.idUser);
                                    return (<Comment
                                        key={note._id}
                                        author={note.user.name}
                                        avatar={<Avatar src={note.user.picture} alt={note.user.name} />}
                                        content={note.note}
                                        datetime={moment(note.dateTime).format('YYYY-MM-DD @ HH:mm')}
                                    />)
                                })}
                            </div>
                        </Card>
                    )}
                    <Card>
                        <Row gutter={[16, 16]} wrap={false}>
                            <Col flex="32px">
                                <Avatar src={user.picture} alt={user.name} />
                            </Col>
                            <Col flex="auto">
                                <Form
                                    form={formNote}
                                    layout="vertical"
                                    requiredMark={false}
                                    onFinish={handleSaveNote}
                                >
                                    <Form.Item name="note">
                                        <TextArea />
                                    </Form.Item>
                                    <Button type="primary" htmlType="submit">Add Note</Button>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </div>
            <Drawer
                visible={showDrawerAccess}
                title="Manage Access"
                mask={false}
                getContainer="#main-container"
                destroyOnClose={true}
                onClose={() => toggleDrawerAccess()}
            >
                <Form
                    form={formAccess}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSaveAccess}
                >
                    <Card bordered={false} style={{ width: "600px" }}>
                        <Form.Item noStyle name="_id">
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item label="Name" name="accessName" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Type" name="type" rules={[{ required: true }]}>
                            <Select>
                                <Select.Option value={0}>Username/Password</Select.Option>
                                <Select.Option value={1}>Pin code</Select.Option>
                                <Select.Option value={2}>Contact person</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}
                        >
                            {({ getFieldValue }) => {
                                if (getFieldValue('type') === 0) {
                                    return (
                                        <>
                                            <Form.Item label="Username" name="username">
                                                <Input />
                                            </Form.Item>
                                            <Form.Item label="Password" name="code">
                                                <Input.Password />
                                            </Form.Item>
                                        </>
                                    )
                                }
                                if (getFieldValue('type') === 1) {
                                    return (
                                        <Form.Item label="Pin code" name="code">
                                            <Input />
                                        </Form.Item>
                                    )
                                }
                                if (getFieldValue('type') === 2) {
                                    return (
                                        <>
                                            <Form.Item label="Name" name="username">
                                                <Input />
                                            </Form.Item>
                                            <Form.Item label="Phone" name="phone">
                                                <Input />
                                            </Form.Item>
                                        </>
                                    )
                                }

                            }}
                        </Form.Item>
                    </Card>
                    <Space className="form-actions">
                        <Button type="secondary" onClick={() => toggleDrawerAccess()}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={isLoading} >Save</Button>
                    </Space>
                </Form>
            </Drawer>
            <Drawer
                visible={showDrawerAddress}
                title="Manage Address"
                mask={false}
                getContainer="#main-container"
                destroyOnClose={true}
                onClose={() => toggleDrawerAddress()}
            >
                <Form
                    form={formAddress}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSaveAddress}
                >
                    <Card bordered={false} style={{ width: "600px" }}>
                        <Row gutter={[10, 0]}>
                            <Col span={24} lg={12}>
                                <Form.Item label="Unit" name="unit">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24} lg={12}>
                                <Form.Item label="Street" name="streetNumber">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Street" name="street">
                            <Input />
                        </Form.Item>
                        <Form.Item label="City" name="city">
                            <Input />
                        </Form.Item>
                        <Row gutter={[10, 0]}>
                            <Col span={12} lg={8}>
                                <Form.Item label="State/Province" name="province">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12} lg={16}>
                                <Form.Item label="Country" name="country">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Postal Code" name="postalCode">
                            <Input />
                        </Form.Item>
                        <Divider />
                        <Form.Item name="latitude" rules={[{ required: true }]}>
                            <Input addonBefore="Latitude" />
                        </Form.Item>
                        <Form.Item name="longitude" rules={[{ required: true }]}>
                            <Input addonBefore="Longitude" />
                        </Form.Item>
                    </Card>
                    <Space className="form-actions">
                        <Button type="secondary" onClick={() => toggleDrawerAddress()}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={isLoading} >Save</Button>
                    </Space>
                </Form>
            </Drawer>
        </>
    )
}