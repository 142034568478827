import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { getLoggedUser, Logout } from "../../auth";
import History from "../../routes/history"
import { Avatar, Breadcrumb, Col, Dropdown, Menu, Row, Space } from 'antd';
import { UserOutlined, BellFilled, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import './headerContent.scss';
import { useLocation } from 'react-router-dom';


const HeaderContent = ({ ...props }) => {
    const location = useLocation();
    const user = getLoggedUser();
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const logout = () => {
        Logout();
        History.push('/Login')
    }

    const access = (menu) => {
        History.push(menu);
    }

    const goTo = (item) => {
        if (item.link) {
            let breadcrumbs = History.location.state.breadcrumbs;
            let index = breadcrumbs.findIndex(x => x.name === item.name) + 1;
            breadcrumbs = breadcrumbs.slice(0, index);
            History.push({
                pathname: item.link,
                state: {
                    breadcrumbs: breadcrumbs
                }
            });
        }
    }

    useEffect(() => {
        if (props.breadcrumbs) {
            let breadcrumbs = [];
            let path = location.pathname.split('/');
            if (path.length === 2) {
                let item = props.menu.find(x => x.name.toLowerCase() === path[1].toLocaleLowerCase());
                breadcrumbs.push(<Breadcrumb.Item key={item.name}>{item.name}</Breadcrumb.Item>);
                History.location.state = null;
            } else {
                breadcrumbs = location.state?.breadcrumbs.map((item, index) => (<Breadcrumb.Item key={item.name} onClick={() => goTo(item)}>{item.name}</Breadcrumb.Item>));
            }
            setBreadcrumbs(breadcrumbs);
        }
    }, [location]);

    const menu = (
        <Menu className="user-info-menu">
            <Menu.ItemGroup title={
                <div className="text-center">
                    <div className="uppercase fs-1">Logged as</div>
                    <div className="fs-2">{user.email}</div>
                </div>
            }>
                <Menu.Item className="fs-3" icon={<SettingOutlined />} onClick={() => { access("/Settings") }}>Settings</Menu.Item>
                <Menu.Item className="fs-3" icon={<UserOutlined />} onClick={() => { access("/Settings/Account") }}>Account</Menu.Item>
                <Menu.Item className="fs-3" icon={<LogoutOutlined />} onClick={() => { logout() }}>Logout</Menu.Item>
            </Menu.ItemGroup>
        </Menu>
    );

    return (
        <div className="mb-2" style={{ display: props.isHeaderVisible ? "block" : "none" }}>
            <Row gutter={20} align="middle">
                <Col flex="auto">
                    {props.breadcrumbs ?
                        (<Breadcrumb>{breadcrumbs}</Breadcrumb>)
                        :
                        (<h1 className="uppercase">{props.title}</h1>)
                    }
                </Col>
                <Col className="text-right" flex="auto">
                    <Space align="center">
                        <BellFilled style={{ fontSize: 24, color: "#f5f5f5" }} />
                        <Dropdown overlay={menu}>
                            <div className="user-info">
                                <span className="username fs-3">{user.name}</span>
                                {user.picture ? <Avatar src={user.picture} /> : <Avatar icon={<UserOutlined />} />}
                            </div>
                        </Dropdown>
                    </Space>
                </Col>
            </Row>
        </div>
    )
}

export default connect(state => ({
    isHeaderVisible: state.appearance.isHeaderVisible
}))(HeaderContent)