import History from "./routes/history";
const jwt = require("jsonwebtoken");

export const isLogged = () => {
    const token = getToken();
    if (token) {
        var decodedToken = jwt.decode(token, { complete: true })
        // expired date verification
        if (decodedToken.exp < new Date().getTime())
            return false;
        return true
    }
    return false
}

export const setLoggedUser = (user) => {
    localStorage.setItem('ApexUser', JSON.stringify(user));
} 

export const getLoggedUser = () => {
    const user = JSON.parse(localStorage.getItem('ApexUser'));
    if(!user){
        History.push('/');
    }
    return user;
} 

export const Logout = () => {
    localStorage.removeItem("ApexUser");
}

export const getToken = () => {
    const user = getLoggedUser();
    return user?.token;
}

export const getIdCompany = () => {
    // return "5a7243b2fdba9d1f10822bf8"
    const user = getLoggedUser();
    return user?.idCompany;
}

export const getIdUser = () => {
    // return "5fc1038692f0280ce8e849d2"
    const user = getLoggedUser();
    return user?.id;
}

export const getUserEmail = () => {
    const user = getLoggedUser();
    return user?.email;
}

export const getUserName = () => {
    const user = getLoggedUser();
    return user?.name;
}

export const getUserPicture = () => {
    const user = getLoggedUser();
    return user?.picture;
}