import React, { createContext, useState, useContext, useEffect } from "react";
import Alert from "../components/alert/alert";
const GlobalAlertContext = createContext();



export default function GatheringProvider({ children }) {

    const [globalAlert, setGlobalAlert] = useState(null)

    return (
        <GlobalAlertContext.Provider value={{
            globalAlert,
            setGlobalAlert
        }}
        >
            {children}

            {globalAlert && (<Alert icon={globalAlert.icon} message={globalAlert.message} onClose={() => { setGlobalAlert(false) }} />)}
        </GlobalAlertContext.Provider>
    )
}

export function useGlobalAlert() {
    const context = useContext(GlobalAlertContext);
    const { globalAlert, setGlobalAlert } = context;
    return { globalAlert, setGlobalAlert }
}

