import React, { useEffect, useState, useRef } from 'react';
import { proximityModuleApi } from "../../../services/api";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import UserBox from "../../../components/userBox/userBox";
import CurrentLineBox from "../../../components/currentLineBox/currentLineBox"
import { BoxContainer } from '../../../components/proximityManagement/proximityBoxStyle/proximityBoxStyle';
import HistoryBox from "../../../components/historyBox/historyBox"
import "./lineControl.css"
import { connect, useDispatch } from "react-redux";
import { toogleSideMenu } from '../../../store/actions/appearance';
import { toogleHeader } from '../../../store/actions/appearance';
import { getIdUser, getIdCompany } from '../../../auth';
import Alert from "../../../components/alert/alert";
import moment from "moment";

function LineControl(props) {

  const [peopleInLine, setPeopleInLine] = useState(0);
  const [peopleBeingServed, setPeopleBeingServed] = useState(0);
  const [timeWaiting, setTimeWaiting] = useState(0);
  const [history, setHistory] = useState([]);
  const [minutesServicing, setMinutesServicing] = useState(0);
  const [status, setStatus] = useState("WAITING NEXT");
  const [call, setCall] = useState(true);
  const [reCall, setReCall] = useState(true);
  const [stopServicing, setStopServicing] = useState(false);
  const [noShow, setNoShow] = useState(true);
  const [isShowingFullscreen, setIsShowingFullscreen] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);
  const [alertReCall, setAlertReCall] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const handle = useFullScreenHandle();
  const [idUser, setIdUser] = useState(null);
  const [idService, setIdService] = useState(0);
  const [idCompany, setIdCompany] = useState(0);
  const dispatch = useDispatch()
  var date = new Date();
  var timeZone = date.getTimezoneOffset();
  useEffect(async () => {

    setIdUser(getIdUser())
    setIdCompany(getIdCompany())

  }, [])

  useEffect(() => {
    if (idUser) {
      proximityModuleApi.get("/Proximity/CheckServices", {
        params: {
          idUser: idUser,
          idCompany: idCompany
        }
      }).then((res) => {
        if (res && res.status == 200) {
          var serverId = res.data.content;
          if (serverId != 0) {
            setIdService(serverId)
            setStopServicing(true);
            setReCall(true);
            setNoShow(true);
            setCall(false);
            setStatus("SERVICING");
            setButtonIsDisabled(false)

          }

        }
      })

    }

  }, [idUser])

  var interval;
  useEffect(async () => {
    interval = setInterval(async () => {
      if (idCompany) {
        var result = await proximityModuleApi.get("/Proximity/CurrentLine", {
          params: {
            idCompany: idCompany
          }
        });

        if (result && result.data && result.data.content >= 0) {
          setPeopleInLine(result.data.content);
          setIsLoading(false)
        }

        var minutesWaiting = await proximityModuleApi.get("/Proximity/MinutesWaiting", {
          params: {
            idCompany: idCompany,
            date: date,
            timeZone: timeZone
          }
        });
        if (minutesWaiting && minutesWaiting.data && minutesWaiting.data.content >= 0) {
          setTimeWaiting(minutesWaiting.data.content);
          setIsLoading(false)
        }

        var minutes = await proximityModuleApi.get("/Proximity/MinutesServicing", {
          params: {
            idCompany: idCompany,
            date: date,
            timeZone: timeZone
          }
        });
        if (minutes && minutes.data && minutes.data.content) {
          setMinutesServicing(minutes.data.content);
          setIsLoading(false)
        }
        setIsLoading(false)
      }

    }, 7000)

    var beingServed = await proximityModuleApi.get("/Proximity/BeingServed", {
      params: {
        idCompany: idCompany
      }
    });
    if (beingServed && beingServed.data && beingServed.data.content) {
      setPeopleBeingServed(beingServed.data.content);
      setIsLoading(false)
    }

  }, [idUser])

  var historyInterval;
  useEffect(async () => {
    if (idUser) {
      historyInterval = setInterval(async () => {
        var historyList = await proximityModuleApi.get("/Proximity/GetHistory", {
          params: {
            idUser: idUser,
            idCompany: idCompany,
            date: date,
            timeZone: timeZone
          }
        });
        if (historyList && historyList.data && historyList.data.content) {
          var a = historyList.data.content.map(({ date, action }) => {
            var objToReturn = {};
            objToReturn.date = moment(date).local().format("hh:mm A")
            objToReturn.action = action;
            return objToReturn
          })

          setHistory(a);
          setIsLoading(false)
        }

      }, 7000)
    }

  }, [idUser])

  useEffect(() => {
    return () => {
      clearInterval(interval)
      clearInterval(historyInterval)

    };
  }, [])

  var callNextMethod = () => {
    try {
      proximityModuleApi.get('/Proximity/CallCustomer/', {
        params: {
          idUser: idUser,
          idCompany: idCompany,
          date: moment().format()
        }
      }).then(async (res) => {
        if (res && res.status == 200) {
          var id = res.data.content;
          setIdService(id)
          setStopServicing(true);
          setReCall(true);
          setNoShow(true);
          setCall(false);
          setStatus("SERVICING");
          setButtonIsDisabled(false)
          if (peopleInLine >= 0) {
            setPeopleInLine(peopleInLine - 1)
            setPeopleBeingServed(peopleBeingServed + 1)
          }
          setIsLoading(false)

        }

      })

    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  var reCallNextMethod = () => {
    try {
      proximityModuleApi.get('/Proximity/ReCallLastCustomer', {
        params: {
          idCompany: idCompany
        }
      })

      setAlertReCall(true)
    } catch (err) {
      setAlertReCall(false)
      console.log(err)
    }
  }

  var stopServicingMethod = () => {
    try {
      proximityModuleApi.get('/Proximity/StopServicing', {
        params: {
          idService: idService,
          idUser: idUser,
          idCompany: idCompany,
          date: moment().format()
        }
      }).then(async (res) => {
        setStopServicing(false);
        setCall(true);
        setButtonIsDisabled(true)
        setStatus("WAITING NEXT");

        if (peopleInLine >= 0) {
          setPeopleBeingServed(peopleBeingServed - 1)

        }
        setIsLoading(false)
      })
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  var noShowMethod = () => {
    try {
      proximityModuleApi.delete('/Proximity/NoShow', {
        params: {
          idService: idService,
          idUser: idUser,
          idCompany: idCompany,
          date: moment().format()
        }
      }).then(async (res) => {
        setCall(true);
        setStopServicing(false);
        setButtonIsDisabled(true)
        setStatus("WAITING NEXT");
        setIsLoading(false)
        setPeopleBeingServed(peopleBeingServed - 1)

        if (peopleInLine == 0) {
          setPeopleInLine(0)
          setTimeWaiting(0)
        }
        else {
          setPeopleInLine(peopleInLine - 1)
        }


      })
    }
    catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const toggleMenu = () => {
    setIsShowingFullscreen(!isShowingFullscreen)
    dispatch(toogleSideMenu(!props.isVisible))

  }

  const toggleHeader = () => {
    setIsShowingFullscreen(!isShowingFullscreen)
    dispatch(toogleHeader(!props.isHeaderVisible))
  }

  return (
    <>

      {!isShowingFullscreen &&
        <div className="fullscreen no-show">
          <div className="float-right" onClick={() => {
            toggleMenu()
            toggleHeader()
          }}  >
            <img className="fullscreen-img" src="/images/fullscreen-icon.png"></img>
            <span > Fullscreen</span>
          </div>
        </div>
      }


      {isShowingFullscreen &&
        <div className="fullscreen no-show">
          <div className="float-right" onClick={() => {
            toggleMenu()
            toggleHeader()
          }} >
            <img className="fullscreen-img" src="/images/fullscreen-icon.png"></img>
            <span>
              Exit
              </span>
          </div>
        </div>

      }

      <div className="line-container">
        <div className=" item user-item">
          <UserBox status={status} minutesServicing={minutesServicing} />
        </div>

        <div className=" item line-item">
          <CurrentLineBox peopleInLine={peopleInLine} peopleBeingServed={peopleBeingServed} timeWaiting={timeWaiting} />

        </div>

        <div className=" item actions-item">
          <BoxContainer>
            <div className="box-title">ACTIONS </div>
            {call && <button type="button" className="call-button" onClick={callNextMethod} >Call next person </button>}
            {stopServicing && <button type="button" className="call-button stop" onClick={stopServicingMethod}  >Stop Servicing </button>}
            {reCall && <button type="button" className="call-button" onClick={reCallNextMethod} disabled={buttonIsDisabled} >Re-call person </button>}
            {noShow && <button type="button" className="call-button" onClick={noShowMethod} disabled={buttonIsDisabled}>No show </button>}

          </BoxContainer>
        </div>

        <div className=" item history-item">
          <HistoryBox history={history} />
        </div>

        {
          alertReCall && <Alert message={"You call again."} onClose={() => { setAlertReCall(false) }} />
        }

        {
          isLoading && (
            <div className="loading-container global-form-column">
              <img src="/images/Loading_1.svg" />
              <label className="loading">Loading...</label>
            </div>
          )
        }

      </div>

    </>
  )
}

export default connect(state => ({
  isVisible: state.appearance.isVisible,
  isHeaderVisible: state.appearance.isHeaderVisible
}))(LineControl)