import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Input, message, Row, Space, Switch, Tabs } from 'antd'
import { configurationModuleApi } from '../../../../services/api';
const { TabPane } = Tabs;

export default function ProfileDetail(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [model, setModel] = useState(props.model);
    const modulesDef = [
        {
            key: 'settings',
            name: 'Settings',
            features: [
                {
                    key: 'users',
                    name: 'Users',
                    permissions: [
                        {
                            key: 'view',
                            name: 'View'
                        },
                        {
                            key: 'add',
                            name: 'Add'
                        },
                        {
                            key: 'edit',
                            name: 'Edit'
                        },
                        {
                            key: 'remove',
                            name: 'Remove'
                        }
                    ]
                },
                {
                    key: 'profiles',
                    name: 'Profiles',
                    permissions: [
                        {
                            key: 'view',
                            name: 'View'
                        },
                        {
                            key: 'add',
                            name: 'Add'
                        },
                        {
                            key: 'edit',
                            name: 'Edit'
                        },
                        {
                            key: 'remove',
                            name: 'Remove'
                        }
                    ]
                },
                {
                    key: 'configuration',
                    name: 'Configuration',
                    permissions: [
                        {
                            key: 'view',
                            name: 'View'
                        },
                        {
                            key: 'add',
                            name: 'Add'
                        },
                        {
                            key: 'edit',
                            name: 'Edit'
                        },
                        {
                            key: 'remove',
                            name: 'Remove'
                        }
                    ]
                },
                {
                    key: 'clients',
                    name: 'Clients/Subcompanies',
                    permissions: [
                        {
                            key: 'view',
                            name: 'View'
                        },
                        {
                            key: 'add',
                            name: 'Add'
                        },
                        {
                            key: 'edit',
                            name: 'Edit'
                        },
                        {
                            key: 'remove',
                            name: 'Remove'
                        }
                    ]
                }
            ]
        },
        {
            key: 'systems',
            name: 'Systems',
            features: []
        },
        {
            key: 'reports',
            name: 'Reports',
            features: []
        },
        {
            key: 'expenses',
            name: 'Expenses',
            features: []
        }
    ];

    useEffect(() => {
        let modules = {};

        model.Modules && model.Modules.map((m) => {
            modules[m.name] = {};
            m.Features && m.Features.map((f) => {
                let feature = {}
                f.permissions && f.permissions.map((p) => {
                    feature[p] = true;
                });
                modules[m.name][f.name] = feature;
            });
        });

        form.setFieldsValue({
            name: model.name,
            Modules: modules
        });

        verifyCheckedAll(true);
    }, [model])

    const handleSave = (data) => {
        setIsLoading(true);

        delete data['allPermissions'];
        let modules = [];

        Object.keys(data.Modules).map((m) => {
            let module = { name: m, Features: [] };
            delete data.Modules[m]['all'];
            Object.keys(data.Modules[m]).map((f) => {
                let feature = { name: f, permissions: [] };
                Object.keys(data.Modules[m][f]).map((p) => {
                    if (data.Modules[m][f][p]) {
                        feature.permissions.push(p);
                    }
                });
                module.Features.push(feature);
            });
            modules.push(module);
        });

        data.Modules = modules;

        if (model._id) {
            configurationModuleApi.put('/account/EditProfile?id=' + model._id, data)
                .then((res) => {
                    setModel(res.data.content);
                    message.success("Profile updated successfully");
                })
                .catch(() => {
                    message.error("Something went wrong!");
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            configurationModuleApi.post('/account/PostProfile', data)
                .then((res) => {
                    setModel(res.data.content);
                    message.success("Profile created successfully");
                })
                .catch(() => {
                    message.error("Something went wrong!");
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleRemove = () => {
        setIsLoading(true);
        configurationModuleApi.delete('/account/DeleteProfile?id=' + model._id)
            .then((res) => {
                handleOnClose();
                message.success("Profile removed successfully")
            })
            .catch(() => {
                message.error("Something went wrong!");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleOnClose = () => {
        props.onClose();
    }

    const toggleAll = (checked, key) => {
        if (key) {
            let module = modulesDef.find(x => x.key == key);
            let value = { Modules: { [module.key]: {} } };

            module.features.map((feature) => {
                value['Modules'][module.key][feature.key] = {};
                feature.permissions.map((permission) => {
                    value['Modules'][module.key][feature.key][permission.key] = checked;
                });
            });

            form.setFieldsValue(value);
            verifyCheckedAll();
        } else {
            let value = { Modules: {} };

            modulesDef.map((module) => {
                value['Modules'][module.key] = { all: checked };
                module.features.map((feature) => {
                    value['Modules'][module.key][feature.key] = {};
                    feature.permissions.map((permission) => {
                        value['Modules'][module.key][feature.key][permission.key] = checked;
                    });
                });
            });

            form.setFieldsValue(value);
        }
    }

    const verifyModuleCheckedAll = (checked, key) => {
        if (checked) {
            let module = modulesDef.find(x => x.key == key);
            let values = form.getFieldsValue();
            let allChecked = true;

            module.features.map((feature) => {
                feature.permissions.map((permission) => {
                    if (!values['Modules'][module.key][feature.key][permission.key]) {
                        allChecked = false;
                    }
                });
            });

            form.setFieldsValue({
                Modules: {
                    [key]: { all: allChecked }
                }
            });

            verifyCheckedAll();
        } else {
            form.setFieldsValue({
                allPermissions: false,
                Modules: {
                    [key]: { all: false }
                }
            });
        }
    }

    const verifyCheckedAll = (checkModule) => {
        let allChecked = true;
        let values = form.getFieldsValue();

        modulesDef.map((module) => {
            module.features.map((feature) => {
                feature.permissions.map((permission) => {
                    if (!values['Modules'][module.key][feature.key][permission.key]) {
                        allChecked = false;
                    }
                });
            });

            if(checkModule){
                verifyModuleCheckedAll(allChecked, module.key);
            }
        });

        form.setFieldsValue({
            allPermissions: allChecked
        });
    }

    return (
        <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={handleSave}
        >
            <Card bordered={false} style={{ maxWidth: "800px" }}>
                <Row className="mb-3" gutter={[10, 10]} align="bottom">
                    <Col span={24} lg={12}>
                        <Form.Item
                            className="mb-0"
                            label="Name"
                            name="name"
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={12}>
                        <Form.Item className="mb-0" extra="Selecting all permissions does not make this profile an Admin.">
                            <Space align="end">
                                <Form.Item className="mb-0" name="allPermissions" valuePropName="checked">
                                    <Switch onChange={(checked) => toggleAll(checked)} />
                                </Form.Item>
                                <label className="label-switch">All permissions</label>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <Tabs className="ant-tabs-flat" defaultActiveKey="settings">
                    {modulesDef && (modulesDef.map((module) => {
                        return (
                            <TabPane key={module.key} tab={module.name} forceRender={true}>
                                <Space align="end">
                                    <Form.Item className="mb-0" name={['Modules', module.key, 'all']} valuePropName="checked">
                                        <Switch onChange={(checked) => toggleAll(checked, module.key)} />
                                    </Form.Item>
                                    <label className="label-switch">All permissions</label>
                                </Space>
                                {module.features && (module.features.map((feature) => {
                                    return (
                                        <Form.Item className="mt-2 mb-0" key={feature.key} label={feature.name}>
                                            <Row gutter={[10, 10]}>
                                                {feature.permissions && (feature.permissions.map((permission) => {
                                                    return (
                                                        <Col key={permission.key} span={12} md={6}>
                                                            <Space align="end">
                                                                <Form.Item className="mb-0" name={['Modules', module.key, feature.key, permission.key]} valuePropName="checked">
                                                                    <Switch onChange={(checked) => verifyModuleCheckedAll(checked, module.key)} />
                                                                </Form.Item>
                                                                <label className="label-switch">{permission.name}</label>
                                                            </Space>
                                                        </Col>
                                                    )
                                                }))}
                                            </Row>
                                        </Form.Item>
                                    )
                                }))}
                            </TabPane>
                        )
                    }))}
                </Tabs>
            </Card>
            <Space className="form-actions">
                <Button type="secondary" onClick={handleRemove} disabled={!model._id} loading={isLoading}>Remove</Button>
                <Button type="primary" htmlType="submit" loading={isLoading} >Save</Button>
            </Space>
        </Form>
    );
}