import React from 'react'
import RecoveryPasswordComponent from "../../components/login-form/recoveryPassword"
import { useParams } from "react-router-dom";

export default function RecoveryPassword() {
    var { token } = useParams()

    return (
        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%', background: 'rgba(0, 0, 0, 0.9)', backdropFilter: 'blur(25px)' }}>
            <RecoveryPasswordComponent token={token} />
        </div>
    )
}
