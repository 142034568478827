import React, { Component } from 'react'
import { proximityModuleApi } from "../../../services/api";
import "./printGatheringEvent.scss";
import { Icon, InlineIcon } from '@iconify/react'
import calendar4Event from '@iconify/icons-bi/calendar4-event';
import textBulletListSquare20Regular from '@iconify/icons-fluent/text-bullet-list-square-20-regular';
import checksBold from '@iconify/icons-ph/checks-bold';
import print48Filled from '@iconify/icons-fluent/print-48-filled';
import edit2Fill from '@iconify/icons-eva/edit-2-fill';
import checkSquare from '@iconify/icons-bi/check-square';
import checkSolid from '@iconify/icons-fa-solid/check';
import accountMultipleCheck from '@iconify/icons-mdi/account-multiple-check';
import bxX from '@iconify/icons-bx/bx-x';
import moment from "moment";
var header = ["name", "email", "phone", "people", "notified", "confirmed", "attended"]
var selectedAux = [];
var map = undefined;
var totalSelected = 0;
var totalRegistred = 0;
var totalAttendees = 0;
var contaa = 0;
export default class PrintGatheringEvent extends Component {
    constructor(props) {
        super(props);
        this.count = 0;
        this.state = { selected: [] };
    }
    getPeopleEvent() {
        proximityModuleApi.get('/gathering/getPeopleEvent', {
            params: {
                idEvent: this.props.event["_id"]
            }
        }).then((result) => {

            selectedAux = result.data.filter(x => x.selected).map(({ name, phoneNumber, email, peopleNumber, notified, confirmed, attended }) => {
                return { name, email, phoneNumber, peopleNumber, notified, confirmed, attended }
            })
            this.setState({ selected: selectedAux })

            totalRegistred = result.data.reduce((sum, element) => {
                return sum + (element.peopleNumber + 1)
            }, 0);
            totalAttendees = selectedAux.reduce((sum, element) => {
                return sum + element.attended
            }, 0);

            totalSelected = selectedAux.reduce((sum, element) => {
                return sum + (element.peopleNumber + 1)
            }, 0);

        }).catch((err) => {
            console.log(err);
        });
    }

    // componentDidUpdate(props) {
    //     if (this.props.event) {
    //         this.getPeopleEvent()
    //     }
    // }

    componentDidMount() {
        if (this.props.event) {
            this.getPeopleEvent()
        }
    }

    render() {
        const { selected } = this.state;
        return (

            // <div style={{ width: "calc(100%-90px)", backgroundImage: `url("/images/container-background.png")`, backgroundRepeat: 'repeat-y', backgroundSize: "", padding: "45px" }}>
            <div className="print-gathering-event-container">
                <div className="print-gathering-logo-box">
                    <img src="images/loginLogo.png" />
                </div>

                <div className="pagereak"></div>


                <div className="print-gathering-info">
                    <div className="print-gathering-box print-gathering-date-location">
                        <div className="icon">
                            <Icon icon={calendar4Event} style={{}} />
                        </div>
                        <div className=" info">
                            <div className="font-weight">
                                {this.props.event && this.props.event.date && moment(this.props.event.date).format("dddd, MMM d, YYYY @ hh:mm a")}
                            </div>
                            <div >
                                {this.props.event && (this.props.event.location + " for " + this.props.event.limit + " people")}

                            </div>
                        </div>
                    </div>
                    <div className="print-gathering-box print-gathering-default-box">
                        <div className="icon-container">
                            <div className="icon-top">
                                <Icon icon={textBulletListSquare20Regular} />
                            </div>
                            <div className="info-label font-weight">
                                Registered
                            </div>
                        </div>

                        <div className="info">
                            <div className="info-number">
                                {totalRegistred}
                            </div>
                        </div>
                    </div>
                    <div className="print-gathering-box print-gathering-default-box">
                        <div className="icon-container">
                            <div className="icon-top smaller">
                                <Icon icon={checkSquare} />
                            </div>
                            <div className="info-label mt-5 font-weight">
                                Selected
                            </div>
                        </div>

                        <div className="info">
                            <div className="info-number">
                                {totalSelected}
                            </div>
                        </div>
                    </div>
                    <div className="print-gathering-box print-gathering-default-box">
                        <div className="icon-container">
                            <div className="icon-top smaller">
                                <Icon icon={checksBold} />
                            </div>
                            <div className="info-label mt-5 font-weight">
                                Attended
                            </div>
                        </div>

                        <div className="info">
                            <div className="info-number">
                                {totalAttendees}
                            </div>
                        </div>
                    </div>


                </div>
                <div className="print-gathering-grid">
                    <div className="row-header">
                        {
                            header && header.map((element, index) => {
                                return <div className="col-header" key={Math.random()}><div className="col-content">{element}</div></div>
                            })

                        }

                    </div>

                    {
                        selected.map((element, i) => {
                            contaa++
                            return (
                                <>
                                    {
                                        i != 0 && i % 17 === 0 ? <span className="pagebreak"></span> : ""
                                    }
                                    <div className={"row"}>

                                        {

                                            Object.keys(element).map(key => {
                                                var objectReturned = null;
                                                if (key == "notified" || key == "confirmed") {
                                                    objectReturned = element[key] ? <Icon className="grid-icon" icon={checkSolid} /> : <div className="empty-square">  </div>
                                                } else if (key == "attended") {
                                                    objectReturned = !isNaN(element[key]) ? element[key] : <div>  </div>
                                                }
                                                else {
                                                    objectReturned = key == "peopleNumber" ? (element[key] + 1).toString() : element[key].toString()
                                                }

                                                return (

                                                    <div className="col" key={Math.random()}>
                                                        <div className="col-content">
                                                            {objectReturned}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>

                                </>
                            )
                        })

                    }



                </div>
            </div>
        );
    }


}
