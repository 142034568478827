import React, { useState } from 'react'
import LineControl from "../proximity/lineControl/lineControl";
import ContactCommunication from "../proximity/contactCommunication/contactCommunication";
import GatheringEvent from "../gathering/gatheringEvent/gatheringEvent";
import DownloadQRCodes from "../proximity/downloadQRCodes/downloadQRCodes";
import Layout from '../../components/layout/layout';

export default function Proximity() {
    const [componentToRender, setComponentToRender] = useState(<LineControl />);
    const [title, setTitle] = useState("Line Control");
    const menu = [
        { name: "Line Control" },
        { name: "Communication" },
        { name: "Access URLs" },
        { name: "Gathering Event" },
    ];
    async function changeActive(componentName) {
        switch (componentName) {
            case "Gathering Event":
                await setComponentToRender(<GatheringEvent />)
                setTitle("Gathering Event");
                break;
            case "Line Control":
                await setComponentToRender(<LineControl />)
                setTitle("Line Control");
                break;
            case "Communication":
                await setComponentToRender(<ContactCommunication />)
                setTitle("Communication");
                break;
            case "Access URLs":
                await setComponentToRender(<DownloadQRCodes />)
                setTitle("Access URLs");
                break;
        }
    }

    return (
        <Layout module="proximity module" defaultActive={["Line Control"]} menu={menu} title={title} onChangeActive={(item) => { changeActive(item) }}>
            {componentToRender}
        </Layout>
    )
}