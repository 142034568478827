import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { connect } from "react-redux";

import markerSafe from '../../assets/markerSafe.png';
import markerMinor from '../../assets/markerMinor.png';
import markerMajor from '../../assets/markerMajor.png';
import markerCritical from '../../assets/markerCritical.png';

import "./map.scss"

const MapContainer = (props) => {
    const mapStyles = {
        width: props.width,
        height: props.height,
        position: 'relative'
    };
    const google = window.google;
    let zoom = props.zoom ? props.zoom : 11;
    let systems = props.systems ? props.systems : [];
    let markers = [];
    let iconUrl = null;
    let center = {
        lat: 0,
        lng: 0
    };

    if (props.showAlerts) {
        systems = props.store.alerts.systems ? props.store.alerts.systems : systems;
    }

    if (systems[0] && systems[0].sites && systems[0].sites[0]) {
        center.lat = systems[0].sites[0].address.latitude;
        center.lng = systems[0].sites[0].address.longitude;
    }

    systems.forEach(system => {
        iconUrl = markerSafe
        // TODO: Change marker type according alert status
        // switch (system) {
        //     case value:
        //         iconUrl = markerMinor
        //         break;

        //     default:
        //         break;
        // }
        system.sites?.forEach(site => {
            markers.push(site);
        });
    });

    const onMarkerClick = (marker) => {
        if(props.onMarkerClick){
            props.onMarkerClick(marker);
        }
    }

    return (
        <Map
            google={props.google}
            zoom={zoom}
            className={'map ' + props.className}
            style={mapStyles}
            fullscreenControl={false}
            center={center}
        >
            {
                markers && markers.map(marker => {
                    return (
                        <Marker
                            key={marker._id}
                            // icon={url= "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
                            // icon={{
                            //     url: iconUrl,
                            //     anchor: new google.maps.Point(5, 58),
                            // }}
                            position={{ lat: marker.address.latitude, lng: marker.address.longitude }}
                            onClick={() => onMarkerClick(marker)}
                        />
                    )
                })
            }
        </Map>
    );
}
export default connect(state => ({
    store: state
}))(GoogleApiWrapper({
    apiKey: 'AIzaSyBD8tSFPTT6gdvLpao7bZYYE5WEwnhQmgk'
})(MapContainer));