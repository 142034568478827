import React, { useState, useEffect } from 'react';
import "./alert.scss"
import { Icon, InlineIcon } from '@iconify/react'
import checkIcon from '@iconify/icons-fa-solid/check';
import bxCheckCircle from '@iconify/icons-bx/bx-check-circle';
import bxXCircle from '@iconify/icons-bx/bx-x-circle';

export default function Alert({ onClose = () => { }, children, ...props }) {
    return (
        <>
            <div className="modal ">
                <div className="alert-box margin-auto" name='alertButton'>
                    <img className="close-icon margin-auto" src="/images/close-icon.png" onClick={onClose}></img>
                    <div className="alert-content">
                        {
                            props.message && 
                                (
                                    <>
                                        {

                                            props.icon && props.icon == "success" 
                                            ? 
                                                (
                                                    <div className="alert-custom-icon">
                                                        <Icon icon={bxCheckCircle} color={props.primaryColor} width="100px" height="100px"/>
                                                    </div>
                                                ) 
                                            : 
                                                props.icon && props.icon == "error" 
                                                ? 
                                                    (
                                                        <div className="alert-custom-icon">
                                                            <Icon icon={bxXCircle} color="#FF5B5B" width="100px" height="100px"/>
                                                        </div>
                                                    ) 
                                                : 
                                                    <img className="alert-triangle-icon margin-auto" src="/images/alert-triangle-icon.png"></img>
                                        }
                                        <div className="message margin-auto">
                                            <span>
                                                {props.message}
                                            </span>
                                        </div>
                                    </>
                                )
                        }
                        {!props.message && children}
                    </div>

                    <button type="button" className="alert-button margin-auto submit" onClick={onClose} > OK</button>
                </div>
            </div>

        </>
    )

}
