import styled from "styled-components";

export const FormContactTracing = styled.div`

   form{
    widht: 100%;
    border-radius: 10px;
    flex-wrap: wrap;
    padding-left:30px;
  
   }
   
   .slides-align{
       display:flex;
     
   }
  
   .slide{
    margin-top:40px;
   
    
   }
    .form-row{
        display:flex;
        width:100%;
        flex-direction:row;
        flex: none; 
        margin-top:15px;
    }
    
    .form-group{
        width:90%;
        margin-top:14px;
        
    }

    label{
        font-size: 20px;
        line-height: 150%;
        color: #4F4F4F;
    }

    input{
        margin-top: 5px;
        height:44px;
        border-radius:6px;
        width: 100%;
        padding-left: 5px; 
    }

    .checkbox{
        height:12px;
        width: auto;
        margin-left: 10px;
        cursor:pointer;
    }

    .label-checkbox{
        cursor:pointer;
    }


    button.submit{
        background-color: #0065BD;
        height: 60px;
        color: #FFFFFF;
        border-radius: 6px;
        cursor: pointer;
        margin-top: 95px;
    }


`;
