import React, { useState, memo, useRef, useEffect } from 'react'
import { useReactToPrint } from 'react-to-print';
import "./gatheringManagement.scss";
import { Icon, InlineIcon } from '@iconify/react'
import calendar4Event from '@iconify/icons-bi/calendar4-event';
import textBulletListSquare20Regular from '@iconify/icons-fluent/text-bullet-list-square-20-regular';
import checksBold from '@iconify/icons-ph/checks-bold';
import print48Filled from '@iconify/icons-fluent/print-48-filled';
import edit2Fill from '@iconify/icons-eva/edit-2-fill';
import checkSquare from '@iconify/icons-bi/check-square';
import bxX from '@iconify/icons-bx/bx-x';
// const { totalAttendees, setTotalAttendees } = useGathering();
import { proximityModuleApi } from "../../../services/api";
import moment from "moment";
import { Form } from "@unform/web";
import Input from "../../../components/form/input/input";
import DatePicker from "../../../components/form/datepicker/datepicker";
import Select from "../../../components/form/select/select"
import SelectPeople from "../../../components/gatheringManagement/selectPeople/selectPeople"
import PrintGatheringEvent from "../../../components/gatheringManagement/printGatheringEvent/printGatheringEvent"
import ConfirmationAlert from "../../../components/alert/confirmationAlert"
import { configurationModuleApi } from "../../../services/api";

const pageStyle = `
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

function GatheringManagement(props) {
    const selectPeopleRef = useRef(null);
    const printGatheringRef = useRef();
    const editGatheringEventRef = useRef(null);
    const [refresh, setRefresh] = useState(false)
    const [event, setEvent] = useState(props.event)
    const [totalRegistred, setTotalRegistred] = useState(0)
    const [totalSelected, setTotalSelected] = useState(0)
    const [totalAttendees, setTotalAttendees] = useState(0)
    const [isEditing, setIsEditing] = useState(false)
    const [alertConfirmation, setAlertConfirmation] = useState(undefined)

    const [location, setLocation] = useState([])
    const [defaultValue, setDefaultValue] = useState({})
    useEffect(() => {
        configurationModuleApi.get("/configuration/GetPlaces").then((res) => {
            var map = res.data.content.map(({ _id, name }) => {
                return ({ label: name, value: _id })
            })
            setLocation(map)
            setDefaultValue({ label: props.event.location.name, value: props.event.location._id })
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        setTimeout(() => {
            let attendeesArray = [];
            document.querySelectorAll(".people-attended-display").forEach((ele, i) => {
                attendeesArray.push(parseInt(ele.innerHTML))
            })
            var sumResult = attendeesArray.reduce((sum, current) => {
                return sum + current;
            }, 0)
            console.log("sumResult", sumResult)
            setTotalAttendees(sumResult)
        }, 500)

    }, [])

    const handlePrint = useReactToPrint({
        content: () => printGatheringRef.current,

    });

    const UpdateTotalRegistred = (total) => {
        setTotalRegistred(total);
    }

    const UpdateTotalSelected = (total) => {

        setTotalSelected(total);
    }

    const UpdateTotalAttendees = (total) => {

        setTimeout(() => {
            let attendeesArray = [];
            document.querySelectorAll(".people-attended-display").forEach((ele, i) => {
                attendeesArray.push(parseInt(ele.innerHTML))
            })
            var sumResult = attendeesArray.reduce((sum, current) => {
                return sum + current;
            }, 0)
            document.querySelector(".attendees-display").innerHTML = sumResult
        }, 200)
    }


    const toggleDetail = () => {
        props.toggleDetail()
    }

    const fillSelectedFromParent = () => {
        selectPeopleRef.current.fillSelectedFromParent()
    }

    const editGatheringEventSubmit = () => {
        var filter = editGatheringEventRef.current.getData();
        filter.time = moment(filter.date).format('hh:mm A')
        filter.date = moment(filter.date).format("YYYY-MM-DDTHH:MM:ss.SSSZ");
        filter.withReset = true
        proximityModuleApi.put('/gathering/editGatheringEvent', filter)
            .then((response) => {
                if (response.status === 200) {
                    setIsEditing(false);
                    setEvent(response.data)
                    closeConfirmationAlert()
                }

            })
            .catch(err => { console.log(err) })
    }

    const closeConfirmationAlert = () => {
        setAlertConfirmation(undefined)
    }

    const openConfirmationAlert = () => {
        setAlertConfirmation(true)
    }

    const updatePrint = () => {
        setRefresh(true)
    }

    return (
        <div className="gathering-management-conatiner">
            {
                alertConfirmation && <ConfirmationAlert
                    doAction={editGatheringEventSubmit}
                    closeAlert={closeConfirmationAlert}
                    message={"If you edit this event you'll lost all notified and confirmed informations, do you really want to edit this event?"} />
            }
            <div style={{ display: "none" }}>
                <PrintGatheringEvent refresh={refresh} event={event} ref={printGatheringRef} />
            </div>
            <div className="gathering-management-header">
                {
                    isEditing && (
                        <div className="gathering-edit-event-conatiner" >
                            <Form className="edit-gathering-form" ref={editGatheringEventRef} onSubmit={editGatheringEventSubmit}>
                                <Input isHidden="true" name="_id" is defaultValue={props.event && props.event._id} />
                                <Input name="name" defaultValue={props.event && props.event.name} label="Event name" />
                                <Select
                                    label="Location"
                                    name="location"
                                    defaultValue={defaultValue}
                                    options={location}
                                    classNamePrefix="select" />
                                <Input name="limit" defaultValue={props.event && props.event.limit} label="Limit" />
                                <DatePicker showTimeSelect dateFormat="MM/dd/yyyy hh:mm a" defaultValue={props.event && props.event.date} name="date" label="Date" />
                                <button onClick={() => { setIsEditing(false) }} className="management-btn management-btn-secondary" type="button">Cancel</button>
                                <button className="management-btn management-btn-primary" onClick={() => { openConfirmationAlert() }} type="button">Save</button>
                            </Form>
                        </div>
                    )
                }
                {
                    !isEditing && (
                        <>
                            <div className="event-info">
                                <div className="date-and-location">
                                    <div className="icon">
                                        <Icon icon={calendar4Event} style={{}} />
                                    </div>
                                    <div className=" info date-and-location-info">
                                        <div className="font-weight">
                                            {event && event.date && moment(event.date).format("ddd, MMM DD, YYYY @ hh:mm A")}
                                        </div>
                                        <div >
                                            {event && event.location ? (event.location.name) + " for " + event.limit + " people" : " for " + event.limit + " people"}
                                        </div>
                                    </div>
                                </div>
                                <div className="registred">
                                    <div className="icon">
                                        <Icon icon={textBulletListSquare20Regular} />
                                    </div>
                                    <div className="info">
                                        <div className="info-label">
                                            People registered
                                    </div>
                                        <div className="info-number">
                                            {totalRegistred}
                                        </div>
                                    </div>
                                </div>
                                <div className="registred">
                                    <div className="icon">

                                        <Icon icon={checkSquare} />

                                    </div>
                                    <div className="info">
                                        <div className="info-label">
                                            People selected
                                        </div>
                                        <div className="info-number">
                                            {totalSelected}
                                        </div>
                                    </div>
                                </div>
                                <div className="attendees">
                                    <div className="icon">
                                        <Icon icon={checksBold} />
                                    </div>

                                    <div className="info">
                                        <div className="info-label">
                                            Attendees
                                        </div>
                                        <div className="info-number attendees-display">
                                            {totalAttendees}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="management-actions">
                                <button onClick={() => { fillSelectedFromParent(); }} className="management-btn management-btn-primary"> Fill Random</button>
                                {/* <button onClick={() => { sendNotify() }} className="management-btn  management-btn-primary"> Notify</button> */}

                                <div className="rounded-icon icon-bordered " onClick={() => { setIsEditing(true) }}>
                                    <Icon icon={edit2Fill} />
                                </div>
                                <div className="rounded-icon background-filled">
                                    <Icon onClick={handlePrint} icon={print48Filled} />
                                </div>
                            </div>

                        </>
                    )
                }
            </div>
            <SelectPeople updatePrint={updatePrint} totalAttendees={UpdateTotalAttendees} totalSelected={UpdateTotalSelected} totalRegistred={UpdateTotalRegistred} ref={selectPeopleRef} event={event} />

        </div>

    )
}
export default GatheringManagement