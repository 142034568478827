import React, { useEffect, useState } from 'react';
import { Row, Card, Col, Tag, Drawer, Modal, Form, Input, Button } from "antd";
import { networkModuleApi } from '../../../services/api';
import Loading from '../../../components/loading/loading'
import DomainDetail from './domainDetails/domainDetails';

export default function Domains() {
    const [form] = Form.useForm();
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState(null);
    const [showDrawer, setShowDrawer] = useState(false)
    const [showModal, setShowModal] = useState(false);

    useEffect(async () => {
        setIsLoading(true);
        getDomains();
        setIsLoading(false);
    }, [])

    const getDomains = async () => {
        const result = await networkModuleApi.get('/Domain/All');
        setList(result.data.content);
    }

    const getEntriesStatus = (entries) => {
        let status = 1;
        entries.map((e) => {
            if (e.status === 0) {
                status = 0;
            }
        })
        return status;
    }

    const handleAddDomain = async (data) => {
        networkModuleApi.post('/Domain', data)
            .then(() => {
                handleModalClose();
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleDrawerClose = () => {
        setShowDrawer(false);
        getDomains();
    }

    const handleModalClose = () => {
        setShowModal(false);
        form.resetFields();
        getDomains();
    }

    return (
        <>
            <Row gutter={[12, 12]} align="stretch">
                {
                    list.map((item) => {
                        return (
                            <Col key={item._id} span={6} style={{ maxWidth: '270px', minWidth: '270px' }}>
                                <Card
                                    className="medium c-pointer"
                                    title={item.url}
                                    bordered={false}
                                    style={{ height: '100%', minHeight: '94px', fontSize: 11 }}
                                    onClick={() => { setSelected(item); setShowDrawer(true); }}
                                >
                                    <Row gutter={[12]} align="center" style={{ marginBottom: 10 }}>
                                        <Col flex="70px">
                                            <Tag className="w-100" color={getEntriesStatus(item.Entries) ? 'success' : 'warning'}>{getEntriesStatus(item.Entries) ? 'OK' : 'Pending'}</Tag>
                                        </Col>
                                        <Col flex="auto">{getEntriesStatus(item.Entries) ? 'A entries' : 'A entry missing'}</Col>
                                    </Row>
                                    <Row gutter={[12]} align="center">
                                        <Col flex="70px">
                                            {item.ssl ? (<Tag className="w-100" color={item.sslStatus !== 0 ? 'success' : 'warning'}>{item.sslStatus !== 0 ? 'Active' : 'Pending'}</Tag>)
                                                : (<Tag className="w-100" color="warning">Inactive</Tag>)}
                                        </Col>
                                        <Col flex="auto">SSL</Col>
                                    </Row>
                                </Card>
                            </Col>
                        );
                    })
                }
                <Col key="add" span={6} style={{ maxWidth: '270px', minWidth: '270px' }}>
                    <Card className="add c-pointer" bordered={false} style={{ height: '100%', minHeight: '94px', width: '100%' }} onClick={() => setShowModal(true)}>
                        Add Domain
                    </Card>
                </Col>
            </Row>

            <Drawer
                visible={showDrawer}
                title="Domain Manage"
                mask={false}
                getContainer="#main-container"
                destroyOnClose={true}
                onClose={handleDrawerClose}
            >
                <DomainDetail model={selected} onClose={handleDrawerClose} />
            </Drawer>

            <Modal
                title="Add Domain"
                width="400px"
                visible={showModal}
                footer={null}
                destroyOnClose={true}
                getContainer="#main-container"
                onCancel={handleModalClose}
            >
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleAddDomain}
                >
                    <Form.Item label="Url" name="url" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>

                    <Row className="mt-2" gutter={[10]}>
                        <Col span={12}>
                            <Button className="w-100" type="secondary" onClick={handleModalClose}>Cancel</Button>
                        </Col>
                        <Col span={12}>
                            <Button className="w-100" type="primary" htmlType="submit" loading={isLoading}>Save</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            { isLoading && (<Loading />)}
        </>
    );
}