import React from 'react';
import QRCodeContact from '../../../components/QRCode/QRCodeContact';
import QRCodeLine from '../../../components/QRCode/QRCodeLine';
import QRCodeGathering from '../../../components/QRCode/QRCodeGathering';
import '../../../components/QRCode/QRCodeContact';
import "./downloadQRCodes.css"

export default function DownloadQRCodes() {


    return (
        <>
            <div className="display-flex content">
                <div>
                    <QRCodeLine
                        label="Line Management"
                        buttonLabel="Download PDF with instructions"
                        size="160"
                    />
                </div>
                <div>
                    <QRCodeContact
                        label="Contact Tracing"
                        buttonLabel="Download PDF with instructions"
                        size="160"
                    /></div>
                <div>
                    <QRCodeGathering
                        label="Gatherign Register"
                        buttonLabel="Download PDF with instructions"
                        size="160"
                    />
                </div>
            </div>
        </>
    )
}
