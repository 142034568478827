import styled from "styled-components";

export const Grid = styled.div`
    width:100%;
    font-size: 12px;
    margin-top: 1rem;
`;


export const Row = styled.div`
    display:flex;
    min-height: 45px;
    align-items:center;
    background-color: #ffffff;
    margin: 3px 0;
    border-radius: 10px;
    padding:10px;
    padding-left:24px;
    box-shadow:0px 3px 5px rgb(0 0 0 / 10%);
    .action-container{
        display: flex;
        justify-content: flex-end;

        @media(max-width: 1000px) {
            flex-direction: column;
            align-items: flex-end;
            button{
                width: 100%;
            }

            button + button{
                margin-top: 10px;
            }
        }

        button + button{
            margin-left: 10px;
        }
    }
    
`;

export const RowHeader = styled.div`
    display:flex;
    min-height: 45px;
    align-items:center;
    background-color: #f4f4f4;
    margin: 3px 0;
    border-radius: 10px;
    padding:10px;
    padding-left:24px;
    text-transform: capitalize;
    box-shadow:0px 3px 5px rgb(0 0 0 / 10%);
`;


export const Col = styled.div`
    flex: ${(props) => props.size};
    word-break: break-word;
    padding:5px;
    &:first-child{
        display: ${(props) => props.display};
    }
   
`;

export const ColHeader = styled.div`
    word-break: break-word;
    padding:5px;
    flex: ${(props) => props.size};
    &:first-child{
        display: ${(props) => props.display};
    }
    font-weight: 500;
    
`;
