import styled from "styled-components";

export const FormContainerRegisterGathering = styled.div`
   form{
   
    align-items: center;
    background: #FFFFFF;
    display:flex;
    padding:45px;
    flex-wrap: wrap;
    width: 400px;
   }

   .form-row{
        display:flex;
        width:100%;
        flex-direction:row;
    }
   

    .form-group{
     display:flex;
     flex: 1;
     flex-direction:column;
        margin-right:10px;
        padding:10px 0;
    }

    

    label{
        font-size: 13px;
        text-transform:capitalize;
    }

    input{
        background: #FBFBFB;
        margin-top: 5px;
        height:30px;
        border-radius:5px;
        width: 100%;
        padding-left: 5px; 
    }

    .checkbox{
        height:12px;
        width: auto;
        margin-left: 10px;
        cursor:pointer;
    }

    .label-checkbox{
        cursor:pointer;
    }

    textarea{
        margin-top: 5px;
        min-height:50px;
        border-radius:5px;
        width: 100%;
        padding-left: 5px; 
        resize:none;
    }

    button.submit{
        background-color:#0F5071;
        padding:30px 10px;
        color: #fff;
        border-radius: 5px;
        margin-right:10px;
        cursor: pointer;
        width:100%;
        margin-top:98px;
        font-weight:500;
        font-size:20px;
        text-transform:uppercase;
       
    }

    button.reset{
        padding:10px;
        border-radius: 5px;
        margin-right:10px;
        cursor: pointer;
        background-color:#fff;
        color: #0F5071;
        border: 1px solid #0F5071;

    }



    /* RESPONSIVE */

    @media (max-width: 800px) {
    .form-row{
        /* flex-direction:column; */
    }

    button.submit{
        width: calc(100% - 5px); 
        margin-right:0;
       
    }

    

    form{
        width: calc(100% - 90px);
        height: 100vh;
        padding-bottom:0;
        padding-top:0;
    }
}
    

`;
