import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";

import { configurationModuleApi } from "../../../services/api";
import { GetProfiles } from "../../../services/services";
import { Button, Drawer, message, Popconfirm, Space, Table } from 'antd';
import ProfileDetail from './profileDetail/profileDetail';

const Profiles = ({ store, dispatch }) => {
  const [selected, setSelected] = useState({});
  const [showDrawer, setShowDrawer] = useState(false);
  const [profiles, SetProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '',
      key: 'action',
      render: (text, item) => (
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <Popconfirm title="Do you really want remove this profile?" okText="Yes" cancelText="No" onConfirm={() => handleRemoveItem(item._id)}>
            <Button type="secondary">Remove</Button>
          </Popconfirm>
          <Button type="primary" onClick={() => handleDrawerOpen(item)}>Edit</Button>
        </Space>
      )
    }
  ];

  useEffect(async () => {
    await getProfiles();
  }, [])

  const getProfiles = async () => {
    setIsLoading(true);
    await GetProfiles()
      .then(data => {
        SetProfiles(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  if (store.configurations && store.configurations.profiles) {
    store.configurations.profiles.forEach(profile => {
      profiles.push(profile)
    });
  }
  
  const handleRemoveItem = (id) => {
    setIsLoading(true)
    configurationModuleApi.delete('/account/DeleteProfile?id=' + id)
      .then(async (res) => {
        await getProfiles();
        message.success("Profile removed successfully");
      })
      .catch(() => {
        message.error("Something went wrong!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleDrawerOpen = (item) => {
    setShowDrawer(true);
    setSelected(item);
  }

  const handleDrawerClose = async () => {
    setShowDrawer(false);
    setSelected({});
    await getProfiles();
  }

  return (
    <>
      <div className="text-right mb-1">
        <Button onClick={() => handleDrawerOpen({})}>Add Profile</Button>
      </div>
      <Table className="table-2" rowKey="_id" columns={columns} dataSource={profiles} pagination={false} loading={isLoading} />
      <Drawer
        visible={showDrawer}
        title="Profile Manage"
        mask={false}
        getContainer="#main-container"
        destroyOnClose={true}
        onClose={handleDrawerClose}
      >
        <ProfileDetail model={selected} onClose={handleDrawerClose} />
      </Drawer>
    </>
  )
}

export default connect(state => ({
  store: state
}))(Profiles);