import React from 'react'
import LoginForm from "../../components/login-form/login"

export default function Login() {
    return (
        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%', background: 'rgba(0, 0, 0, 0.85)', backdropFilter: 'blur(25px)' }}>
            <LoginForm />
        </div>
    )
}
