import React from 'react';
import "./customersInsideBox.scss"
import { Row, Col } from 'antd';

export default function CustomersInsideBox({ peopleInLine, peopleShareData }) {
    return (
        <>

            <div className="client-box-container"  >
                <div className="title-box">CUSTOMERS INSIDE</div>
                <Row gutter={[15, 15]}>
                    <Col xs={24}>
                        <Row align="middle" className="people-box blue">
                            <Col xs={20}>
                                <img className="" src="/images/people-in-line.png"></img>
                                <span className="font-blue">
                                    Total people
                                </span>
                            </Col>
                            <Col xs={4}>
                                <span className="people-number">{peopleInLine}</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24}>
                        <Row align="middle" className="people-box green">
                            <Col xs={20}>
                                <img src="/images/check.png"></img>
                                <span className="font-green">
                                    People shared their data
                                </span>
                            </Col>
                            <Col xs={4}>
                                <span className="people-number">{peopleShareData}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>



            </div>

        </>
    )

}

