import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Input, message, Row, Select, Space, Upload } from 'antd'
import { configurationModuleApi } from '../../../../services/api';
import { GetProfiles } from '../../../../services/services';
import { UserOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { getBase64 } from '../../../../utils/helpers';

export default function UserDetail(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [model, setModel] = useState(props.model);
    const [profiles, SetProfiles] = useState([]);
    const [isNew, setIsNew] = useState(false);
    const [file, setFile] = useState(undefined);

    useEffect(async () => {
        SetProfiles(await GetProfiles());
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            name: model.name,
            login: model.login,
            profile: model.idProfile,
            picture: model.picture
        });
        setFile(model.picture);
        form.resetFields(['password', 'password_confirm']);
        setIsNew(!model._id);
    }, [model])

    const handleSave = (data) => {
        setIsLoading(true);
        data.picture = file || data.picture;
        if (model._id) {
            configurationModuleApi.put('/account/EditUser?id=' + model._id, data)
                .then((res) => {
                    setModel(res.data.content);
                    message.success("User updated successfully");
                })
                .catch(() => {
                    message.error("Something went wrong!");
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            configurationModuleApi.post('/account/PostUser', data)
                .then((res) => {
                    setModel(res.data.content);
                    message.success("User created successfully");
                })
                .catch(() => {
                    message.error("Something went wrong!");
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleRemove = () => {
        setIsLoading(true);
        configurationModuleApi.delete('/account/DeleteUser?id=' + model._id)
            .then((res) => {
                handleOnClose();
                message.success("User removed successfully")
            })
            .catch(() => {
                message.error("Something went wrong!");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleOnClose = () => {
        props.onClose();
    }

    return (
        <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={handleSave}
        >
            <Card bordered={false} style={{ maxWidth: "600px" }}>
                <Form.Item className="mb-0" name="picture">
                    <Upload
                        accept=".jpg,.jpeg,.png"
                        listType="picture"
                        showUploadList={false}
                        beforeUpload={file => {
                            getBase64(file, img => setFile(img));
                            return false;
                        }}
                    >
                        {file ? <img src={file} /> : (<UserOutlined />)}
                        <CloudUploadOutlined/>
                    </Upload>
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="User email / Login"
                    name="login"
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Row gutter={[10, 10]}>
                    <Col span={24} lg={12}>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: isNew }]}
                        >
                            <Input.Password placeholder={isNew ? "" : "Leave it blank to keep"} />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={12}>
                        <Form.Item
                            className="show-error"
                            label="Password Confirm"
                            name="password_confirm"
                            rules={[
                                { required: isNew, message: '' },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if ((!isNew && !value && !getFieldValue('password')) || (value && getFieldValue('password') === value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder={isNew ? "" : "Leave it blank to keep"} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    className="mb-0"
                    label="Profile"
                    name="profile"
                    rules={[{ required: true }]}
                >
                    <Select>
                        {profiles && (profiles.map((item) => {
                            return (
                                <Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>
                            )
                        }))}
                    </Select>
                </Form.Item>
            </Card>
            <Space className="form-actions">
                <Button type="secondary" onClick={handleRemove} disabled={isNew || props.isCurrent} loading={isLoading}>Remove</Button>
                <Button type="primary" htmlType="submit" loading={isLoading} >Save</Button>
            </Space>
        </Form>
    );
}