
import { ExpensesStatus } from '../../utils/enums'

const INITIAL_STATE = []

const SAMPLE_STATE = [
    {
        name: "lorem ipsum 01",
        status: ExpensesStatus.APPROVED,
        date: "01 Dec 20"
    },
    {
        name: "lorem ipsum 02",
        status: ExpensesStatus.DENIED,
        date: "01 Dec 20"
    },
    {
        name: "lorem ipsum 03",
        status: ExpensesStatus.REIMBURSED,
        date: "01 Dec 20"
    },
    {
        name: "lorem ipsum 04",
        status: ExpensesStatus.SUBMITTED,
        date: "01 Dec 20"
    },
    {
        name: "lorem ipsum 05",
        status: ExpensesStatus.UNREPORTED,
        date: "01 Dec 20"
    }
]

export default function expenses(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "UPDATE_EXPENSES":
            return {
                ...state,
                expenses: action.expenses
            }
        default:
            return state
    }
}