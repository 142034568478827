import React, { useEffect, useState } from 'react';
import { systemsModuleApi } from '../../../../services/api';
import { getFile } from '../../../../utils/helpers';
import { Card, Row, Col, Button, Form, Input, Drawer, Select, Space, message, Tree, Modal, Upload, Divider, InputNumber } from "antd";
import { CloudUploadOutlined } from '@ant-design/icons';
import { DeviceDiagram } from './device-diagram';

export default function SiteDevices(props) {
    const [form] = Form.useForm();
    const [formRack] = Form.useForm();
    const [formDocument] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [showDrawerDocument, setShowDrawerDocument] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [devices, setDevices] = useState([]);
    const [list, setList] = useState([]);
    const [racks, setRacks] = useState([]);
    const [fileTypes, setFileTypes] = useState('.pdf');
    const [showUpload, setShowUpload] = useState(true);
    const [mapping, setMapping] = useState(null);
    const [newDevice, setNewDevice] = useState({});

    const getDevices = () => {
        setIsLoading(true);
        systemsModuleApi.get(`/Devices/GetDevices?id=${props.id}`)
            .then(result => {
                let devicesBD = result.data.content;
                let devices = devicesBD.filter(x => x.parentDevice === '000000000000000000000000');

                devices.map(item => {
                    let children = devicesBD.filter(x => x.parentDevice === item._id);
                    item['children'] = children;
                    return item;
                });

                let list = devices.map(device => {
                    let item = { key: device._id, title: device.name, children: [] };
                    if (device.children) {
                        item.children = device.children.map(x => ({ key: x._id, title: x.name }))
                    }
                    return item;
                });

                setDevices(devicesBD);
                setMapping(null);
                mountMapping(devicesBD);
                setList(list);
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getRacks = () => {
        setIsLoading(true);

        systemsModuleApi.get(`/Devices/GetDevicesByCategory?id=${props.id}&category=Rack`)
            .then(result => {
                setRacks(result.data.content);
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getDocuments = (id) => {
        setIsLoading(true);

        systemsModuleApi.get(`/Devices/GetDocuments?id=${id}`)
            .then(result => {
                form.setFieldsValue({
                    documents: result.data.content
                });
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const saveMapping = (data) => {
        if (data !== mapping) {
            systemsModuleApi.put(`/Systems/PutMapping/${props.id}`, data)
                .catch(() => {
                    message.error('Something went wrong!');
                });
        }
    }

    const mountMapping = (devices) => {
        let mapping = null;
        systemsModuleApi.get(`/Systems/GetMapping?id=${props.id}`)
            .then(result => {
                if (result.data.content) {
                    mapping = JSON.parse(result.data.content);
                } else {
                    devices = devices.filter(x => x.category !== 'Rack');
                    mapping = { nodes: [], links: [] };
                    devices.map((device, index) => {
                        let coordinates = [];
                        coordinates.push((index + 1) * 10);
                        coordinates.push((index + 1) * 10);
                        mapping.nodes.push({
                            id: device._id,
                            content: device.name,
                            coordinates: coordinates,
                            inputs: [{ id: `input-1-${device._id}` }],
                        });
                    });
                }
                setMapping(mapping);
            })
            .catch(error => {
                devices = devices.filter(x => x.category !== 'Rack');
                mapping = { nodes: [], links: [] };
                devices.map((device, index) => {
                    let coordinates = [];
                    coordinates.push((index + 1) * 10);
                    coordinates.push((index + 1) * 10);
                    mapping.nodes.push({
                        id: device._id,
                        content: device.name,
                        coordinates: coordinates,
                        inputs: [{ id: `input-1-${device._id}` }],
                    });
                });
                setMapping(mapping);
            });
    }

    const updateMapping = (data) => {
        setMapping(null);
        setTimeout(() => {
            let newMapping = Object.assign({}, mapping);
            newMapping.nodes.map(node => {
                if (node.id === data._id) {
                    if (node.content !== data.name) {
                        node.content = data.name;
                    }

                    let portsDiff = data.ports - node.inputs.length;
                    if (portsDiff > 0) {
                        let ports = node.inputs.length + 1;
                        for (let i = ports; i <= data.ports; i++) {
                            node.inputs.push({ id: `input-${i}-${data._id}` });
                        }
                    } else {
                        for (let i = 0; i < (portsDiff * -1); i++) {
                            let port = node.inputs.pop();
                            let index = newMapping.links.findIndex(x => x.input === port.id || x.output === port.id);
                            newMapping.links.splice(index, 1);
                        }
                    }
                }
            });
            setMapping(newMapping);
            saveMapping(newMapping);
        }, 500);
    }

    let timer;
    const handleMappingChange = (mapping) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            saveMapping(mapping);
        }, 1000);
    }

    const handleModalOpen = (data) => {
        formRack.setFieldsValue(data);
        setShowModal(true);
    }

    const handleModalClose = () => {
        formRack.resetFields();
        setShowModal(false);
    }

    const handleSaveRack = (data) => {
        setIsLoading(true);
        data['siteId'] = props.id;
        data['category'] = 'Rack';

        systemsModuleApi.post('/Devices/PostDevice', { device: JSON.stringify(data) })
            .then(result => {
                handleModalClose();
                getDevices();
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleDrawerOpen = () => {
        getRacks();
        setShowDrawer(true);
    }

    const handleDrawerClose = () => {
        form.resetFields();
        setShowDrawer(false);
    }

    const handleSave = (data) => {
        setIsLoading(true);
        data['siteId'] = props.id;

        if (data._id) {
            updateMapping(data);

            systemsModuleApi.put(`/Devices/PutDevice/${data._id}`, data)
                .then(result => {
                    handleDrawerClose();
                    getDevices();
                })
                .catch(() => {
                    message.error('Something went wrong!');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            let formData = new FormData();
            data.documents?.map(document => {
                let filename = document.file.uid + '-' + document.file.name;
                document['filename'] = filename;
                formData.append("files[]", document.file, filename);
                delete document.file;
            })
            formData.append("device", JSON.stringify(data));

            systemsModuleApi.post('/Devices/PostDevice', formData)
                .then(result => {
                    setNewDevice(result.data.content);
                    handleDrawerClose();
                    getDevices();
                })
                .catch(() => {
                    message.error('Something went wrong!');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleSelectNode = (selectedKeys) => {
        if (selectedKeys.length > 0) {
            let id = selectedKeys[0];
            let device = devices.find(x => x._id === id);
            
            if(device.category !== 'Rack'){
                let node = mapping.nodes.find(x => x.id === id);
                device['ports'] = node.inputs?.length;

                if(device.parentDevice === '000000000000000000000000'){
                    device.parentDevice = null;
                }
    
                form.setFieldsValue(device);
                handleDrawerOpen();
                getDocuments(device._id);
            }
        }
    }

    const handleDrawerDocumentOpen = () => {
        formDocument.resetFields();
        setShowUpload(true);
        setShowDrawerDocument(true);
    }

    const handleDrawerDocumentClose = () => {
        formDocument.resetFields();
        setShowUpload(true);
        setShowDrawerDocument(false);
    }

    const handleChangeFileType = (value) => {
        if (value === 0) {
            setFileTypes('.pdf');
        } else if (value === 1) {
            setFileTypes('.jpg, .png');
        }
    }

    const handleAddDocument = (data) => {
        let deviceId = form.getFieldValue('_id');
        let documents = form.getFieldValue('documents');

        if (documents === undefined) {
            documents = [];
        }

        if (deviceId) {
            let formData = new FormData();
            let filename = data.file.uid + '-' + data.file.name;
            data['deviceId'] = deviceId;
            formData.append("files[]", data.file, filename);
            formData.append("document", JSON.stringify(data));

            systemsModuleApi.post('/Devices/PostDocument', formData)
                .then(result => {
                    documents.push(result.data.content);
                    form.setFieldsValue({
                        documents: documents
                    });
                    handleDrawerDocumentClose();
                })
                .catch(() => {
                    message.error('Something went wrong!');
                })
                .finally(() => {
                    setIsLoading(false);
                });

        } else {
            const rand = (Math.random().toString(16) + "000000000").substr(2, 8);
            data['keyId'] = rand;
            data['siteId'] = props.id;
            documents.push(data);
            form.setFieldsValue({
                documents: documents
            });
            handleDrawerDocumentClose();
        }
    };

    const handleRemoveDocument = (item) => {
        let deviceId = form.getFieldValue('_id');
        if (deviceId) {
            systemsModuleApi.delete(`/Devices/DeleteDocuments/${item._id}`)
                .then(result => {
                    let documents = form.getFieldValue('documents').filter(x => x._id !== item._id);
                    form.setFieldsValue({
                        documents: documents
                    });
                })
                .catch(() => {
                    message.error('Something went wrong!');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            let documents = form.getFieldValue('documents').filter(x => x.keyId !== item.keyId);
            form.setFieldsValue({
                documents: documents
            });
        }
    };

    const getDocumentType = (type) => {
        if (type === 0) {
            return 'Official';
        } else if (type === 1) {
            return 'User uploaded';
        } else {
            return type;
        }
    }

    const getFileType = (type) => {
        if (type === 0) {
            return 'PDF';
        } else if (type === 1) {
            return 'Image';
        } else {
            return type;
        }
    }

    useEffect(() => {
        getDevices();
    }, [])

    return (
        <>
            <div>
                <Row gutter={[10, 10]} align="stretch">
                    <Col span={24} lg={8} xxl={6}>
                        <div className="h-100" style={{ height: '100%', paddingBottom: 95 }}>
                            <Space style={{ position: 'absolute', bottom: 0, left: 20 }}>
                                <Button type="primary" onClick={() => handleDrawerOpen({})}>Add Device</Button>
                                <Button type="primary" onClick={() => handleModalOpen({})}>Add Rack</Button>
                            </Space>
                            <Tree showLine treeData={list} onSelect={handleSelectNode} />
                        </div>
                    </Col>
                    <Col span={24} lg={16} xxl={18}>
                        <div className="h-100" style={{ height: '100%', minHeight: '50vh' }}>
                            {mapping && <DeviceDiagram mapping={mapping} style={{ height: '50vh' }} newDevice={newDevice} onMappingChange={handleMappingChange} />}
                        </div>
                    </Col>
                </Row>
            </div>
            <Drawer
                visible={showDrawer}
                title="Device Detail"
                mask={false}
                getContainer="#main-container"
                destroyOnClose={true}
                onClose={handleDrawerClose}
            >
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSave}
                >
                    <Card bordered={false} style={{ maxWidth: "800px" }}>
                        <Form.Item noStyle name="_id">
                            <Input type="hidden" />
                        </Form.Item>
                        <Row gutter={[10, 10]}>
                            <Col span={24} md={12}>
                                <Form.Item label="Device Type" name="category" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={12}>
                                <Form.Item label="Rack" name="parentDevice">
                                    <Select>
                                        {racks.map(device => <Select.Option key={'rack_' + device._id} value={device._id}>{device.name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[10, 10]}>
                            <Col span={24} md={20}>
                                <Form.Item label="Name/Model" name="name" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={4}>
                                <Form.Item label="Ports" name="ports">
                                    <InputNumber min="1" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col span={12}>
                                <Form.Item className="mb-0" label="Documents" name="documents"></Form.Item>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Button type="secondary" size="small" onClick={() => handleDrawerDocumentOpen()} disabled={isLoading}>Add Document</Button>
                            </Col>
                        </Row>
                        <table className="no-ant stripped bordered mt-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>File Type</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.documents !== currentValues.documents} >
                                    {({ getFieldValue }) => {
                                        let documents = getFieldValue('documents');
                                        return documents && documents.length > 0 ? documents.map(item =>
                                            <tr key={item.keyId}>
                                                <td>{item.name}</td>
                                                <td>{getDocumentType(item.type)}</td>
                                                <td>{getFileType(item.type)}</td>
                                                <td className="text-right"><Button style={{ minWidth: 'auto' }} type="secondary" onClick={() => handleRemoveDocument(item)}>Remove</Button></td>
                                            </tr>
                                        ) : <tr><td></td><td></td><td></td><td></td></tr>
                                    }}
                                </Form.Item>
                            </tbody>
                        </table>
                    </Card>
                    <Space className="form-actions">
                        <Button type="secondary" onClick={handleDrawerClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={isLoading} >Save</Button>
                    </Space>
                </Form>
            </Drawer>
            <Drawer
                visible={showDrawerDocument}
                title="Manage Document"
                mask={false}
                getContainer="#main-container"
                destroyOnClose={true}
                onClose={handleDrawerDocumentClose}
            >
                <Form
                    form={formDocument}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleAddDocument}
                >
                    <Card bordered={false} style={{ width: "600px" }}>
                        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Document Type" name="type" rules={[{ required: true }]}>
                            <Select>
                                <Select.Option value={0}>Official</Select.Option>
                                <Select.Option value={1}>User uploaded</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="File Type" name="fileType" rules={[{ required: true }]}>
                            <Select onChange={handleChangeFileType}>
                                <Select.Option value={0}>PDF</Select.Option>
                                <Select.Option value={1}>Image</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="File" name="file" valuePropName="file" getValueFromEvent={getFile} rules={[{ required: true }]}>
                            <Upload
                                accept={fileTypes}
                                beforeUpload={() => {
                                    setShowUpload(false);
                                    return false;
                                }}
                                onRemove={() => {
                                    setShowUpload(true);
                                }}
                            >
                                {showUpload && <Button type="secondary" icon={<CloudUploadOutlined />}>Click to Upload</Button>}
                            </Upload>
                        </Form.Item>
                    </Card>
                    <Space className="form-actions">
                        <Button type="secondary" onClick={handleDrawerDocumentClose}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={isLoading} >Save</Button>
                    </Space>
                </Form>
            </Drawer>
            <Modal
                title="Rack Manage"
                width="400px"
                visible={showModal}
                footer={null}
                destroyOnClose={true}
                getContainer="#main-container"
                onCancel={handleModalClose}
            >
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSaveRack}
                >
                    <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Row className="mt-2" gutter={[10]}>
                        <Col span={12}>
                            <Button className="w-100" type="secondary" onClick={handleModalClose}>Cancel</Button>
                        </Col>
                        <Col span={12}>
                            <Button className="w-100" type="primary" htmlType="submit" loading={isLoading}>Save</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}