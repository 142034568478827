export function changeBorderColor(primaryColor, secondaryColor){
    return {
        type:"CHANGE_DEFAULT_COLORS",
        primaryColor,
        secondaryColor
    }
}

export function changeColorAsBG(colorAsBg){
    return {
        type:"CHANGE_COLOR_BG",
        colorAsBg
    }
}

export function changeBgColor(BgColor){
    return {
        type:"CHANGE_BG_COLOR",
        BgColor
    }
}

export function setBackground(image){
    return {
        type:"SET_BACKGROUND",
        image
    }
}

export function setLogo(image){
    return {
        type:"SET_LOGO",
        image
    }
}

export function toogleSideMenu(isVisible){
    return {
        type:"TOGGLE_MENU",
        isVisible
    }
}

export function toogleHeader(isHeaderVisible){
    return {
        type:"TOOGLE_HEADER",
        isHeaderVisible
    }
}