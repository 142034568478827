import React, { Component } from 'react'
import { connect } from "react-redux";
import Calendar from 'react-calendar';
import History from "../../routes/history"
import 'react-calendar/dist/Calendar.css';

import { ModuleType, TasksStatus } from '../../utils/enums'
import Map from '../map/map'
import BoxListing from '../boxListing/boxListing'

import "./boxModel.css"

class BoxModel extends Component {
    access = (type, param) => () => {
        // TODO: remove console.log and uncomment History.push 
        if (type)
            if (param)
                History.push('/' + type.split(' ')[0] + '/' + param)
            else
                History.push('/' + type.split(' ')[0])
    }

    render() {
        var box = [];
        var type = this.props.widget.type;
        var logo = false;

        switch (type) {
            case ModuleType.SYSTEMS:  
                box.push(
                    <div key={ "systems" } className="box-main">
                        <div className="box-sizes box-large" style={{position: 'relative'}}>
                            <Map width={"auto"} height={"170px"} showAlerts={true} />
                        </div>
                        <div className="box-sizes box-large">
                            <BoxListing type="systems"/>
                        </div>
                    </div>
                )
                break;
            case ModuleType.REPORTS:            
                box.push(
                    <div key={ "reports" } className="box-main">
                        <div className="box-sizes box-small">
                            <img className="module" src="/icons/ic_reports.png" alt="reports-icon" onClick={ this.access(type)}></img>
                        </div>
                    </div>
                )
                break;
            case ModuleType.TASK:
                let todo_tasks = this.props.store.tasks.tasks ? this.props.store.tasks.tasks.filter(t => t.status === TasksStatus.TODO).length : 0
                let doing_tasks = this.props.store.tasks.tasks ? this.props.store.tasks.tasks.filter(t => t.status === TasksStatus.DOING).length : 0
                let delayed_tasks = this.props.store.tasks.tasks ? this.props.store.tasks.tasks.filter(t => t.status === TasksStatus.DELAYED).length : 0
                
                box.push(
                    <div key={ "tasks" } className="box-main">
                        <div className="box-sizes box-large">
                            <Calendar 
                                showNeighboringMonth={false}
                                // TODO: check how to get the clicked date
                                onClickDay={ this.access("schedule", 1) }
                            />
                        </div>
                        <div className="box-sizes box-large">
                            <ul className="list-ul task-box">
                                <li className="list-li todo">
                                    <div className="container-item" onClick={ this.access(type, TasksStatus.TODO)}>
                                        <span className="title"> TO DO </span>
                                        <span className="subtitle"> {todo_tasks} tasks </span>
                                    </div>
                                </li>
                                <li className="list-li progress">
                                    <div className="container-item" onClick={ this.access(type, TasksStatus.DOING)}>
                                        <span className="title"> IN PROGRESS </span>
                                        <span className="subtitle"> {doing_tasks} tasks </span>
                                    </div>
                                </li>
                                <li className="list-li delayed">
                                    <div className="container-item" onClick={ this.access(type, TasksStatus.DELAYED)}>
                                        <span className="title"> DELAYED </span>
                                        <span className="subtitle"> {delayed_tasks} tasks </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                )
                break;
            case ModuleType.VIEWS:
                box.push(
                    <div key={ "views" } className="box-main">
                        <div className="box-sizes box-small">
                            <img className="module" src="/icons/ic_views.png" alt="views-icon" onClick={ this.access(type)}></img>
                        </div>
                    </div>
                )
                break;
            case ModuleType.SETTINGS:
                box.push(
                    <div key={ "settings" } className="box-main">
                        <div className="box-sizes box-small">
                            <img className="module" src="/icons/ic_settings.png" alt="settings-icon" onClick={ this.access(type)}></img>
                        </div>
                    </div>
                )
                break;
            case ModuleType.PROXIMITY:
                box.push(
                    <div key={ "proximity" } className="box-main">
                        <div className="box-sizes box-small">
                            <img className="module" src="/icons/ic_mask.png" alt="proximity-icon" onClick={ this.access(type)}></img>
                        </div>
                    </div>
                )
                break;
            case ModuleType.EXPENSES:
                box.push(
                    <div key={ "expenses" } className="box-main">
                        <div className="box-sizes box-small box-expenses">
                            <BoxListing type="expenses" />
                        </div>
                    </div>
                )
                break;
            case ModuleType.EMERGENCY:
                box.push(
                    <div key={ "emergency" } className="box-main">
                        <div className="box-sizes box-small">
                            <img className="module" src="/icons/ic_emergency.png" alt="emergency-icon" onClick={ this.access(type)}></img>
                        </div>
                    </div>
                )
                break;
            case ModuleType.NETWORK:
                box.push(
                    <div key={ "network" } className="box-main">
                        <div className="box-sizes box-small">
                            <img className="module" src="/icons/ic_network.png" alt="network-icon" onClick={ this.access(type)}></img>
                        </div>
                    </div>
                )
                break;
            default:
                logo = true;
                box.push(
                    <div key={ "logo" } className="box-logo" style={{backgroundColor: this.props.store.appearance.colorAsBg ? this.props.store.appearance.backgroundColor : "transparent" }}>
                        <img className="logo-img" src={this.props.store.configurations.logo} alt={this.props.widget.company + " Logo"} onClick={ this.access(type)}></img>
                    </div>
                )
                break;
        }

        return (
            <div className="box-container">
                <div className={`${logo ? "logo" : ""}`}  style={logo?{backgroundImage: `url("${this.props.store.configurations.background_image}")`}:null}></div>
                    {box}
                <div className="box-footer" tabIndex="1" onClick={ this.access(type) }>{type}</div>        
            </div>
        )
    }
}

export default connect(state => ({ 
    store: state 
}))(BoxModel);
