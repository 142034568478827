import { createGlobalStyle } from "styled-components"
import "./typography.scss";
import "./colors.scss";

export default createGlobalStyle`

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/Montserrat-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  src: url("/fonts/Montserrat-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: url("/fonts/Montserrat-BoldItalic.ttf") format("truetype");
}

  *, :double-button, input{
      border:0;
      outline:0;
      padding:0;
  }

  ::-webkit-scrollbar {
      width: 6px;
  }
  ::-webkit-scrollbar:horizontal{
    height: 6px;
  }
  
  ::-webkit-scrollbar-track {
      background: #F0F0F0;
      border-radius: 6px;
  }
  
  ::-webkit-scrollbar-thumb {
      background: #BFBFBF;
      border-radius: 6px;
      transition: background 250ms ease;
  }
  
  ::-webkit-scrollbar-thumb:hover {
      background: #7a7a7a;
  }

  .hidden {
    display: none !important;
  }

  .d-none {
      display: none !important;
  }

  .d-block {
      display: block !important;
  }

  .d-flex {
      display: flex !important;
  }
  
  .uppercase {
      text-transform: uppercase;
  }

  .master-container {
      display: flex;
      height: 100vh;
      position: relative;
      z-index: 5;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
  }

  .loading-container {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 107.4vh;
    background: #000;
    opacity: .7;
    display:flex;
    justify-content:center;
    align-items: center;
    z-index: 1040;
  }
  
  .loading {
    color: #fff
  }

  .w-100 {
    width: 100%;
  }

  .h-100 {
    height: 100% !important;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .mt-1{
      margin-top: 10px !important;
  }

  .mt-2 {
      margin-top: 20px !important;
  }

  .mt-3 {
      margin-top: 30px !important;
  }

  .mr-0 {
    margin-right: 0 !important;
  }

  .mr-1 {
    margin-right: 10px !important;
  }

  .mr-2 {
    margin-right: 20px !important;
  }

  .mr-3 {
    margin-right: 30px !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-1 {
    margin-bottom: 10px !important;
  }

  .mb-2 {
    margin-bottom: 20px !important;
  }

  .mb-3 {
    margin-bottom: 30px !important;
  }

  .ml-0 {
    margin-left: 0 !important;
  }

  .ml-1 {
    margin-left: 10px !important;
  }

  .ml-2 {
    margin-left: 20px !important;
  }

  .ml-3 {
    margin-left: 30px !important;
  }

  .m-0 {
    margin: 0 !important;
  }

  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .p-0,
  .py-0,
  .pt-0 {
    padding-top: 0 !important;
  }

  .p-1,
  .pt-1{
    padding-top: 10px !important;
  }

  .p-2,
  .pt-2 {
    padding-top: 20px !important;
  }

  .p-3,
  .pt-3 {
    padding-top: 30px !important;
  }

  .p-0,
  .px-0,
  .pr-0 {
    padding-right: 0 !important;
  }

  .p-1,
  .pr-1 {
    padding-right: 10px !important;
  }

  .p-2,
  .pr-2 {
    padding-right: 20px !important;
  }

  .p-3,
  .pr-3 {
    padding-right: 30px !important;
  }

  .p-0,
  .py-0,
  .pb-0 {
    padding-bottom: 0 !important;
  }

  .p-1,
  .pb-1 {
    padding-bottom: 10px !important;
  }

  .p-2,
  .pb-2 {
    padding-bottom: 20px !important;
  }

  .p-3,
  .pb-3 {
    padding-bottom: 30px !important;
  }

  .p-0,
  .px-0,
  .pl-0 {
    padding-left: 0 !important;
  }

  .p-1,
  .pl-1 {
    padding-left: 10px !important;
  }

  .p-2,
  .pl-2 {
    padding-left: 20px !important;
  }

  .p-3,
  .pl-3 {
    padding-left: 30px !important;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .flex {
    display: flex;
  }

  .jc-center {
    justify-content: center;
  }
  
  .ai-center {
    align-items: center;
  }
  
  .ai-left {
    align-items: flex-start;
  }

  .global-form-row {
    display:flex;
    width:100%;
    flex-direction:row;
  }

  .row-reverse {
    flex-direction:row-reverse;
  }

  .global-form-column {
    display:flex;
    width:100%;
    flex-direction:column;
  }

  hr {
    border-bottom: 1px solid #D1D1D1;
    margin-bottom: 3rem;
  }  

  label {
    font-size: 13px;
    line-height: 22px;
    color: #4f4f4f;
    font-weight: 500;
  }

  .label-title {
    font-size: 20px !important;
    font-weight: 400;
    margin: 0.2rem 0;
  }

  .modal-fade{
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 107.4vh;
    backdrop-filter: blur(1px);
    background: rgba(255,255,255,0.70);
    display:flex;
    justify-content:center;
    align-items: center;
  }

  .modal-context{
    box-sizing: border-box;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
    background: #fff;
    z-index:200;
    min-width: 400px;
    transition: opacity 0.5s;

    .form-group{
      width: 100%
    }
  }

  .modal-title{
    width:100%;
    text-align:center;
    background-color: #E9E9E9;
    padding:10px 0;
    font-size:11px;
    height:30px;
    display:flex;
    align-items:center;
    justify-content:center;
    text-transform:capitalize;
    position:relative;
  }

  .modal-body{
      padding:45px;
  }

  .modal-actions{
    display:flex;
    justify-content:space-between;
    margin-top:30px
  }

  .modal-close{
    position:absolute;
    right:20px;
    top:20px;
  }

  .close-icon{
    color:#636363;
    cursor:pointer;
  }
  
  button.submit{
    background-color: ${(props) => props.colors.primaryColor};
    padding: 5px 10px;
    color: #fff;
    border-radius: 5px;
    margin-right:10px;
    cursor: pointer;
    min-width: 100px;
  }

  button.reset{
    padding:10px;
    border-radius: 5px;
    margin-right:10px;
    cursor: pointer;
    background-color:#fff;
    color: #0F5071;
  }

  button.inactive{
    background-color:#E7E7E7;
    padding:10px;
    border-radius: 5px;
    min-width: 107px;
  }

  button.cancel {
    background-color:#E7E7E7;
    padding:10px;
    border-radius: 5px;
    margin-right:10px;
    min-width: 100px;
    color: #000 !important;
  }

  button.disabled {
    background-color:#FFFFFF !important;
    padding:10px;
    border-radius: 5px;
    border: 1px solid #B8B8B8;
    margin-right:10px;
    min-width: 100px;
    color: #B8B8B8 !important;
    cursor: initial !important;
  }
  
  input:checked+.slider {
    background-color:  ${(props) => props.colors.primaryColor};
  }

  table {
    background: #F4F4F4;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: .5rem;
    width: 100%;
    margin-top: 1rem;
    border-spacing: 0;
  }

  th {
    text-align: initial;
    padding: 1rem;
    background: #FFFFFF;
    box-shadow: 0px 3px 5px rgba(201, 201, 201, 0.1);
  }

  th:first-child {
    border-top-left-radius: .5rem;
  }

  th:last-child {
    border-top-right-radius: .5rem;
  }

  td {
    padding: 1rem;
  }

  td.action {
    display: flex;
    flex-direction: row-reverse
  }

  td.action > button:nth-of-type(1) {
    margin-left: 10px;
  }

  .react-tabs {
    width: 100%
  }

  .react-tabs__tab-list {
    border-bottom: 0px;
    margin: 0 0 -2px;
  }

  .react-tabs__tab {
    margin: 0 2.5rem;
  }

  .react-tabs__tab--selected {
    font-weight: 500;
    color: ${(props) => props.colors.primaryColor};
    background: transparent;
    border-color: transparent;
  }

  .react-tabs__tab--selected::after {
    background: ${(props) => props.colors.primaryColor};
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    margin: 0 1rem;
  }

  .react-tabs__tab--selected:focus {
    box-shadow: none;
    color: ${(props) => props.colors.primaryColor};
    background: transparent;
    border-color: transparent;
  }

  .modal-context {
    .form-group {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
    }

    input {
      border: 1px solid #e5e5e5 !important;
      height: 35px;
      border-radius: 6px;
      margin-top: 5px;
      padding-left: 10px !important;
    }

    label {
      text-transform: capitalize;
      font-size: 11px;
    }

    .modal-btn {
      min-width: 145px;
      padding: 10px 5px;
      color: #fff;
      border-radius: 6px;
      cursor: pointer;
    }

    .primary {
      background: ${(props) => props.colors.primaryColor};
    }

    .secondary {
      background: #dbdbdb;
      color: #4f4f4f;
    }
  }

  .side_menu_logo_container {
    margin: 1rem;
    padding: 1rem;
    border-radius: 6px;
  }

  .react-tabs__tab--selected:focus:after {
    background: ${(props) => props.colors.primaryColor};
  }

  .page-container{
    width: 100%;
    padding: 30px 45px 45px;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(25px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .pagination{
    list-style:none;
    display:flex;
    background: rgba(255,255,255,0.75);
    box-shadow: 0px 3px 5px rgb(0 0 0 / 10%);
    margin-top:20px;
    justify-content:center;
    

    li{
      margin:5px;
      text-align:center;
      cursor: pointer;
      color:#000;
      opacity:0.75;
      font-size: 11px;
    
      min-width:15px;
      padding: 5px;

      a{
        display: block;
        min-width: 20px;
        width: 100%;
      }

      &:hover{
        background: #fff;
        opacity:0.5;
      }
    }

    .previous{
      color:#000;
    }

    .active{
      background: #fff;
      font-weight:bold;
      color:#0F5071;
    }

    .next{
      color:#000;
    }

    .break-me{
      color:#0F5071;
      color:#000;
    }
  }

  .js-pagination{
    list-style:none;
    width:100%;
    display:flex;

    li{
      text-align:center;
      margin:5px;
      border:1px solid #000;
      padding: 3px 5px;
      min-width: 10px;
      background: #F4F4F4;
      border-radius: 5px;
    }
    
    .active{
      background: #0F5071;
     

      a{
        color: #fff;
      }
    }

    a{
      color: #000;
      text-decoration:none
    }
  }

  .text-right{
    text-align: right !important;
  }

  @media (max-width: 991px) {
    .page-container{
      padding:30px 10px;
      margin-top: 50px;
    }

    .hidden-xs{
      display:none;
    }
  
  }

  @media (min-width: 992px) {
      .d-lg-block {
          display: block !important;
      }
      .d-lg-none {
          display: none !important;
      }
  }


`;