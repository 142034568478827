import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { ChromePicker } from 'react-color'
import ImagePicker from 'react-image-picker'
import { Form, Button, Card, Col, Row, message, Space, Switch, Upload } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { configurationModuleApi, configurationUploadImageApi } from "../../../services/api";
import * as AppearanceActions from '../../../store/actions/appearance';
import * as ConfigurationActions from '../../../store/actions/configuration';
import { getFile } from '../../../utils/helpers';
import './appearance.scss';
import 'react-image-picker/dist/index.css'

const Appearence = ({ store, dispatch }) => {
  const [formBackground] = Form.useForm();
  const [formLogo] = Form.useForm();
  const [formColor] = Form.useForm();
  const [formLogoBackground] = Form.useForm();
  let defaultUploadUrl = "/images/upload_image.svg"
  let defaultLogoUrl = "/images/apex_logo_white.png"
  let defaultColor = "#0F5070"
  let inputBackgroundRef = React.createRef();
  let pickImage = React.createRef();

  const [idSettings, setId] = useState(null);
  const [choosenBackground, setChoosenBackground] = useState(defaultUploadUrl);
  const [uploadImage, setUploadImage] = useState({});
  const [urlBackground, setBackground] = useState(defaultUploadUrl);
  const [indexBackground, setIndexBackground] = useState(-1);
  const [manually, setManually] = useState(false);
  const [logo, setLogo] = useState(defaultLogoUrl);
  const [color, setColor] = useState(defaultColor);
  const [colorAsBg, setColorAsBg] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLogo, setIsLoadingLogo] = useState(false);
  const [isLoadingColor, setIsLoadingColor] = useState(false);

  useEffect(() => {
    GetSettings();
  }, [])

  useEffect(async () => {
    if (indexBackground !== -1)
      pickImage.current.handleImageClick(pickImage.current.props.images.find(i => i.value === indexBackground))
  }, [indexBackground])

  useEffect(async () => {
    setIndexBackground(imageList.findIndex(i => i == urlBackground))
  }, [urlBackground])

  let imageList = [
    "/images/background_1.png",
    "/images/background_2.png",
    "/images/background_3.png",
    "/images/background_4.png",
    "/images/background_5.png",
    "/images/background_6.png",
    "/images/background_7.png",
    urlBackground
  ]

  const GetSettings = () => {
    setIsLoading(true);
    setIsLoadingLogo(true);
    setIsLoadingColor(true);

    configurationModuleApi.get("/Settings/GetSettings")
      .then((result) => {
        SetSettings(result);

        formLogo.setFieldsValue({
          colorAsBg: result.data.content.colorAsBg
        })
      })
      .finally(() => {
        setIsLoading(false);
        setIsLoadingLogo(false);
        setIsLoadingColor(false);
      })
  }

  const SetSettings = (result) => {
    setId(result.data.content._id)
    setColor(result.data.content.primaryColor ? result.data.content.primaryColor : defaultColor)
    setBackgroundColor(result.data.content.backgroundColor ? result.data.content.backgroundColor : "#FFFFFF")
    setLogo(result.data.content.logo ? result.data.content.logo : defaultLogoUrl)
    setColorAsBg(result.data.content.colorAsBg)
    setManually(false)

    dispatch(AppearanceActions.changeBorderColor(result.data.content.primaryColor == undefined ? defaultColor : result.data.content.primaryColor, "#ede000"))
    dispatch(AppearanceActions.changeBgColor(result.data.content.backgroundColor == undefined ? "#FFFFFF" : result.data.content.backgroundColor))
    dispatch(AppearanceActions.changeColorAsBG(result.data.content.colorAsBg == undefined ? false : result.data.content.colorAsBg))
    dispatch(ConfigurationActions.updatelogo(result.data.content.logo ? result.data.content.logo : defaultLogoUrl))

    if (result.data.content.background !== undefined && result.data.content.background.includes("http"))
      setBackground(result.data.content.background)
    else {
      setBackground(defaultUploadUrl)
      setIndexBackground(imageList.findIndex(i => i == result.data.content.background))
    }

    dispatch(AppearanceActions.setBackground(result.data.content.background))
  }

  async function handleBackgroundSubmit(data) {
    setIsLoading(true);
    let url = '/Settings/SetBackground?id=';

    if (indexBackground === 7) {
      let formData = new FormData()
      formData.append("file", uploadImage);

      configurationUploadImageApi.put(url + idSettings, formData)
        .then(async (res) => {
          SetSettings(res);
          message.success("Background updated successfully");
        })
        .catch((err) => {
          message.error("Something went wrong!");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    else {
      data.background = choosenBackground

      configurationModuleApi.put(url + idSettings, data)
        .then(async (res) => {
          SetSettings(res);
          message.success("Background updated successfully");
        })
        .catch((err) => {
          message.error("Something went wrong!");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  async function handleLogoSubmit(data) {
    setIsLoadingLogo(true);
    let url = '/Settings/SetLogo?id=';

    if (logo === defaultLogoUrl) {
      data.logo = "";

      configurationModuleApi.put(url + idSettings, data)
        .then(async (res) => {
          SetSettings(res);
          message.success("Logo updated successfully");
        })
        .finally(() => {
          setIsLoadingLogo(false);
        });
    } else {
      let formData = new FormData();
      formData.append("file", data.logo);
      formData.append("colorAsBg", data.colorAsBg);

      configurationUploadImageApi.put(url + idSettings, formData)
        .then(async (res) => {
          SetSettings(res);
          message.success("Logo updated successfully");
        })
        .catch((err) => {
          message.error("Something went wrong!");
        })
        .finally(() => {
          setIsLoadingLogo(false);
        });
    }
  }

  async function handleColorSubmit(data) {
    setIsLoadingColor(true)
    data.primaryColor = color

    configurationModuleApi.put('/Settings/EditSettings?id=' + idSettings, data)
      .then(async (res) => {
        SetSettings(res);
        message.success("Primary color updated successfully");
      })
      .catch((err) => {
        message.error("Something went wrong!");
      })
      .finally(() => {
        setIsLoadingColor(false)
      });
  }

  async function handleBackgroundColorSubmit(data) {
    setIsLoadingLogo(true);
    data.backgroundColor = backgroundColor;
    data.colorAsBg = true;

    configurationModuleApi.put('/Settings/EditSettings?id=' + idSettings, data)
      .then(async (res) => {
        SetSettings(res);
        message.success("Background color updated successfully");
      })
      .catch((err) => {
        message.error("Something went wrong!");
      })
      .finally(() => {
        setIsLoadingLogo(false);
      });
  }

  const handleBackgroundChange = e => {
    if (e.target.files[0]) {
      if (e.target.files[0].type === "image/x-png" || e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/jpg") {
        setBackground(URL.createObjectURL(e.target.files[0]))
        setChoosenBackground(URL.createObjectURL(e.target.files[0]))
        setUploadImage(e.target.files[0])
      }
      else
        message.error("Only jpg/jpeg and png files are allowed!");
    }
  }

  const handleChangeComplete = data => {
    if (data.hex !== color)
      setColor(data.hex);
  };

  const handleBgColorChangeComplete = data => {
    if (data.hex !== color)
      setBackgroundColor(data.hex);
  };

  const backgroundUploader = () => {
    if (inputBackgroundRef.current) {
      setManually(false)
      inputBackgroundRef.current.click();
    }
  }

  const onPick = (image) => {
    if (manually && image.value === 7)
      backgroundUploader();
    else
      setChoosenBackground(image.src)

    setIndexBackground(image.value)
  }

  const resetBackground = () => {
    setBackground(defaultUploadUrl);
    setIndexBackground(0);
    setChoosenBackground(defaultUploadUrl)
  }

  return (
    <>
      <Row gutter={[12, 12]} align="stretch">
        <Col span={24}>
          <Card bordered={false}>
            <Form form={formBackground} layout="vertical" onFinish={handleBackgroundSubmit}>
              <Form.Item label="Background" name="backgroundImage">
                <div className="appearance-background-picker" onMouseDown={() => setManually(true)}>
                  <ImagePicker ref={pickImage}
                    images={imageList.map((image, i) => ({ src: image, value: i }))}
                    onPick={onPick}
                  />
                  <input type="file" hidden={true} ref={inputBackgroundRef} onChange={handleBackgroundChange} accept="image/x-png,image/jpeg,image/jpg" />
                </div>
              </Form.Item>
              <Space>
                <Button type="secondary" onClick={() => resetBackground()}>Reset Default</Button>
                <Button type="primary" htmlType="submit" loading={isLoading}>Save</Button>
              </Space>
            </Form>
          </Card>
        </Col>
        <Col span={24} md={8} xl={6} xxl={5}>
          <Card bordered={false}>
            <Form form={formLogo} layout="vertical" onFinish={handleLogoSubmit}>
              <Form.Item label="Logo" name="logo" valuePropName="file" getValueFromEvent={getFile}>
                <Upload
                  className="upload-single"
                  accept=".jpg,.jpeg,.png"
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={file => {
                    setLogo(URL.createObjectURL(file))
                    return false;
                  }}
                >
                  {logo && (
                    <div className="logo-background" style={{ backgroundColor: colorAsBg ? backgroundColor : "transparent" }}>
                      <img className="img-fluid" src={logo} />
                    </div>
                  )}
                  <CloudUploadOutlined />
                </Upload>
              </Form.Item>
              <Space>
                <Form.Item className="mb-0" name="colorAsBg" valuePropName="checked">
                  <Switch onChange={(checked) => setColorAsBg(checked)} />
                </Form.Item>
                <label className="label-switch">Use BG color to logo</label>
              </Space>
              <Row className="mt-3" gutter={[10]}>
                <Col span={12}>
                  <Button className="w-100" type="secondary" onClick={() => setLogo(defaultLogoUrl)}>Reset Default</Button>
                </Col>
                <Col span={12}>
                  <Button className="w-100" type="primary" htmlType="submit" loading={isLoadingLogo}>Save</Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={24} md={8} xl={6} xxl={5} style={{ display: colorAsBg ? 'block' : 'none' }}>
          <Card bordered={false}>
            <Form form={formLogoBackground} layout="vertical" onFinish={handleBackgroundColorSubmit}>
              <Form.Item label="Logo background" name="backgroundColor">
                <ChromePicker disableAlpha={true} color={backgroundColor} onChange={handleBgColorChangeComplete} />
              </Form.Item>
              <Row className="mt-1" gutter={[10]}>
                <Col span={12}>
                  <Button className="w-100" type="secondary" onClick={() => setBackgroundColor("#FFFFFF")}>Reset Default</Button>
                </Col>
                <Col span={12}>
                  <Button className="w-100" type="primary" htmlType="submit" loading={isLoadingLogo}>Save</Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={24} md={8} xl={6} xxl={5}>
          <Card bordered={false}>
            <Form form={formColor} layout="vertical" onFinish={handleColorSubmit}>
              <Form.Item label="Color" name="primaryColor">
                <ChromePicker disableAlpha={true} color={color} onChange={handleChangeComplete} />
              </Form.Item>
              <Row className="mt-1" gutter={[10]}>
                <Col span={12}>
                  <Button className="w-100" type="secondary" onClick={() => setColor(defaultColor)}>Reset Default</Button>
                </Col>
                <Col span={12}>
                  <Button className="w-100" type="primary" htmlType="submit" loading={isLoadingColor}>Save</Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default connect(state => ({
  store: state
}))(Appearence);