import React, { Component } from 'react';
import { connect } from "react-redux";

import { ExpensesStatus } from '../../utils/enums'
import "./boxListing.css"

class Listing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shown: []
        };
    }

    access = idx => () => {
        if (this.props.type === "systems") {
            if (this.state.shown.includes(idx)) {
                let id = this.props.store.alerts.systems[idx]._id
                // TODO: Call specific page passing ID as paramether
                console.log('/Systems/' + id) 
                // History.push('/Systems/' + id)
            }
            else {
                // TODO: update map only with markers of the selected system
            }
        }
        else {
            let id = this.props.store.expenses.expenses[idx]._id
            // TODO: Call specific page passing ID as paramether
            console.log('/Expenses/' + id) 
            // History.push('/Expenses/' + id)
        }
    }

    toggleClass = idx => () => {
        this.setState(prevState => ({
            shown: this.state.shown.includes(idx)      
                ? prevState.shown.filter(x => x !== idx) 
                : [...prevState.shown, idx]
        }))
    }

    render() {
        let li = [];
        const { shown } = this.state;
        
        if (this.props.type === "systems") {
            let systems = this.props.store.alerts.systems;
            
            if (systems) {
                for (let index = 0; index < systems.length; index++) {            
                    li.push(
                        <li key={ index.toString() } tabIndex="1" className={ `${systems[index].top_alert_status} list-li` } onMouseDown={ this.access(index) } onFocus={this.toggleClass(index)} onBlur={this.toggleClass(index)}>
                            <div className="container-item">
                                <span className="title"> { systems[index].name } </span>
                                
                                <span className="subtitle"> { systems[index].alert_count } { systems[index].alert_count > 1 ? "alerts" : "alert"} </span>
                            </div>
                            <button className={`${shown.includes(index) ? '' : 'hidden'} access-button`} ></button>
                        </li>
                    )
                }
            }
        }
            
        if (this.props.type === "expenses") {
            let expenses =  this.props.store.expenses.expenses;
            let receiptUrl = "";
            let expenseStatus = "";

            if (expenses) {
                for (let index = 0; index < expenses.length; index++) {                
                    receiptUrl = expenses[index].receipt[0]
                    
                    if (!receiptUrl)
                        receiptUrl = "/images/noImg.png";

                    switch (expenses[index].status.toLowerCase()) {
                        case ExpensesStatus.UNREPORTED.toLowerCase():
                            expenseStatus = "Unreported";
                            break;
                        case ExpensesStatus.OPEN.toLowerCase():
                            expenseStatus = "Open";
                            break;
                        case ExpensesStatus.SUBMITTED.toLowerCase():
                            expenseStatus = "Submitted";
                            break;
                        case ExpensesStatus.APPROVED.toLowerCase():
                            expenseStatus = "Approved";
                            break;
                        case ExpensesStatus.REIMBURSABLE.toLowerCase():
                            expenseStatus = "Reimbursable";
                            break;
                        case ExpensesStatus.DENIED.toLowerCase():
                            expenseStatus = "Denied";
                            break;
                        case ExpensesStatus.REIMBURSED.toLowerCase():
                            expenseStatus = "Reimbursed";
                            break;
                        case ExpensesStatus.NOT_REIMBURSED.toLowerCase():
                            expenseStatus = "Not Reimbursed";
                            break;
                        case ExpensesStatus.PAID.toLowerCase():
                            expenseStatus = "Paid";
                            break;
                        case ExpensesStatus.NOT_PAID.toLowerCase():
                            expenseStatus = "Not Paid";
                            break;
                        case ExpensesStatus.ARCHIVED.toLowerCase():
                            expenseStatus = "Archived";
                            break;
                        default:
                            expenseStatus = ""
                            break;
                    }
                    li.push(
                        <li key={ index.toString() } className={ `${expenseStatus} list-li` } onMouseDown={ this.access(index) }>
                            <div className="receipt-thumb">
                                <img className="receipt-img" src={ receiptUrl } alt={"Receipt"}></img>
                            </div>                        
                            <div className="container-item">
                                <span className="title"> { expenses[index].description } </span>
                                    {/* <span className="subtitle"> { expenses[index].status } - { expenses[index].date } </span> */}
                                    <span className="subtitle"> { expenseStatus } </span>
                            </div>
                            
                            <button className="access-button"></button>
                        </li>
                    )
                }
            }
        } 
    
        return (
            <ul className="list-ul">
                {li}
            </ul>
        );
    }
}  

export default connect(state => ({ 
    store: state 
}))(Listing);