import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Responsive, WidthProvider } from "react-grid-layout";
import { connect } from "react-redux";

import BoxModel from '../../components/boxModel/boxModel'
import { ModuleType, LauncherWidget } from '../../utils/enums'
// TODO: uncomment socket and test
// import socket from '../../utils/socket'
import { GetExpenses, GetTasks, GetModules } from "../../services/services";
import * as ConfigurationActions from '../../store/actions/configuration';
import * as ExpensesActions from '../../store/actions/expenses';
import * as TasksActions from '../../store/actions/tasks';
import * as AppearanceActions from '../../store/actions/appearance';
import { configurationModuleApi } from "../../services/api";

import "./launcher.css"

const ResponsiveGridLayout = WidthProvider(Responsive);

const Launcher = ( { dispatch }) => {
    const [systems1, setSystems] = useState([]);
    const [modules, setModules] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [width] = useMediaQuery();

    var html = []
    var boxList = [];
    var boxes = [];
    var box = {};
    var cols = { lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 };
    var originalRow = 2
    var row = originalRow;
    var column = .75
    var layout = {};
    var logo = { type: ModuleType.LOGO, width: LauncherWidget.SMALL }
    var larges = modules.filter(w => w.width === LauncherWidget.LARGE)
    var sortedModules = [];
    var smallScreenModules = [];

    // useEffect(async () => {
    //     systemsModuleApi.get('/System/GetAllSystems').then((res) => {
    //         if (res && res.status == 200) {
    //             let list = res.data.content;
    //             // TODO: fix system API then finish this box
    //             // res.data.content.forEach(system => {
    //             //     let sys = {
    //             //         _id: system._id,
    //             //         name: system.name,
    //             //         location: system.location,
    //             //         alert_count: 0,
    //             //         top_alert_status: "",
    //             //         alerts: []
    //             //     }

    //             //     list.push(sys)
    //             // });

    //             setSystems(list);

    //             dispatch(AlertsActions.generateSystems(list))

    //             // TODO: uncomment socket and test
    //             // socket.on("updateAlerts", alert => {
    //             //     dispatch(AlertsActions.updateAlerts(list, alert))
    //             // });
    //         }
    //     }).catch((err) => {
    //         var systems = [
    //             {
    //                 _id: 1,
    //                 name: "system name 01",
    //                 description: "system name 01",
    //                 alerts: [{
    //                     _id: 1,
    //                     system_id: 1,
    //                     status: 0
    //                 },
    //                 {
    //                     _id: 2,
    //                     system_id: 1,
    //                     status: 1
    //                 },
    //                 {
    //                     _id: 3,
    //                     system_id: 1,
    //                     status: 2
    //                 },
    //                 {
    //                     _id: 4,
    //                     system_id: 1,
    //                     status: 1
    //                 },
    //                 {
    //                     _id: 5,
    //                     system_id: 1,
    //                     status: 0
    //                 },
    //                 {
    //                     _id: 6,
    //                     system_id: 1,
    //                     status: 1
    //                 },
    //                 {
    //                     _id: 7,
    //                     system_id: 1,
    //                     status: 1
    //                 },
    //                 {
    //                     _id: 8,
    //                     system_id: 1,
    //                     status: 2
    //                 }],
    //                 location: {
    //                     lat: -2.2884,
    //                     lng: 36.8233
    //                 },
    //                 sites: [
    //                     {
    //                         _id: 1,
    //                         name: "Site 1",
    //                         location: {
    //                             lat: -2.2884,
    //                             lng: 36.8233
    //                         }
    //                     },
    //                     {
    //                         _id: 2,
    //                         name: "Site 2",
    //                         location: {
    //                             lat: -2.5884,
    //                             lng: 36.2233
    //                         }
    //                     },
    //                 ],
    //                 alert_count: 8,
    //                 top_alert_status: "major"
    //             },
    //             {
    //                 _id: 2,
    //                 name: "system name 02",
    //                 alerts: [],
    //                 sites: [
    //                     {
    //                         _id: 3,
    //                         name: "Site 1",
    //                         location: {
    //                             lat: 2.2884,
    //                             lng: 36.8233
    //                         }
    //                     },
    //                     {
    //                         _id: 4,
    //                         name: "Site 2",
    //                         location: {
    //                             lat: 2.5884,
    //                             lng: 36.2233
    //                         }
    //                     },
    //                 ],
    //                 alert_count: 0,
    //                 top_alert_status: "safe"
    //             },
    //             {
    //                 _id: 3,
    //                 name: "system name 03",
    //                 alerts: [],
    //                 sites: [
    //                     {
    //                         _id: 5,
    //                         name: "Site 1",
    //                         location: {
    //                             lat: -1.2884,
    //                             lng: 33.8233
    //                         }
    //                     },
    //                     {
    //                         _id: 6,
    //                         name: "Site 2",
    //                         location: {
    //                             lat: -1.3884,
    //                             lng: 33.2233
    //                         }
    //                     },
    //                 ],
    //                 alert_count: 0,
    //                 top_alert_status: "safe"
    //             },
    //             {
    //                 _id: 4,
    //                 name: "system name 04",
    //                 alerts: [],
    //                 sites: [
    //                     {
    //                         _id: 7,
    //                         name: "Site 1",
    //                         location: {
    //                             lat: -1.2884,
    //                             lng: 35.8233
    //                         }
    //                     },
    //                     {
    //                         _id: 8,
    //                         name: "Site 2",
    //                         location: {
    //                             lat: -1.3884,
    //                             lng: 35.2563
    //                         }
    //                     },
    //                 ],
    //                 alert_count: 0,
    //                 top_alert_status: "safe"
    //             },
    //             {
    //                 _id: 5,
    //                 name: "system name 05",
    //                 alerts: [],
    //                 sites: [
    //                     {
    //                         _id: 9,
    //                         name: "Site 1",
    //                         location: {
    //                             lat: 1.2884,
    //                             lng: 36.8233
    //                         }
    //                     },
    //                     {
    //                         _id: 10,
    //                         name: "Site 2",
    //                         location: {
    //                             lat: 1.3884,
    //                             lng: 36.1233
    //                         }
    //                     },
    //                 ],
    //                 alert_count: 0,
    //                 top_alert_status: "safe"
    //             }
    //         ]

    //         setSystems(systems)

    //         dispatch(AlertsActions.generateSystems(systems))

    //         // TODO: uncomment socket and test
    //         // socket.on("updateAlerts", alert => {
    //         //     dispatch(AlertsActions.updateAlerts(systems, alert))
    //         // });
    //     })

    const GetSettings = async () => {
        configurationModuleApi.get("/Settings/GetSettings").then(async (result) => {
            if (result && result.status == 200) {
                dispatch(ConfigurationActions.updatelogo(result.data.content.logo == undefined ? "/images/apex_logo_white.png" : result.data.content.logo))
                dispatch(ConfigurationActions.updateBackground(result.data.content.background))
                dispatch(AppearanceActions.changeBorderColor(result.data.content.primaryColor == undefined ? "#21d2ff" : result.data.content.primaryColor, "#ede000"))
            
                if (result.data.content.background !== undefined)
                    dispatch(AppearanceActions.setBackground(result.data.content.background))
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    function useMediaQuery() {
        const [screenSize, setScreenSize] = useState([0, 0]);

        useLayoutEffect(() => {
            function updateScreenSize() {
                setScreenSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener("resize", updateScreenSize);
            updateScreenSize();
            return () => window.removeEventListener("resize", updateScreenSize);
        }, []);
        
        return screenSize;
    }   

    useEffect(async () => {
        await GetSettings()
        setModules(await GetModules());
        dispatch(ExpensesActions.updateExpenses(await GetExpenses()));
        dispatch(TasksActions.updateTasks(await GetTasks()));
    }, [])
    
    // APP_L - 8
    if (modules.length === 8 && larges.length === 2) {        
        sortedModules = [...modules.filter(m => m.width === LauncherWidget.SMALL)]
        sortedModules.unshift(modules.filter(m => m.width === LauncherWidget.LARGE)[0]);
        sortedModules.splice(5, 0, modules.filter(m => m.width === LauncherWidget.LARGE)[1])

        smallScreenModules = [...modules.filter(m => m.width === LauncherWidget.SMALL)]
        smallScreenModules.unshift(modules.filter(m => m.width === LauncherWidget.LARGE)[0]);
        smallScreenModules.splice(5, 0, modules.filter(m => m.width === LauncherWidget.LARGE)[1])
                
        if (width <= 772) {
            originalRow = .15
            column = 0
        } 
        else if (width <= 834) {
            originalRow = .25
            column = 0
        } 
        else if (width <= 890) {
            originalRow = 1
            column = 0
        } 
        else if (width <= 1200) {
            originalRow = 0
            column = .25
        } 
        else {
            originalRow = .5
            column = .25
        } 
        
        logo.width = LauncherWidget.LARGE
        sortedModules.splice(4, 0, logo)
        smallScreenModules.splice(3, 0, logo)
        row = originalRow;

        let length = width <= 890 ? smallScreenModules.length : sortedModules.length
        for (let index = 0; index < length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w:  width <= 890 ? smallScreenModules[index].width : sortedModules[index].width,
                h: 1,
                static: true
            }
            
            if (width <= 890) {
                if (smallScreenModules[index].width === LauncherWidget.LARGE || index === 2 || index === 5){
                    row = originalRow
                    column++
                }
                else 
                    row++
            } 
            else {
                if (sortedModules[index].width === LauncherWidget.LARGE)
                    row += 2
                else if (index === 2 || index === 5){
                    row = originalRow
                    column++
                }
                else
                    row++
            }

            let widget = width <= 890 ? smallScreenModules[index] : sortedModules[index]
            boxList.push(<div key={index.toString()}><BoxModel widget={widget} /></div>)
            boxes.push(box);
        }
    }

    // APP_L - 7 (2)
    if (modules.length === 7 && larges.length === 1) {
        sortedModules = [...modules.filter(m => m.width === LauncherWidget.SMALL)]
        sortedModules.unshift(modules.find(m => m.width === LauncherWidget.LARGE));

        smallScreenModules = [...modules.filter(m => m.width === LauncherWidget.SMALL)]
        smallScreenModules.unshift(modules.find(m => m.width === LauncherWidget.LARGE));
         
        if (width <= 772) {
            originalRow = .15
            column = 0
        } 
        else if (width <= 834) {
            originalRow = .25
            column = 0
        } 
        else if (width <= 890) {
            originalRow = 1
            column = 0
        } 
        else if (width <= 1200) {
            originalRow = .5
            column = .25
        } 
        else {
            originalRow = 1
            column = .25
        } 

        if (width <= 890)
            logo.width = LauncherWidget.LARGE
        else
            logo.width = LauncherWidget.SMALL
            
        sortedModules.splice(3, 0, logo)
        smallScreenModules.splice(3, 0, logo)
        row = originalRow;

        let length = width <= 890 ? smallScreenModules.length : sortedModules.length
        for (let index = 0; index < length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w:  width <= 890 ? smallScreenModules[index].width : sortedModules[index].width,
                h: 1,
                static: true
            }
            
            if (width <= 890) {
                if (smallScreenModules[index].width === LauncherWidget.LARGE || index === 2 || index === 5){
                    row = originalRow
                    column++
                }
                else 
                    row++
            } 
            else {
                if (sortedModules[index].width === LauncherWidget.LARGE)
                    row += 2
                else if (index === 1 || index === 4){
                    row = originalRow
                    column++
                }
                else
                    row++
            }

            let widget = width <= 890 ? smallScreenModules[index] : sortedModules[index]
            boxList.push(<div key={index.toString()}><BoxModel widget={widget} /></div>)
            boxes.push(box);
        }
    }

    // APP_L - 7
    if (modules.length === 7 && larges.length === 2) {
        sortedModules = [...modules.filter(m => m.width === LauncherWidget.SMALL)]
        sortedModules.unshift(modules.filter(m => m.width === LauncherWidget.LARGE)[0]);
        sortedModules.push(modules.filter(m => m.width === LauncherWidget.LARGE)[1]);

        smallScreenModules = [...modules.filter(m => m.width === LauncherWidget.SMALL)]
        smallScreenModules.unshift(modules.filter(m => m.width === LauncherWidget.LARGE)[0]);
        smallScreenModules.splice(4, 0, modules.filter(m => m.width === LauncherWidget.LARGE)[1])
                
        if (width <= 772) {
            originalRow = .15
            column = 0
        } 
        else if (width <= 834) {
            originalRow = .25
            column = 0
        } 
        else if (width <= 890) {
            originalRow = 1
            column = 0
        } 
        else if (width <= 1200) {
            originalRow = 0
            column = .25
        } 
        else {
            originalRow = .5
            column = .25
        } 

        if (width <= 890)
            logo.width = LauncherWidget.SMALL
        else
            logo.width = LauncherWidget.LARGE
            
        sortedModules.splice(4, 0, logo)
        smallScreenModules.splice(2, 0, logo)
        row = originalRow;

        let length = width <= 890 ? smallScreenModules.length : sortedModules.length
        for (let index = 0; index < length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w:  width <= 890 ? smallScreenModules[index].width : sortedModules[index].width,
                h: 1,
                static: true
            }
            
            if (width <= 890) {
                if (smallScreenModules[index].width === LauncherWidget.LARGE || index === 2 || index === 4){
                    row = originalRow
                    column++
                }
                else 
                    row++
            } 
            else {
                if (sortedModules[index].width === LauncherWidget.LARGE)
                    row += 2
                else if (index === 2 || index === 5){
                    row = originalRow
                    column++
                }
                else
                    row++
            }

            let widget = width <= 890 ? smallScreenModules[index] : sortedModules[index]
            boxList.push(<div key={index.toString()}><BoxModel widget={widget} /></div>)
            boxes.push(box);
        }
    }

    // APP_L - 6 (3)
    if (modules.length === 6 && larges.length === 2) {
        sortedModules = [...modules.filter(m => m.width === LauncherWidget.SMALL)]
        sortedModules.unshift(modules.filter(m => m.width === LauncherWidget.LARGE)[0]);
        sortedModules.push(modules.filter(m => m.width === LauncherWidget.LARGE)[1]);

        smallScreenModules = [...modules.filter(m => m.width === LauncherWidget.SMALL)]
        smallScreenModules.unshift(modules.filter(m => m.width === LauncherWidget.LARGE)[0]);
        smallScreenModules.push(modules.filter(m => m.width === LauncherWidget.LARGE)[1]);
        
        if (width <= 772) {
            originalRow = .15
            column = 0
        } 
        else if (width <= 834) {
            originalRow = .25
            column = 0
        } 
        else if (width <= 890) {
            originalRow = 1
            column = 0
        } 
        else if (width <= 1200) {
            originalRow = .5
            column = .25
        } 
        else {
            originalRow = 1
            column = .25
        } 

        if (width <= 890)
            logo.width = LauncherWidget.LARGE
        else
            logo.width = LauncherWidget.SMALL
            
        sortedModules.unshift(logo)
        smallScreenModules.splice(3, 0, logo)
        row = originalRow;

        let length = width <= 890 ? smallScreenModules.length : sortedModules.length
        for (let index = 0; index < length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w:  width <= 890 ? smallScreenModules[index].width : sortedModules[index].width,
                h: 1,
                static: true
            }
            
            if (width <= 890) {
                if (smallScreenModules[index].width === LauncherWidget.LARGE || index === 2 || index === 5){
                    row = originalRow
                    column++
                }
                else 
                    row++
            } 
            else {
                if (sortedModules[index].width === LauncherWidget.LARGE){
                    row = originalRow
                    column++
                }
                else if (index === 4){
                    row = originalRow
                    column++
                }
                else
                    row++
            }

            let widget = width <= 890 ? smallScreenModules[index] : sortedModules[index]
            boxList.push(<div key={index.toString()}><BoxModel widget={widget} /></div>)
            boxes.push(box);
        }
    }

    // APP_L - 6 (2)
    if (modules.length === 6 && larges.length === 1) {
        sortedModules = [...modules.filter(m => m.width === LauncherWidget.SMALL)]
        sortedModules.unshift(modules.find(m => m.width === LauncherWidget.LARGE));
        smallScreenModules = [...modules.filter(m => m.width === LauncherWidget.SMALL)]
        smallScreenModules.splice(1, 0, modules.find(m => m.width === LauncherWidget.LARGE))
        
        if (width <= 772) {
            originalRow = .15
            column = 0
        } 
        else if (width <= 834) {
            originalRow = .25
            column = 0
        } 
        else if (width <= 890) {
            originalRow = 1
            column = 0
        } 
        else if (width <= 1200) {
            originalRow = 0
            column = 1
        } 
        else {
            originalRow = .5
            column = 1
        } 

        logo.width = LauncherWidget.SMALL
        sortedModules.unshift(logo)
        smallScreenModules.unshift(logo)
        row = originalRow;

        let length = width <= 1000 ? smallScreenModules.length : sortedModules.length
        for (let index = 0; index < length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w:  width <= 890 ? smallScreenModules[index].width : sortedModules[index].width,
                h: 1,
                static: true
            }
            
            if (width <= 890) {
                if (index === 0)
                    row++
                else if (smallScreenModules[index].width === LauncherWidget.LARGE){
                    row = originalRow
                    column++
                }
                else if (index%2 == 0 || index === 1) {
                    row = originalRow
                    column++
                }
                else 
                    row++
            } 
            else {
                if (index === 1)
                    row += 2
                else if (index === 2){
                    row = originalRow
                    column++
                }
                else
                    row++
            }

            let widget = width <= 890 ? smallScreenModules[index] : sortedModules[index]
            boxList.push(<div key={index.toString()}><BoxModel widget={widget} /></div>)
            boxes.push(box);
        }
    }

    // APP_L - 6
    if (modules.length === 6 && larges.length === 0) {
        sortedModules = [...modules]
        logo.width = LauncherWidget.LARGE
        
        if (width <= 772) {
            originalRow = .15
            column = 0
        } 
        else if (width <= 834) {
            originalRow = .25
            column = 0
        } 
        else if (width <= 890) {
            originalRow = 1
            column = 0
        } 
        else if (width <= 1200) {
            originalRow = 0
            column = 1
        } 
        else {
            originalRow = .5
            column = 1
        } 

        if (width <= 890) 
            sortedModules.splice(2, 0, logo)
        else
            sortedModules.splice(1, 0, logo)

        row = originalRow;

        for (let index = 0; index < sortedModules.length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w: sortedModules[index].width,
                h: 1,
                static: true
            }
            
            if (width <= 890) {
                if (index === 0)
                    row++
                else if (sortedModules[index].width === LauncherWidget.LARGE){
                    row = originalRow
                    column++
                }
                else if (index%2 == 0 || index === 1) {
                    row = originalRow
                    column++
                }
                else 
                    row++
            } 
            else {
                if (index === 1)
                    row += 2
                else if (index === 2){
                    row = originalRow
                    column++
                }
                else
                    row++
            }

            boxList.push(<div key={index.toString()}><BoxModel widget={sortedModules[index]} /></div>)
            boxes.push(box);
        }
    }

    // APP_L - 5 (3)
    if (modules.length === 5 && larges.length === 2) {
        sortedModules = [...modules.filter(m => m.width === LauncherWidget.SMALL)]
        sortedModules.splice(1, 0, modules.filter(m => m.width === LauncherWidget.LARGE)[0])
        sortedModules.push(modules.filter(m => m.width === LauncherWidget.LARGE)[1])
        
        if (width <= 772) {
            originalRow = .15
            column = 0
        } 
        else if (width <= 834) {
            originalRow = .25
            column = 0
        } 
        else if (width <= 890) {
            originalRow = 1
            column = 0
        } 
        else if (width <= 1200) {
            originalRow = 0
            column = 1
        } 
        else {
            originalRow = .5
            column = 1
        } 
        
        logo.width = LauncherWidget.SMALL
        sortedModules.unshift(logo);
        row = originalRow;
        
        for (let index = 0; index < sortedModules.length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w: sortedModules[index].width,
                h: 1,
                static: true
            }

            if (width <= 890) {
                if (index === 0 || index === 3)
                    row++
                else if (sortedModules[index].width === LauncherWidget.LARGE || index === 1 || index === 4) {
                    row = originalRow
                    column++
                }
            } 
            else {
                if (index <= 1)
                    row++
                else if (sortedModules[index].width === LauncherWidget.LARGE) {
                    row = originalRow
                    column++
                }
                else
                    row++
            }

            boxList.push(<div key={index.toString()}><BoxModel widget={sortedModules[index]} /></div>)
            boxes.push(box);
        }
    }

    // APP_L - 5 (2)
    if (modules.length === 5 && larges.length === 1) {    
        sortedModules = [...modules.filter(m => m.width === LauncherWidget.SMALL)]
        sortedModules.unshift(modules.find(m => m.width === LauncherWidget.LARGE));
        smallScreenModules = [...modules.filter(m => m.width === LauncherWidget.SMALL)]
        smallScreenModules.splice(2, 0, modules.find(m => m.width === LauncherWidget.LARGE))
           
        if (width <= 772) {
            originalRow = .15
            column = 0
        } 
        else if (width <= 834) {
            originalRow = .25
            column = 0
        } 
        else if (width <= 890) {
            originalRow = 1
            column = 0
        } 
        else if (width <= 1200) {
            originalRow = 0
            column = 1
        } 
        else {
            originalRow = .5
            column = 1
        } 
        
        logo.width = LauncherWidget.LARGE
        sortedModules.unshift(logo);
        smallScreenModules.unshift(logo);
        row = originalRow;
        
        let length = width <= 890 ? smallScreenModules.length : sortedModules.length
        for (let index = 0; index < length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w: width <= 890 ? smallScreenModules[index].width : sortedModules[index].width,
                h: 1,
                static: true
            }

            if (width <= 834) {
                if (smallScreenModules[index].width === LauncherWidget.LARGE) {
                    row = originalRow
                    column++
                }
                else if (index === 1 || index === 4)
                    row += 1 
                else {
                    row = originalRow
                    column++
                }
            } 
            else if (width <= 890) {
                if (smallScreenModules[index].width === LauncherWidget.LARGE) {
                    row = originalRow
                    column++
                }
                else if (index === 1 || index === 4)
                    row++
                else {
                    row = originalRow
                    column++
                }
            } 
            else {
                if (index === 0)
                    row += 2
                else if (sortedModules[index].width === LauncherWidget.LARGE) {
                    row = originalRow
                    column++
                }
                else
                    row++
            }

            let widget = width <= 890 ? smallScreenModules[index] : sortedModules[index]
            boxList.push(<div key={index.toString()}><BoxModel widget={widget} /></div>)
            boxes.push(box);
        }
    }

    // APP_L - 5
    if (modules.length === 5 && larges.length === 0) {
        sortedModules = [...modules]
        logo.width = LauncherWidget.SMALL
                
        if (width <= 768) {
            originalRow = .13
            column = .25
        } 
        else if (width <= 830) {
            originalRow = .25
            column = .25
        } 
        else if (width <= 890) {
            originalRow = 1
            column = .25
        } 
        else if (width <= 1200) {
            originalRow = .5
            column = 1
        } 
        else {
            originalRow = 1
            column = 1
        } 
        
        sortedModules.unshift(logo);
        row = originalRow;

        for (let index = 0; index < sortedModules.length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w: sortedModules[index].width,
                h: 1,
                static: true
            }
            
            if (width <= 890) {
                if (index%2 == 0)
                    row++
                else {
                    row = originalRow
                    column++
                }
            } 
            else {
                if (index === 2){
                    row = originalRow
                    column++
                }
                else
                    row++
            }

            boxList.push(<div key={index.toString()}><BoxModel widget={sortedModules[index]} /></div>)
            boxes.push(box);
        }
    }

    // APP_L - 4 (3)
    if (modules.length === 4 && larges.length === 2) {
        sortedModules = [...modules.filter(m => m.width === LauncherWidget.LARGE)]
        sortedModules.push(...modules.filter(m => m.width === LauncherWidget.SMALL));

        smallScreenModules = [...modules.filter(m => m.width === LauncherWidget.LARGE)]
        smallScreenModules.splice(0, 0, modules.filter(m => m.width === LauncherWidget.SMALL)[0])
        smallScreenModules.splice(1, 0, modules.filter(m => m.width === LauncherWidget.SMALL)[1])
        
        if (width <= 772) {
            originalRow = .13
            column = 0
        } 
        else if (width <= 834) {
            originalRow = .25
            column = 0
        } 
        else if (width <= 915) {
            originalRow = 1
            column = 0
        }
        else if (width <= 1200) {
            originalRow = 0
            column = 1
        } 
        else {
            originalRow = .5
            column = 1
        } 
        
        logo.width = LauncherWidget.LARGE
        sortedModules.unshift(logo);
        smallScreenModules.unshift(logo);
        row = originalRow;
        
        let length = width <= 915 ? smallScreenModules.length : sortedModules.length
        for (let index = 0; index < length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w: width <= 915 ? smallScreenModules[index].width : sortedModules[index].width,
                h: 1,
                static: true
            }

            if (width <= 834) {
                if (smallScreenModules[index].width === LauncherWidget.LARGE) {
                    row = originalRow
                    column++
                }
                else if (index === 1)
                    row += 1 
                else {
                    row = originalRow
                    column++
                }
            } 
            else if (width <= 915) {
                if (smallScreenModules[index].width === LauncherWidget.LARGE) {
                    row = originalRow
                    column++
                }
                else if (index === 1)
                    row++
                else {
                    row = originalRow
                    column++
                }
            } 
            else {
                if (index === 0 || (sortedModules[index].width === LauncherWidget.LARGE && index !== 1))
                    row += 2
                else if (index === 1) {
                    row = originalRow
                    column++
                }
                else 
                    row++
            }

            let widget = width <= 915 ? smallScreenModules[index] : sortedModules[index]
            boxList.push(<div key={index.toString()}><BoxModel widget={widget} /></div>)
            boxes.push(box);
        }
    }

    // APP_L - 4 (2)
    if (modules.length === 4 && larges.length === 1) {
        sortedModules = [...modules.filter(m => m.width === LauncherWidget.SMALL)]
        sortedModules.unshift(modules.find(m => m.width === LauncherWidget.LARGE));
        smallScreenModules = [...modules.filter(m => m.width === LauncherWidget.SMALL)]
        smallScreenModules.splice(1, 0, modules.find(m => m.width === LauncherWidget.LARGE))

        if (width <= 768) {
            originalRow = .13
            column = .25
        } 
        else if (width <= 830) {
            originalRow = .25
            column = .25
        }
        else if (width <= 1200) {
            originalRow = .5
            column = 1
        } 
        else {
            originalRow = 1
            column = 1
        } 
        
        logo.width = LauncherWidget.SMALL
        sortedModules.unshift(logo);
        smallScreenModules.unshift(logo);
        row = originalRow;
        
        let length = width <= 830 ? smallScreenModules.length : sortedModules.length
        for (let index = 0; index < length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w: width <= 830 ? smallScreenModules[index].width : sortedModules[index].width,
                h: 1,
                static: true
            }

            if (width <= 830) {
                if (index === 0)
                    row++
                else if (smallScreenModules[index].width === LauncherWidget.LARGE || index === 1){
                    row = originalRow
                    column++
                }
                else
                    row++
            } 
            else {
                if (index === 0)
                    row++
                else if (sortedModules[index].width === LauncherWidget.LARGE){
                    row = originalRow
                    column++
                }
                else
                    row++
            }

            let widget = width <= 830 ? smallScreenModules[index] : sortedModules[index]
            boxList.push(<div key={index.toString()}><BoxModel widget={widget} /></div>)
            boxes.push(box);
        }
    }

    // APP_L - 4
    if (modules.length === 4 && larges.length === 0) {
        sortedModules = [...modules]
        logo.width = LauncherWidget.LARGE
        
        if (width <= 768) {
            originalRow = .13
            column = .25
        } 
        else if (width <= 830) {
            originalRow = .24
            column = .25
        }
        else if (width <= 880) {
            originalRow = 1
            column = .25
        }
        else if (width <= 1200) {
            originalRow = .5
            column = 1
        } 
        else {
            originalRow = 1
            column = 1
        } 
        
        sortedModules.unshift(logo);
        row = originalRow;

        for (let index = 0; index < sortedModules.length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w: sortedModules[index].width,
                h: 1,
                static: true
            }
            
            if (width <= 880) {
                if (index === 0 || index == 2){
                    row = originalRow
                    column++
                }
                else
                    row++
            } 
            else {
                if (index === 0)
                    row += 2
                else if (index <= 1) {
                    row = originalRow
                    column++
                }
                else
                    row++
            }

            boxList.push(<div key={index.toString()}><BoxModel widget={sortedModules[index]} /></div>)
            boxes.push(box);
        }
    }

    // APP_L - 3 mod 3
    if (modules.length === 3 && larges.length === 2) {    
        sortedModules = [...modules.filter(m => m.width === LauncherWidget.LARGE)]
        sortedModules.push(modules.find(m => m.width === LauncherWidget.SMALL))
        smallScreenModules.push([...modules.filter(m => m.width === LauncherWidget.LARGE)][0])
        smallScreenModules.push(modules.find(m => m.width === LauncherWidget.SMALL))
        smallScreenModules.push([...modules.filter(m => m.width === LauncherWidget.LARGE)][1])

        if (width <= 768) {
            originalRow = .13
            column = .25
        } 
        else if (width <= 830) {
            originalRow = .25
            column = .25
        }
        else if (width <= 1200) {
            originalRow = .5
            column = 1
        } 
        else {
            originalRow = 1
            column = 1
        } 

        logo.width = LauncherWidget.SMALL
        sortedModules.unshift(logo);
        smallScreenModules.splice(1, 0, logo)
        row = originalRow;

        let length = width <= 830 ? smallScreenModules.length : sortedModules.length
        for (let index = 0; index < length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w: width <= 830 ? smallScreenModules[index].width : sortedModules[index].width,
                h: 1,
                static: true
            }

            if (width <= 830) {
                if (smallScreenModules[index].width === LauncherWidget.LARGE)
                    column++
                else if (index <= 1)
                    row++
                else {
                    row = originalRow
                    column++
                }
            } 
            else {
                if (index === 0)
                    row++
                else if (index === 1) {
                    row = originalRow
                    column++
                }
                else if (index == 2)
                    row += 2
                else
                    row++
            }
            
            let widget = width <= 830 ? smallScreenModules[index] : sortedModules[index]
            boxList.push(<div key={index.toString()}><BoxModel widget={widget} /></div>)
            boxes.push(box);
        }
    }
    
    // APP_L - 3 mod 2
    if (modules.length === 3 && larges.length === 0) {    
        sortedModules = [...modules]
        logo.width = LauncherWidget.SMALL
          
        if (width <= 768) {
            originalRow = .13
            column = 1
        } 
        else if (width <= 830) {
            originalRow = .25
            column = 1
        }
        else if (width <= 1200) {
            originalRow = 1
            column = 1
        } 
        else {
            originalRow = 1.5
            column = 1
        } 

        sortedModules.unshift(logo);
        row = originalRow;

        for (let index = 0; index < sortedModules.length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w: sortedModules[index].width,
                h: 1,
                static: true
            }

            if (index === 0)
                row++
            else if (index <= 1) {
                row = originalRow
                column++
            }
            else
                row++

            boxList.push(<div key={index.toString()}><BoxModel widget={sortedModules[index]} /></div>)
            boxes.push(box);
        }
    }  

    // APP_L - 3 mod
    if (modules.length === 3 && larges.length === 1) {
        sortedModules = [...modules.filter(m => m.width === LauncherWidget.SMALL)]
        sortedModules.push(modules.find(m => m.width === LauncherWidget.LARGE))
        
        if (width <= 768) {
            originalRow = .13
            column = .25
        } 
        else if (width <= 830) {
            originalRow = .25
            column = .25
        }
        else if (width <= 1200) {
            originalRow = .5
            column = 1
        } 
        else {
            originalRow = 1
            column = 1
        } 
        
        logo.width = LauncherWidget.LARGE
        sortedModules.unshift(logo);
        row = originalRow;
        
        for (let index = 0; index < sortedModules.length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w: sortedModules[index].width,
                h: 1,
                static: true
            }

            if (width <= 830) {
                if (sortedModules[index].width === LauncherWidget.LARGE)
                    column++
                else if (index <= 1)
                    row++
                else {
                    row = originalRow
                    column++
                }
            } 
            else {
                if (sortedModules[index].width === LauncherWidget.LARGE)
                    row += 2
                else if (index <= 1) {
                    row = originalRow
                    column++
                }
                else
                    row++
            }


            boxList.push(<div key={index.toString()}><BoxModel widget={sortedModules[index]} /></div>)
            boxes.push(box);
        }
    } 
    
    // APP_L - 2 mod 2
    if (modules.length === 2 && larges.length === 1) {
        sortedModules = [...modules]
        logo.width = LauncherWidget.SMALL
        
        if (width <= 768) {
            originalRow = .13
            column = 1
        } 
        else if (width <= 830) {
            originalRow = .25
            column = 1
        }
        else if (width <= 1200) {
            originalRow = 1
            column = 1
        } 
        else {
            originalRow = 1.5
            column = 1
        } 

        sortedModules.unshift(logo);
        row = originalRow;

        for (let index = 0; index < sortedModules.length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w: sortedModules[index].width,
                h: 1,
                static: true
            }

            if (sortedModules[index].width === LauncherWidget.SMALL && index <= 0)
                row++
            else {
                row = originalRow
                column++
            }

            boxList.push(<div key={index.toString()}><BoxModel widget={sortedModules[index]} /></div>)
            boxes.push(box);
        }
    }

    // APP_L - 2 mod
    if (modules.length === 2 && larges.length === 0) {
        sortedModules = [...modules]
        logo.width = LauncherWidget.LARGE
        
        if (width <= 768) {
            originalRow = .13
            column = 1
        } 
        else if (width <= 830) {
            originalRow = .25
            column = 1
        }
        else if (width <= 1200) {
            originalRow = 1
            column = 1
        } 
        else {
            originalRow = 1.5
            column = 1
        } 

        sortedModules.unshift(logo);
        row = originalRow;

        for (let index = 0; index < sortedModules.length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w: sortedModules[index].width,
                h: 1,
                static: true
            }

            if (sortedModules[index].width === LauncherWidget.SMALL)
                row++
            else
                column++

            boxList.push(<div key={index.toString()}><BoxModel widget={sortedModules[index]} /></div>)
            boxes.push(box);
        }
    }

    if (modules.length === 1 && larges.length === 1) {
        sortedModules = [...modules]
        logo.width = LauncherWidget.LARGE 
        
        if (width <= 768) {
            originalRow = .13
            column = 1
        } 
        else if (width <= 830) {
            originalRow = .25
            column = 1
        }
        else if (width <= 1200) {
            originalRow = 1
            column = 1
        } 
        else {
            originalRow = 1.5
            column = 1
        } 

        sortedModules.unshift(logo);
        row = originalRow;

        for (let index = 0; index < sortedModules.length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w: sortedModules[index].width,
                h: 1,
                static: true
            }

            if (sortedModules[index].width === LauncherWidget.SMALL)
                if (width <= 830) 
                    column++
                else
                    row++
            else
                column++

            boxList.push(<div key={index.toString()}><BoxModel widget={sortedModules[index]} /></div>)
            boxes.push(box);
        }
    }

    if (modules.length === 1 && larges.length === 0) {
        sortedModules = [...modules]
        logo.width = LauncherWidget.SMALL  

        if (width <= 768) {
            originalRow = .65
            column = 1
        } 
        else if (width <= 830) {
            originalRow = .75
            column = 1
        }
        else if (width <= 1200) {
            originalRow = 1
            column = 1.25
        } 
        else {
            originalRow = 1.5
            column = 1.25
        } 
        
        sortedModules.unshift(logo);
        row = originalRow;

        for (let index = 0; index < sortedModules.length; index++) {
            box = {
                i: index.toString(),
                x: row,
                y: column,
                w: sortedModules[index].width,
                h: 1,
                static: true
            }

            if (sortedModules[index].width === LauncherWidget.SMALL)
                if (width <= 830) 
                    column++
                else
                    row++
            else
                column++

            boxList.push(<div key={index.toString()}><BoxModel widget={sortedModules[index]} /></div>)
            boxes.push(box);
        }
    }

    layout = { lg: boxes }
    html = boxList
    
    return (
        <>
            <div className="launcher-container">
                <ResponsiveGridLayout
                    className="layout"
                    layouts={layout}
                    isDraggable={true}
                    isResizable={false}
                    cols = {{ lg: 5, md: 4, sm: 2.5, xs: 2.25, xxs: 2.25 }}
                    rowHeight= { 253.7 }
                    autoSize={true}
                    breakpoints={{ lg: 1200, md: 830, sm: 768, xs: 500, xxs: 0}}
                >
                    { html }
                </ResponsiveGridLayout>
            </div>
        </>
    );
}

export default connect(state => ({
    store: state
}))(Launcher);