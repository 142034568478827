import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Card, Tabs, Form, Input, Select, Row, Col, Button, message, Switch, Space, InputNumber } from 'antd';
import { SettingsDefaultCurrency, SettingsDefaultMileage, SettingsDefaultTax } from '../../../utils/enums'
import { GetUsers } from "../../../services/services";
import { configurationModuleApi } from "../../../services/api";
import "./settings.scss"

const { TabPane } = Tabs;
const { TextArea } = Input;

const SettingsPage = ({ store, dispatch }) => {
  const [mileageUnit, setMileageUnit] = useState("Km");
  const [currencyUnit, setCurrencyUnit] = useState("C$");
  const [idSettings, setId] = useState(null);
  const [settings, setConfig] = useState({});
  const [users, setUsers] = useState([{ value: "", label: "Select..." }]);
  const [isLoading, setIsLoading] = useState(true);

  const [formGeneral] = Form.useForm();
  const [formExpense] = Form.useForm();
  const [formGather] = Form.useForm();

  useEffect(async () => {
    let userList = [{ value: "", label: "Select..." }]
    let userFinalList = []
    userList = await GetUsers();

    userList.forEach(user => {
      let paymentCoordinator = {}
      paymentCoordinator.value = user._id
      paymentCoordinator.label = user.name
      userFinalList.push(paymentCoordinator)
    });

    setUsers(userFinalList)
    await GetSettings()
  }, [])

  const GetSettings = async () => {
    setIsLoading(true)
    configurationModuleApi.get("/Settings/GetSettings")
      .then(async (result) => {
        setId(result.data.content._id)
        setConfig(result.data.content);
        setCurrencyUnit(SettingsDefaultCurrency[0].value === result.data.content.currency ? SettingsDefaultCurrency[0].label : SettingsDefaultCurrency[1].label)
        setMileageUnit(SettingsDefaultMileage[0].value.toLowerCase() === result.data.content.mileageUnit.toLowerCase() ? SettingsDefaultMileage[0].label : SettingsDefaultMileage[1].label)
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(async () => {
    formGeneral.setFieldsValue({
      policy: settings.policy,
      currency: settings.currency,
      mileageRate: settings.mileageRate,
      tax: settings.tax,
      mileageUnit: settings.mileageUnit
    });

    formExpense.setFieldsValue({
      paymentCoordinator: settings.paymentCoordinator,
      askForConfirmationOnReportSubmission: settings.askForConfirmationOnReportSubmission
    });

    formGather.setFieldsValue({
      autoConfirm: settings.autoConfirm,
      limit: settings.limit
    });
  }, [settings])

  const handleCurrencyChange = (value, option) => {
    setCurrencyUnit(option.children)
  }

  const handleMileageChange = (value, option) => {
    setMileageUnit(option.children)
  }

  async function handleGeneralSubmit(values) {
    setIsLoading(true);

    configurationModuleApi.put('/Settings/EditSettings?id=' + idSettings, values)
      .then((res) => {
        message.success('General settings saved successfully');
      })
      .catch(() => {
        message.error('Error saving general settings');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function handleExpensesSubmit(values) {
    setIsLoading(true)

    configurationModuleApi.put('/Settings/EditSettings?id=' + idSettings, values)
      .then((res) => {
        message.success('Expense settings saved successfully');
      })
      .catch(() => {
        message.error('Error saving expense settings');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function handleGatheringSubmit(values) {
    setIsLoading(true)

    configurationModuleApi.put('/Settings/EditSettings?id=' + idSettings, values)
      .then((res) => {
        message.success('Expense settings saved successfully');
      })
      .catch(() => {
        message.error('Error saving expense settings');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane tab="General" key="1" forceRender={true}>
          <Form
            form={formGeneral}
            layout="vertical"
            requiredMark={false}
            onFinish={handleGeneralSubmit}
          >
            <Row gutter={[10, 10]} align="bottom">
              <Col span={24}>
                <Form.Item label="Policy" name="policy">
                  <TextArea />
                </Form.Item>
              </Col>
              <Col span={12} lg={5}>
                <Form.Item className="mb-0" label="Default Currency" name="currency" rules={[{ required: true }]}>
                  <Select onChange={handleCurrencyChange}>
                    {SettingsDefaultCurrency && (SettingsDefaultCurrency.map((item) => {
                      return (<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)
                    }))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} lg={5}>
                <Form.Item className="mb-0" label="Mileage Rate" name="mileageRate" rules={[{ required: true }]}>
                  <Input addonBefore={currencyUnit} addonAfter={'\/' + mileageUnit}></Input>
                </Form.Item>
              </Col>
              <Col span={12} lg={5}>
                <Form.Item className="mb-0" label="Default tax" name="tax" rules={[{ required: true }]}>
                  <Select>
                    {SettingsDefaultTax && (SettingsDefaultTax.map((item) => {
                      return (<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)
                    }))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} lg={5}>
                <Form.Item className="mb-0" label="Default mileage unit" name="mileageUnit" rules={[{ required: true }]}>
                  <Select onChange={handleMileageChange}>
                    {SettingsDefaultMileage && (SettingsDefaultMileage.map((item) => {
                      return (<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)
                    }))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} lg={4}>
                <Button type="primary" htmlType="submit" loading={isLoading}>Save</Button>
              </Col>
            </Row>
          </Form>
        </TabPane>
        <TabPane tab="Expenses" key="2" forceRender={true}>
          <Form
            form={formExpense}
            layout="vertical"
            requiredMark={false}
            style={{ maxWidth: 270 }}
            onFinish={handleExpensesSubmit}
          >
            <Form.Item label="Payment Coordinator" name="paymentCoordinator" rules={[{ required: true }]}>
              <Select>
                {users && (users.map((item) => {
                  return (<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)
                }))}
              </Select>
            </Form.Item>
            <Form.Item label="Report Submission" extra="Show policy before submit a report">
              <Space>
                <Form.Item className="mb-0" name="askForConfirmationOnReportSubmission" valuePropName="checked">
                  <Switch />
                </Form.Item>
                <label className="label-switch">Ask for confirmation</label>
              </Space>
            </Form.Item>
            <Form.Item noStyle>
              <Button type="primary" htmlType="submit" loading={isLoading}>Save</Button>
            </Form.Item>
          </Form>
        </TabPane>
        <TabPane tab="Gathering" key="3" forceRender={true}>
          <Form
            form={formGather}
            layout="vertical"
            style={{ maxWidth: 300 }}
            requiredMark={false}
            onFinish={handleGatheringSubmit}
          >
            <Form.Item label="Auto confirm">
              <Space>
                <Form.Item className="mb-0" name="autoConfirm" valuePropName="checked">
                  <Switch />
                </Form.Item>
                <label className="label-switch">Auto confirm people in order or checking</label>
              </Space>
            </Form.Item>
            <Form.Item label="Limit of people" name="limit" rules={[{ type: 'number', min: 0 }]} style={{ maxWidth: 200 }}>
              <InputNumber />
            </Form.Item>
            <Form.Item noStyle>
              <Button type="primary" htmlType="submit" loading={isLoading}>Save</Button>
            </Form.Item>
          </Form>
        </TabPane>
      </Tabs>
    </>
  )
}

export default connect(state => ({
  store: state
}))(SettingsPage);
