import React, { Component, memo, useEffect, useState } from 'react'
import { proximityModuleApi } from "../../services/api";
import { BoxContainer } from '../proximityManagement/proximityBoxStyle/proximityBoxStyle';
import "./historyBox.css"
import "../currentLineBox/currentLineBox.css"

function HistoryBox({ history }) {

    return (
        <>
            <div className="content-history-box">
                <BoxContainer>
                    <div className="box-title">HISTORY</div>
                    <div className="history-box-container">
                        <table rules="none" className='table-history-box'>
                            <tbody>
                                {
                                    history && history.map((element) => {
                                        return (
                                            <tr>
                                                {
                                                    Object.keys(element).map(item => <td><div className="td-content">{element[item]}</div></td>)

                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    </div>

                </BoxContainer>
            </div>
        </>
    )

}

export default memo(HistoryBox)