import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from "react-redux";
import { configurationModuleApi } from '../../services/api';
import * as AppearanceActions from '../../store/actions/appearance';
import * as ConfigurationActions from '../../store/actions/configuration';
import { Button, Layout, Menu, Progress, Tag } from 'antd';
import { MenuOutlined, LeftOutlined } from '@ant-design/icons';
import HeaderContent from "../../components/headerContent/headerContent";
import "../globalStyles/ant-custom.scss";
import History from '../../routes/history';
import { Link, useLocation } from 'react-router-dom';

const { Header, Content, Sider } = Layout;

function LayoutBase({ menu, ...props }) {
    const location = useLocation();
    const dispatch = useDispatch();
    const [active, setActive] = useState([]);
    const [collapsed, setCollapsed] = useState(props.store.appearance.isVisible);
    const [menuWidth, setMenuWidth] = useState(250);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(async () => {
        await GetSettings();
    }, [])

    useEffect(() => {
        if (menu && menu.length > 0) {
            setActive([menu[0].name]);
        }

        menu.map(item => {
            if (item.link == location.pathname) {
                changeActive(item.name);
            } else if (item.children) {
                item.children.map(child => {
                    if (child.link == location.pathname) {
                        changeActive(item.name);
                    }
                })
            }
        });
    }, [location])

    const GetSettings = async () => {
        configurationModuleApi.get("/Settings/GetSettings")
            .then(async (result) => {
                dispatch(ConfigurationActions.updatelogo(result.data.content.logo === undefined ? "/images/apex_logo_white.png" : result.data.content.logo));
                dispatch(AppearanceActions.changeColorAsBG(result.data.content.colorAsBg === undefined ? false : result.data.content.colorAsBg));
                dispatch(AppearanceActions.changeBgColor(result.data.content.backgroundColor === undefined ? "#FFFFFF" : result.data.content.backgroundColor));
                dispatch(AppearanceActions.changeBorderColor(result.data.content.primaryColor === undefined ? "#21d2ff" : result.data.content.primaryColor, "#ede000"));

                if (result.data.content.background !== undefined)
                    dispatch(AppearanceActions.setBackground(result.data.content.background));
            });

        configurationModuleApi.get("/Settings/GetEnvSettings")
            .then(async (result) => {
                dispatch(ConfigurationActions.updateEnv(result.data.content));
            });
    }

    const onChangeBreakpoint = (mobile) => {
        setCollapsed(mobile);
        setIsMobile(mobile);
        if (mobile) {
            setMenuWidth(425);
        } else {
            setMenuWidth(250);
        }
    }

    const toggleMenu = () => {
        setCollapsed(!collapsed);
    }

    const changeActive = (item) => {
        setActive([item]);

        if (isMobile) {
            toggleMenu();
        }
        if (typeof props.onChangeActive == 'function') {
            props.onChangeActive(item);
        }
    }

    const goHome = () => {
        History.push('/');
    }

    return (
        <>
            {props.progress < 100 && (<Progress className="main" percent={props.progress} strokeWidth={4} showInfo={false} />)}
            <Layout>
                <Sider width={menuWidth} breakpoint="lg" collapsedWidth="0" trigger={null} collapsible collapsed={collapsed} onBreakpoint={mobile => onChangeBreakpoint(mobile)}>
                    <div className="sider-header" style={{ backgroundColor: props.store.appearance.colorAsBg ? props.store.appearance.backgroundColor : 'transparent' }}>
                        <img className="layout-logo" src={props.store.configurations.logo} alt="logo" />
                        <Button className="menu-toggle d-lg-none" icon={<LeftOutlined />} onClick={toggleMenu} />
                    </div>
                    <div className="sider-launcher">
                        <a className="d-block" onClick={goHome}>
                            <img className="icon-launcher" src="/images/app-launcher-logo.png" alt="app launcher" />
                            <span>App Launcher</span>
                        </a>
                    </div>
                    {props.module && <div className="sider-module">{props.module}</div>}
                    <Menu theme="dark" mode="inline" selectedKeys={active}>
                        {
                            menu && menu.map((item) => {
                                if (item.children) {
                                    return (
                                        <Menu.SubMenu key={item.name} icon={item.icon} title={item.name}>
                                            {
                                                item.children.map((child) => {
                                                    return (
                                                        <Menu.Item
                                                            key={child.name}
                                                            icon={child.icon}
                                                            className={child.new ? "is-new" : ""}
                                                            style={{ borderColor: props.store.appearance.primaryColor }}
                                                            onClick={() => { changeActive(child.name) }}
                                                        >
                                                            {child.link != undefined ? <Link to={child.link}>{child.name}</Link> : child.name}
                                                            {child.new && (<Tag color="success">New!</Tag>)}
                                                        </Menu.Item>
                                                    )
                                                })
                                            }
                                        </Menu.SubMenu>
                                    )
                                } else {
                                    return (
                                        <Menu.Item
                                            key={item.name}
                                            icon={item.icon}
                                            className={item.new ? "is-new" : ""}
                                            style={{ borderColor: props.store.appearance.primaryColor }}
                                            onClick={() => { changeActive(item.name); }}
                                            title=""
                                        >
                                            {item.link != undefined ? <Link to={item.link}>{item.name}</Link> : item.name}
                                            {item.new && (<Tag color="success">New!</Tag>)}
                                        </Menu.Item>
                                    )
                                }

                            })
                        }
                    </Menu>
                    <div className="sider-copywrite">
                        <div>Powered by</div>
                        <img src="/images/tasc-menu-logo.png" alt="tasc logo" />
                    </div>
                </Sider>
                <Layout id="main-container">
                    <Header className="d-lg-none">
                        <Button className="menu-toggle" icon={<MenuOutlined />} onClick={toggleMenu} />
                        <img className="layout-logo" src={props.store.configurations.logo} style={{ opacity: (collapsed ? 100 : 0) }} alt="logo" />
                    </Header>
                    <Content>
                        <HeaderContent title={props.title} breadcrumbs={props.breadcrumbs} menu={menu} />
                        {props.children}
                    </Content>
                </Layout>
            </Layout>
        </>
    )
}

export default connect(state => ({
    store: state
}))(LayoutBase);
