import styled from "styled-components";

export const Grid = styled.div`
    font-size: 12px;
    width:100%;

`;

export const Tbody = styled.div`
height: ${(props) => props.height};
background:#F4F4F4;
    overflow-y:scroll;
    &::-webkit-scrollbar{
        position: absolute;
        right:0;
        background: #f4f4f4;
    }
    border-bottom-left-radius: 10px;
    

`;


export const Row = styled.div`
    display:flex;
    min-height: 45px;
    align-items:center;
    background-color: #f4f4f4;
    padding:16px;

`;

export const RowHeader = styled.div`
    display:flex;
    min-height: 45px;
    align-items:center;
    background-color: #ffffff;
    padding:16px;
    border-radius: 10px 10px 0 0;
   
`;


export const Col = styled.div`
    flex: ${(props) => props.size};
    padding:5px;
    word-break: break-all;
    display: ${(props) => props.display};
   
`;

export const ColHeader = styled.div`
    flex: ${(props) => props.size};
    font-weight: 500;
    padding:5px;
    word-break:break-all;
    text-transform: capitalize;
    display: ${(props) => props.display};
    
   
`;
