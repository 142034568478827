import React, { useEffect, useRef, useState } from 'react';
import { QRCodesBoxContainer } from "../proximityManagement/proximityBoxStyle/proximityBoxStyle"
import { configurationModuleApi } from "../../services/api";
import "../QRCode/QRCode.css"
import { FormContactCommunication } from "../form/formContainer/formContactCommunication";
import QRious from 'qrious';
import { useReactToPrint } from 'react-to-print';
import ContactToPrint from "../proximityManagement/PDFInstructions/PDFContact";
import LineToPrint from "../proximityManagement/PDFInstructions/PDFLine";
import { loginApi } from "../../services/api";

export default function QRCodes({ label, buttonLabel, text, size }) {

    var url = window.location.href
    var subDomain = /:\/\/([^\/]+)/.exec(url)[1];

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const QRCodes = ({ size }) => {
        const canvas = useRef(null);
        useEffect(() => {
            var result =  loginApi.get('/login/GetCompanyBySubdomain', {
                params: {
                    subDomain
                }
            });

            if (canvas != null && canvas.current != null) {
                let qr = new QRious({
                    element: canvas.current,
                    value: "http://" + subDomain + "/line-entering",
                    size: size,

                });
            }
        });
        return (<canvas ref={canvas}></canvas>);
    }
    return (
        <>
            <div className="container">
                <QRCodesBoxContainer>
                    <div className="QRCode-title">
                        <span>
                            {label}
                        </span>
                    </div>
                    <div className='qr-code'>
                        <QRCodes subDomain={subDomain} size={size} />
                    </div>
                    <div>
                        <FormContactCommunication>

                            <button type="submit" className="submit QRCode-button" onClick={handlePrint}> {buttonLabel}</button>

                            <div hidden>
                                <LineToPrint ref={componentRef} style={{ backgroundColor: "#dedad7" }} />
                            </div>

                        </FormContactCommunication>
                    </div>

                </QRCodesBoxContainer>
            </div>

        </>
    )
}
