import React, { useEffect, useState } from 'react';
import History from '../../../routes/history';
import { systemsModuleApi } from '../../../services/api';
import Loading from '../../../components/loading/loading';
import Map from '../../../components/map/map';
// import GoogleMap from '../../../components/map/googleMap';
import { Button, Card, Col, Collapse, Drawer, Form, Input, message, Row, Space } from 'antd';
import { Icon } from '@iconify/react'
import externalLinkOutline from '@iconify/icons-eva/external-link-outline';
import { DownOutlined } from '@ant-design/icons';

import './systems.scss';

const { Panel } = Collapse;

export function Systems() {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [showDrawer, setShowDrawer] = useState(false);

    const GetSystems = () => {
        setIsLoading(true);
        Promise.all([
            systemsModuleApi.get('/Systems/GetSystems'),
            systemsModuleApi.get('/Systems/GetSites')
        ])
            .then(result => {
                let systems = result[0].data.content;
                let sites = result[1].data.content;
                systems.map(system => {
                    system.sites = sites.filter(x => x.systemId == system._id);
                });
                setList(systems);
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSave = (data) => {
        setIsLoading(true);
        systemsModuleApi.post('/Systems/PostSystem', data)
            .then(async (res) => {
                GetSystems();
                setShowDrawer(false);
                message.success('System created successfully');
            })
            .catch(() => {
                message.error('Something went wrong!');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const goSystem = (item) => {
        History.push({
            pathname: `/Systems/System/${item._id}`,
            state: {
                breadcrumbs: [
                    { name: 'Systems', link: '/Systems' },
                    { name: item.name, link: `/Systems/System/${item._id}` }
                ]
            }
        });
    };

    useEffect(() => {
        GetSystems();
    }, []);

    return (
        <>
            <div className="text-right mb-1">
                <Button onClick={() => { setShowDrawer(true); }}>Add System</Button>
            </div>
            <Row gutter={[24, 24]} align="stretch">
                {
                    list.map((item) => {
                        return (
                            <Col key={item._id} span={24} lg={12}>
                                <Card
                                    className="medium cover-right"
                                    bordered={false}
                                    cover={
                                        <>
                                            <div className="map-caption" onClick={() => goSystem(item)}>
                                                <Row>
                                                    <Col flex="auto">
                                                        <p className="text-black-8 fw-medium mb-1">{item.name}</p>
                                                        <p className="fs-3 text-black-6">{item.client}</p>
                                                    </Col>
                                                    <Col flex="20px">
                                                        <Icon icon={externalLinkOutline} style={{ fontSize: 18, color: '#2D9CDB' }} />
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Map className="map-devices" width={"auto"} height={"380px"} systems={[item]} />
                                        </>
                                    }
                                >
                                    <Space className="sites" direction="vertical" size="small" style={{ maxHeight: 345 }}>
                                        {item.sites && item.sites.map(site => {
                                            return (
                                                <Collapse
                                                    key={site._id}
                                                    expandIconPosition="right"
                                                    expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
                                                >
                                                    <Panel
                                                        header={
                                                            <>
                                                                <span className="status mr-1"></span>
                                                                <span>{site.name}</span>
                                                            </>
                                                        }
                                                    >
                                                        {site.gateways && site.gateways.length > 0 ?
                                                            site.gateways.map(gateway =>
                                                                <div key={gateway._id} className="device">
                                                                    <span className="name">{gateway.name}</span>
                                                                    <span className="status"></span>
                                                                </div>
                                                            )
                                                            :
                                                            <span>Not avaliable</span>
                                                        }
                                                    </Panel>
                                                </Collapse>
                                            )
                                        })}
                                    </Space>
                                </Card>
                            </Col>
                        );
                    })
                }
            </Row>
            <Drawer
                visible={showDrawer}
                title="Add System"
                mask={false}
                getContainer="#main-container"
                destroyOnClose={true}
                onClose={() => { setShowDrawer(false); }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSave}
                >
                    <Card bordered={false} style={{ width: "600px" }}>
                        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Client" name="client" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Card>
                    <Space className="form-actions">
                        <Button type="secondary" onClick={() => { setShowDrawer(false); }}>Cancel</Button>
                        <Button type="primary" htmlType="submit" loading={isLoading} >Save</Button>
                    </Space>
                </Form>
            </Drawer>
            {isLoading && <Loading />}
        </>
    )
}